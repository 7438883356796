import { FaqSlugB2B, FaqSlugB2C } from 'types/faq'
import { BiLingualValue } from 'types/types'

export const FAQ_SLUGS: { [key in FaqSlugB2B | FaqSlugB2C]: BiLingualValue<string> } = {
  // B2C
  [FaqSlugB2C.CONSUMPTION]: {
    nl: 'verbruik-2',
    fr: 'consommation-2'
  },
  [FaqSlugB2C.COMMUNITY_SALES]: {
    nl: 'vrienden-uitnodigen',
    fr: 'Inviter-un-ami'
  },
  [FaqSlugB2C.MOVE]: {
    nl: 'verhuizen',
    fr: 'demenager'
  },
  [FaqSlugB2C.TARIFFS]: {
    nl: 'tarieven',
    fr: 'tarifs'
  },
  [FaqSlugB2C.ABOUT_BOLT]: {
    nl: 'bolt-over',
    fr: 'a-propos-de-Bolt'
  },
  [FaqSlugB2C.SWITCH]: {
    nl: 'overstappen-2',
    fr: 'changer-de-fournisseur-2'
  },
  [FaqSlugB2C.PAYMENT]: {
    nl: 'betalen',
    fr: 'regler-sa-facture'
  },
  [FaqSlugB2C.INVOICES_AND_PAYMENTS]: {
    nl: 'facturen',
    fr: 'factures-et-paiements'
  },
  [FaqSlugB2C.MY_ADDRESSES]: {
    nl: 'adressen',
    fr: 'adresses'
  },
  [FaqSlugB2C.MY_DETAILS]: {
    nl: 'gegevens',
    fr: 'mes-informations'
  },
  [FaqSlugB2C.MY_PRODUCER]: {
    nl: 'energie-opwekker',
    fr: 'producteurs-d-energie'
  },
  [FaqSlugB2C.ENERGY_OVERVIEW]: {
    nl: 'impact',
    fr: 'apercu-de-mon-energie'
  },
  [FaqSlugB2C.MARKET_PRICES]: {
    nl: 'prijzen-vandaag-en-morgen',
    fr: 'les-prix-aujourdhui-et-demain'
  },
  [FaqSlugB2C.PHISHING]: {
    nl: 'phishing',
    fr: 'phishing'
  },

  // B2B
  [FaqSlugB2B.INJECTION]: {
    nl: 'injectie-b2b',
    fr: 'injection-b2b'
  },
  [FaqSlugB2B.INVOICES]: {
    nl: 'facturen-b2b',
    fr: 'factures-b2b'
  },
  [FaqSlugB2B.GVO]: {
    nl: 'gvo-b2b-nl',
    fr: 'gvo-b2b-fr'
  },
  [FaqSlugB2B.ABOUT_BOLT]: {
    nl: 'over-bolt-b2b',
    fr: 'a-propos-de-bolt-b2b'
  }
  //  [FaqSlugB2B.ENERGY_SHARING]: {
  //    nl: 'energiedelen-b2b',
  //    fr: ''
  //  }
}
