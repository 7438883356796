import { ImageCmsModel } from './cms'
import { BiLingualValue } from 'types/types.ts'

export type Coordinates = {
  lat: number
  long: number
}

export type Image = {
  url?: string
  name?: string
}

export type ImageObject = {
  header?: Image
  profile?: Image
  extra?: Image[]
  logo?: Image
}

type ProducerBase = {
  id: string
  companyDescription: BiLingualValue<string>
  description: BiLingualValue<string>
  enabled: boolean
  facts?: BiLingualValue<string[]>
  images: ImageObject
  longDescription?: BiLingualValue<string>
  name: BiLingualValue<string>
  slug: string
}

type ProducerLocation = {
  coordinates: Coordinates
  currentCapacity: number
  energyType: ProducerEnergyType
  energyUsed: number
  location: BiLingualValue<string>
  maxCapacity: number
  minOccupation: number
  slug: string
  state: ProducerState
}

export type Producer = ProducerBase &
  ProducerLocation & {
    energyRegion: string
    isFeatured: BiLingualValue<boolean>
    isPopular: BiLingualValue<boolean>
    isSoldOut: boolean
    order: number
    parentSlug: string
    salesforceId: string
    website: string
  }

export type ParentProducer = ProducerBase & {
  locations: ProducerLocation[]
}

export enum ProducerEnergyType {
  BIO = 'Bio Energy',
  WATER = 'Water',
  SOLAR = 'Solar',
  WIND = 'Wind'
}

export enum ProducerState {
  AVAILABlE = 'available',
  SOLD_OUT = 'soldOut',
  RESERVED = 'reserved'
}

export type PersonalizedProducers = {
  nl: {
    popular: Producer
    nearest: Producer
    recent: Producer
  }
  fr: {
    popular: Producer
    nearest: Producer
    recent: Producer
  }
}

export type ProducerContact = {
  slug: string
  contactId: string
  firstName: string
  lastName: string
  email: string
  phone: string
  avatar?: ImageCmsModel
}
