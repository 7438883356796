import styles from 'pages/App/addresses/Addresses.module.scss'
import { Button } from '@boltenergy-be/design-system'
import { useTranslation } from 'react-i18next'

type ShowInactiveAddressesProps = {
  showInactive: boolean
  setShowInactive: (showInactive: boolean) => void
  inactiveContractsCount: number
}

const ShowInactiveAddresses = ({ showInactive, setShowInactive, inactiveContractsCount }: ShowInactiveAddressesProps) => {
  // i18n
  const { t } = useTranslation('addresses')

  return (
    !showInactive &&
    inactiveContractsCount > 0 && (
      <footer className={styles.footer}>
        <small className="text-subdued">
          {inactiveContractsCount === 1
            ? t('overview.inactiveSingle')
            : inactiveContractsCount > 1 && t('overview.inactivePlural', { amount: inactiveContractsCount })}
        </small>
        <Button variant="secondary" onClick={() => setShowInactive(true)} representation="link" size="small">
          {t('overview.showInactive')}
        </Button>
      </footer>
    )
  )
}

export default ShowInactiveAddresses
