import styles from '../Producer.module.scss'
import Link from 'components/Link/Link'
import { routes } from 'types/routes'
import { useTranslation } from 'react-i18next'

const ProducerFooter = () => {
  // i18n
  const { t } = useTranslation(['producer', 'addresses'])

  return (
    <div className={styles['producer-footer']}>
      <small>{t('producerFooter')}</small>
      <Link href={routes.SUPPORT_CONTACT} variant="secondary" size="small">
        {t('addresses:overview.offtake.contact')}
      </Link>
    </div>
  )
}

export default ProducerFooter
