import styles from './Invoices.module.scss'
import InvoicesStatusBanner from './status-banner/InvoicesStatusBanner.tsx'
import { useStoreSelector } from 'hooks/store.ts'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { routes } from 'types/routes.ts'
import { UserTypes } from 'store/auth/types.ts'
import { AccountingEntity } from 'types/types.ts'
import { useGetCustomerAccountingQuery } from 'store/queries/bolt-api/customers'
import FeedbackWidget from 'components/FeedbackWidget/FeedbackWidget.tsx'
import useOpenFeedbackWidget from 'hooks/useOpenFeedbackWidget.ts'
import { WidgetSlug } from 'types/feedbackWidget.ts'
import { useEffect, useMemo, useState } from 'react'
import useInvoicesTable from 'pages/App/billing/invoices/table-data/useInvoicesTable.tsx'
import { InvoiceTableTabs } from 'pages/App/billing/invoices/table-data/types.ts'
import NoInvoices from './no-invoices/NoInvoices.tsx'
import InvoicesTable from './invoices-table/InvoicesTable.tsx'
import TableLoadingSkeleton from './table-loading-skeleton/TableLoadingSkeleton.tsx'
import mixpanel from 'mixpanel-browser'
import { Banner, Tab, Toggle } from '@boltenergy-be/design-system'
import Link from 'components/Link/Link.tsx'
import AddressSwitcher from 'components/AddressSwitcher/AdressSwitcher.tsx'
import { CardColors } from 'components/Card/types.ts'
import Card from 'components/Card/Card.tsx'
import useWindowSize from 'hooks/useWindowSize.tsx'
import classNames from 'classnames'
import { selectCurrentContracts } from 'store/contact/selectors.ts'
import { determineAccessRights } from 'utils/contracts.ts'
import { AccessRightsKeys } from 'types/contracts.ts'
import { InvoiceEvents } from 'types/tracking.ts'

const Invoices = () => {
  // i18n
  const { t } = useTranslation('invoices')

  // Feedback widget
  const { feedbackWidgetData, openFeedbackWidget } = useOpenFeedbackWidget({
    delay: 10,
    slug: WidgetSlug.INVOICES
  })

  // Redux State
  const { userType } = useStoreSelector((store) => store.auth)
  const { selected, accounts, isProducer, billingContracts } = useStoreSelector((store) => store.contact)
  const isSuperUser = userType === UserTypes.SUPER_USER
  const customerNumber = selected.account

  // Redux Queries
  const { data, isLoading, isFetching } = useGetCustomerAccountingQuery({ customerNumber })

  // Contracts
  const billingContract = selectCurrentContracts({ billingContracts, selected })
  const { electricity } = billingContract.serviceContracts

  // Access rights
  const accessRights = determineAccessRights(electricity)

  // React router
  const { search } = useLocation()
  const query = useMemo<URLSearchParams>(() => new URLSearchParams(search), [search])
  const tabFromQuery =
    query.get('tab') && Object.values(InvoiceTableTabs).includes(query.get('tab') as InvoiceTableTabs)
      ? (query.get('tab') as InvoiceTableTabs)
      : undefined

  // Window size
  const { isTablet } = useWindowSize()

  // Local state
  const [tabs, setTabs] = useState<Tab[]>([])
  const [activeTab, setActiveTab] = useState<InvoiceTableTabs>(
    tabFromQuery || (isSuperUser ? InvoiceTableTabs.SUPER_USER_CUSTOMER : InvoiceTableTabs.OUTSTANDING_INVOICES)
  )

  // Table data
  const tableData = useInvoicesTable({ tab: activeTab })

  // track invoice tab viewed event
  useEffect(() => {
    mixpanel.track(InvoiceEvents.INVOICE_TAB_VIEWED, { tab: activeTab })
  }, [activeTab])

  useEffect(() => {
    const getSubNavTabs = () => {
      const tabs: Tab[] = [
        {
          label: t('subNav.outstandingInvoices'),
          mobileLabel: t('subNav.outstandingInvoicesShort'),
          path: InvoiceTableTabs.OUTSTANDING_INVOICES
        },
        { label: t('subNav.allInvoices'), mobileLabel: t('subNav.allInvoicesShort'), path: InvoiceTableTabs.ALL_INVOICES },
        { label: t('subNav.allTransactions'), path: InvoiceTableTabs.ALL_TRANSACTIONS }
      ]

      if (isSuperUser) {
        const isAlsoSupplier = data?.documents?.some((doc) => doc.accountingEntity === AccountingEntity.SUPPLIER)
        if (isAlsoSupplier) {
          tabs.unshift({ label: t('subNav.superuserDistributor'), path: InvoiceTableTabs.SUPER_USER_SUPPLIER })
        }

        tabs.unshift({
          label: t(isAlsoSupplier ? 'subNav.superuserClient' : 'subNav.superuser'),
          mobileLabel: isAlsoSupplier ? undefined : t('subNav.superuserShort'),
          path: InvoiceTableTabs.SUPER_USER_CUSTOMER
        })
      }

      setTabs(tabs)
    }

    getSubNavTabs()
  }, [data?.documents, isSuperUser, t])

  return (
    <div className={styles.container}>
      <InvoicesStatusBanner />

      <section className={styles.outlet}>
        {isTablet ? (
          <div
            className={classNames(styles.sticky, {
              [styles['hug-top']]:
                isProducer ||
                (!accessRights[AccessRightsKeys.BILLING_CYCLES].canAccess && !accessRights[AccessRightsKeys.INSTALMENT].canAccess)
            })}
          >
            <Toggle
              className={styles.toggle}
              isFullwidth
              active={activeTab}
              options={tabs.map(({ path, mobileLabel, label }) => ({ value: path as InvoiceTableTabs, label: mobileLabel || label }))}
              onClick={(path) => setActiveTab(path)}
            />
          </div>
        ) : (
          <Card as="header" shadow={false} color={CardColors.SECONDARY} className={styles['switch-bar']}>
            <Toggle
              className={styles.toggle}
              active={activeTab}
              options={tabs.map(({ path, label }) => ({ value: path as InvoiceTableTabs, label }))}
              onClick={(path) => setActiveTab(path)}
            />

            <AddressSwitcher className={styles.switcher} />
          </Card>
        )}

        {isLoading || isFetching ? (
          <TableLoadingSkeleton />
        ) : tableData.length ? (
          <InvoicesTable {...{ tableData, activeTab }} />
        ) : (
          <NoInvoices tab={activeTab} setActiveTab={(tab) => setActiveTab(tab)} />
        )}
      </section>

      {!accounts?.[selected.account]?.paymentDetails?.directDebit &&
        activeTab === InvoiceTableTabs.OUTSTANDING_INVOICES &&
        tableData.length > 0 && (
          <Banner title={t('paymentMethodBanner.title')}>
            <p className="mb-100">{t('paymentMethodBanner.text')}</p>
            <Link variant="tertiary" href={routes.USER_PAYMENT}>
              {t('paymentMethodBanner.linkText')}
            </Link>
          </Banner>
        )}

      {feedbackWidgetData?.isActive && <FeedbackWidget id={feedbackWidgetData._id} openFeedbackWidget={openFeedbackWidget} />}
    </div>
  )
}

export default Invoices
