export const ENVIRONMENT_RIBBON_CONFIG = {
  superUser: {
    color: 'yellow',
    text: 'Super User'
  },
  staging: {
    color: 'purple',
    text: 'staging'
  },
  testing: {
    color: 'lightblue',
    text: 'testing'
  },
  beta: {
    color: 'brand',
    text: 'beta'
  }
}
