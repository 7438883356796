import styles from './EstimatedSettlement.module.scss'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import classNames from 'classnames'
import { formatCurrency } from 'utils/format.ts'
import { useTranslation } from 'react-i18next'
import { useStoreSelector } from 'hooks/store.ts'
import { EstimatedSettlementProps } from './types.ts'
import { Heading } from '@boltenergy-be/design-system'

const EstimatedSettlement = ({ loading, settlementAmount, labelAsTitle }: EstimatedSettlementProps) => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)

  // i18n
  const { t } = useTranslation('billing')

  return (
    <div className={styles.settlement}>
      {labelAsTitle ? (
        <Heading as={labelAsTitle} variant="h6" weight={600}>
          {t('settlement.label')}
        </Heading>
      ) : (
        <strong>{t('settlement.label')}</strong>
      )}

      {loading ? (
        <LoadingSkeleton>
          <LoadingSkeleton.Rectangle height={24} width={140} />
        </LoadingSkeleton>
      ) : typeof settlementAmount === 'number' ? (
        <strong
          className={classNames({
            [styles.extra]: settlementAmount > 0,
            [styles.return]: settlementAmount < 0
          })}
        >
          {formatCurrency(Math.abs(settlementAmount), { language })} {t(`settlement.${settlementAmount > 0 ? 'extra' : 'recover'}`)}
        </strong>
      ) : (
        <strong>—</strong>
      )}
    </div>
  )
}

export default EstimatedSettlement
