import styles from './SmartMeterOption.module.scss'
import { Heading, Icon, Switch, Tag } from '@boltenergy-be/design-system'
import { useTranslation } from 'react-i18next'
import { SmartMeterOptionProps } from 'pages/App/addresses/offtake-address/components/SmartMeterOption/types'
import classNames from 'classnames'
import Card from 'components/Card/Card'

const SmartMeterOption = ({ option, icon, isActive, readMoreUrl, editable, onChange, title }: SmartMeterOptionProps) => {
  // i18n
  const { t } = useTranslation('addresses')

  return (
    <Card className={classNames(styles.item, styles[option])}>
      {icon}

      <div className={styles.content}>
        <Heading as="h3" variant="h5">
          {title}
        </Heading>
        <p>{t(`myContract.smartMeterOptions.${option}.description`)}</p>
        {readMoreUrl && (
          <a href={readMoreUrl} target="_blank" rel="noreferrer noopener" className={styles['more-info']}>
            {t('readMore', { ns: 'common' })} <Icon name="externalLink" />
          </a>
        )}
      </div>

      <div className={styles.status}>
        {editable ? (
          <div className={styles['switch-group']}>
            <span>{isActive ? t('myContract.smartMeterOptions.status.active') : t('myContract.smartMeterOptions.status.inactive')}</span>
            <Switch checked={isActive} onChange={(event) => onChange(event.target.checked)} />
          </div>
        ) : (
          <Tag color={isActive ? 'green' : 'gray'}>{t(`myContract.smartMeterOptions.status.${isActive ? 'active' : 'inactive'}`)}</Tag>
        )}
      </div>
    </Card>
  )
}

export default SmartMeterOption
