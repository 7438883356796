import { RenewableSourceCardProps } from './types'
import { energyToIconName } from 'utils/mapping'
import styles from './RenewableSourceCard.module.scss'
import { Heading, Icon } from '@boltenergy-be/design-system'

const RenewableSourceCard = ({ energyType, tag, pct, mwh }: RenewableSourceCardProps) => {
  return (
    <div className={styles.card}>
      <div className={styles.head}>
        <Icon className={styles.icon} name={energyToIconName(energyType)} />
        <div className={styles.tag}>{tag}</div>
      </div>
      <div>
        <Heading as="h3" variant="h4" className={styles.pct}>
          {pct}%
        </Heading>
        <small className={styles.mwh}>{mwh} MWh</small>
      </div>
    </div>
  )
}

export default RenewableSourceCard
