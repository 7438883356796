import { useGetCustomerAccountingQuery } from 'store/queries/bolt-api/customers'
import { useStoreSelector } from 'hooks/store'
import { CustomerInvoice, DocumentType } from 'types/types'
import dayjs from 'dayjs'
import { formatCurrency } from 'utils/format'
import { useTranslation } from 'react-i18next'
import styles from './InvoicesStatusBanner.module.scss'
import { Button, Card, popErrorToast } from '@boltenergy-be/design-system'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton'
import { useState } from 'react'
import { getCustomerBalancePayUrl } from 'api/customer'
import { selectCurrentAccount } from 'store/contact/selectors.ts'
import parse from 'html-react-parser'
import { INVOICE_STATUS_CONFIG } from './constants.ts'
import { getInvoiceStatus } from './utils.ts'
import { InvoicesStatus } from './types.ts'

const InvoicesStatusBanner = () => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)
  const { selected, accounts } = useStoreSelector((store) => store.contact)
  const account = selectCurrentAccount({ accounts, selected })

  // Redux Queries
  const { data, isLoading, isFetching, isError } = useGetCustomerAccountingQuery(
    { customerNumber: selected.account },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      skip: !selected.account
    }
  )

  // Local state
  const [isLoadingBalancePayUrl, setIsLoadingBalancePayUrl] = useState<boolean>(false)

  // i18n
  const { t } = useTranslation(['invoices', 'common'])

  // Constants
  const usesDirectDebit = !!account?.paymentDetails?.directDebit

  // -- invoices
  const invoices = data?.documents?.filter((document) => document.documentType === DocumentType.INVOICE) as CustomerInvoice[]
  const expiredInvoices = invoices?.filter((invoice) => {
    const invoiceDueDate = dayjs(invoice.dueDate)
    return dayjs().isAfter(invoiceDueDate.add(4, 'days'), 'day') && !invoice.paid
  })
  const hasNegativeUnpaidDueInvoices = invoices?.some(
    (invoice) => invoice.amount < 0 && dayjs().isAfter(invoice.dueDate, 'day') && !invoice.paid
  )

  // -- payments
  const hasOpenPayments = data?.documents
    ?.filter((document) => document.documentType === DocumentType.PAYMENT)
    ?.some((payment) => !payment.paid)
  const showGeneralPaymentButton = hasOpenPayments || hasNegativeUnpaidDueInvoices
  const status = getInvoiceStatus({
    balance: Number(data?.balance),
    usesDirectDebit,
    hasExpiredInvoices: !!expiredInvoices?.length
  })

  /**
   * Handles the click on the general payment button
   * Fetches the balance pay url and opens it
   */
  const onGeneralPaymentButtonClick = async () => {
    setIsLoadingBalancePayUrl(true)

    // Fetch the balance pay url
    const balancePayUrl = await getCustomerBalancePayUrl(selected.account)

    if (balancePayUrl) {
      window.location.href = balancePayUrl
    } else {
      popErrorToast(t('common:error'))
    }

    setIsLoadingBalancePayUrl(false)
  }

  // Data loading
  if (!data && (isLoading || isFetching)) {
    return (
      <Card as="section" className={styles.card}>
        <LoadingSkeleton>
          <LoadingSkeleton.Paragraph lines={2} />
          <LoadingSkeleton.Button />
        </LoadingSkeleton>
      </Card>
    )
  }

  // hasBddubDocuments or error
  if (data?.hasBddubDocuments || isError) return null

  return (
    <Card as="section" color={INVOICE_STATUS_CONFIG[status].color} className={styles.card}>
      <Card.Title as="h1" variant="h5" weight={400}>
        {parse(
          t(`invoicesStatusBanner.${INVOICE_STATUS_CONFIG[status].textKey}.title`, {
            amount: formatCurrency(Number(data?.balance), { language })
          })
        )}
      </Card.Title>

      {status === InvoicesStatus.DIRECT_DEBIT && <p>{t('invoicesStatusBanner.directDebit.text')}</p>}

      {[InvoicesStatus.HAS_UNPAID_INVOICES, InvoicesStatus.DIRECT_DEBIT_WITH_UNPAID_INVOICES].includes(status) &&
        showGeneralPaymentButton && (
          <Button
            variant="layered"
            onClick={onGeneralPaymentButtonClick}
            loading={isLoadingBalancePayUrl}
            isFullwidthMobile
            className={styles.button}
          >
            {t('invoicesStatusBanner.open.buttonText')}
          </Button>
        )}
    </Card>
  )
}

export default InvoicesStatusBanner
