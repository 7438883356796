import { GetProductsContentResponse, ProductsCmsReponse } from 'store/queries/cms-api/types'
import { ImageCmsModel, WithId } from 'types/cms'
import { flattenCmsResponse } from 'utils/cms'
import {
  ELProduct,
  NGProduct,
  Product,
  ProductBillingOptions,
  ProductConfigOption,
  ProductContactOptions,
  ProductContent,
  ProductTypes
} from 'types/products'
import { Region } from 'api/types'
import { PRODUCTS_CONFIG } from 'constants/products'
import { store } from 'store/index'
import { UserTypes } from 'store/auth/types'
import { Products } from 'features/contracts/add/types'
import { SourceType } from 'types/user'
import { SimulationType } from 'types/contacts.ts'
import { selectCurrentContracts } from 'store/contact/selectors.ts'
import { DeliveryMode } from 'types/types.ts'

/**
 * Helper function that converts the cms response into a dictionary
 * @param {ProductsCmsReponse} res
 * @returns {GetProductsContentResponse}
 */
export const convertProductsCmsResponseToDictionary = ({ data }: ProductsCmsReponse): GetProductsContentResponse => {
  const normalizedProducts = data
    .map((product) => ({
      ...product.attributes,
      icon: flattenCmsResponse(product?.attributes?.icon) as WithId<ImageCmsModel>,
      id: product?.id ?? null,
      slug: flattenCmsResponse(product?.attributes?.apiProduct)?.slug as Product
    }))
    .filter((product) => product.slug)

  if (!normalizedProducts.length) return null

  return normalizedProducts.reduce((acc: { [p in Product]?: ProductContent }, product) => {
    acc[product.slug as Product] = product

    return acc
  }, {})
}

/**
 * Helper function that checks if a product is digital only
 * @param {Product} product
 * @returns {{[ProductConfigOption.BILLING_OPTIONS]: boolean, [ProductConfigOption.CONTACT_OPTIONS]: boolean}}
 */
export const checkIfProductIsDigitalOnly = (
  product: Product
): { [ProductConfigOption.BILLING_OPTIONS]: boolean; [ProductConfigOption.CONTACT_OPTIONS]: boolean } => {
  const contactOptions = PRODUCTS_CONFIG[product][ProductConfigOption.CONTACT_OPTIONS]
  const billingOptions = PRODUCTS_CONFIG[product][ProductConfigOption.BILLING_OPTIONS]

  return {
    [ProductConfigOption.CONTACT_OPTIONS]: contactOptions.length === 1 && contactOptions[0] === ProductContactOptions.DIGITAL,
    [ProductConfigOption.BILLING_OPTIONS]: billingOptions.length === 1 && billingOptions[0] === ProductBillingOptions.DIGITAL
  }
}

/**
 * Helper function that returns the default products for a given region
 * @param {{
 *   availableProducts?: Product[]
 *   isEarlyBird?: boolean
 *   region: Region
 *   simulationType?: SimulationType
 *   userType: UserTypes | null
 *   withBoltGo?: boolean
 * }} payload
 * @returns {Product[]}
 */
export const getUsableProducts = ({
  availableProducts = undefined,
  isEarlyBird = false,
  region,
  simulationType = undefined,
  userType = null,
  withBoltGo = false
}: {
  availableProducts?: Product[]
  isEarlyBird?: boolean
  region: Region
  simulationType?: SimulationType
  userType: UserTypes | null
  withBoltGo?: boolean
}): Product[] => {
  // Define some constants
  const isSalesUser = userType === UserTypes.SALES
  const isSuperUser = userType === UserTypes.SUPER_USER

  // Always skip early bird products, they are replaced in the .reduce below
  const productsToSkip: Product[] = [Product.EARLY_BIRD_OFFLINE, Product.EARLY_BIRD_ONLINE]

  // Add Bolt GO to skippable products
  if (!withBoltGo) productsToSkip.push(Product.GO)

  if (simulationType === SimulationType.BOLT_GO) {
    productsToSkip.push(...[Product.FIX, Product.VARIABLE_OFFLINE, Product.VARIABLE_ONLINE])
  }

  // Loop over products in the config and return the ones that are available in the given region
  return Object.keys(PRODUCTS_CONFIG).reduce((productsForGivenRegion, product) => {
    const productConfig = PRODUCTS_CONFIG[product as Product]

    const availableIn = productConfig[ProductConfigOption.AVAILABLE_IN_REGIONS][isSalesUser ? SourceType.PUSH : SourceType.PULL]

    // Skip product if it's in the skippable list
    if (productsToSkip.includes(product as Product)) return productsForGivenRegion

    // Skip the product for sales user if it's not in the available products list in a non-BOLT_GO simulation
    if (simulationType !== SimulationType.BOLT_GO && availableProducts && !availableProducts.includes(product as Product)) {
      return productsForGivenRegion
    }

    // Check if product is in previous contracts
    const { billingContracts } = store.getState().contact
    const isInPreviousContracts = Object.values(billingContracts).some(
      (contract) =>
        contract.serviceContracts.electricity.detail.productCode ===
        productConfig[ProductConfigOption.PRODUCT_CODES][contract.serviceContracts.electricity.detail.energyType]
    )

    // Add product if it's available in the given region
    if (availableIn.includes(region) || (isSuperUser && isInPreviousContracts)) {
      const shouldChangeToEarlyBird =
        !isSalesUser && isEarlyBird && [Product.VARIABLE_OFFLINE, Product.VARIABLE_ONLINE].includes(product as Product)

      productsForGivenRegion.push(shouldChangeToEarlyBird ? convertProductToEarlyBird(product as Product) : (product as Product))
    }

    return productsForGivenRegion
  }, [] as Product[])
}

/**
 * Helper function that converts a variable product to early bird product
 * @param {Product} product
 * @returns {Product}
 */
const convertProductToEarlyBird = (product: Product): Product => {
  switch (product) {
    case Product.VARIABLE_OFFLINE:
      return Product.EARLY_BIRD_OFFLINE
    case Product.VARIABLE_ONLINE:
      return Product.EARLY_BIRD_ONLINE
    default:
      return product
  }
}

/**
 * Helper function that returns the product code from a given product
 * @param {ELProduct | NGProduct | undefined} productCode
 * @returns {Product | undefined}
 */
export const getProductFromProductCode = (productCode: ELProduct | NGProduct | undefined): Product | undefined => {
  switch (productCode) {
    case ELProduct.EarlyBirdOn:
    case NGProduct.EarlyBirdOn: {
      return Product.EARLY_BIRD_ONLINE
    }

    case ELProduct.EarlyBirdOff:
    case NGProduct.EarlyBirdOff: {
      return Product.EARLY_BIRD_OFFLINE
    }

    case ELProduct.VariableOn:
    case NGProduct.VariableOn: {
      return Product.VARIABLE_ONLINE
    }

    case ELProduct.VariableOff:
    case NGProduct.VariableOff: {
      return Product.VARIABLE_OFFLINE
    }

    case ELProduct.Go:
    case NGProduct.Go: {
      return Product.GO
    }

    case ELProduct.Fix:
    case NGProduct.Fix: {
      return Product.FIX
    }

    default:
      return undefined
  }
}

/**
 * Checks if at least one contract has early bird product
 * @returns {boolean}
 */
export const checkIsEarlyBird = (): boolean => {
  const { userType } = store.getState().auth
  if (!userType || userType === UserTypes.SALES) return false

  const { billingContracts } = store.getState().contact

  return Object.values(billingContracts).some((contract) => {
    const productCode = contract.serviceContracts.electricity.detail.productCode
    return productCode === ELProduct.EarlyBirdOn || productCode === ELProduct.EarlyBirdOff
  })
}

/**
 * Checks if the selected customer has a fixed product
 * @returns {boolean}
 */
export const checkHasFixedProduct = (): boolean => {
  const { billingContracts, selected } = store.getState().contact
  const billingContract = selectCurrentContracts({ billingContracts, selected })
  const selectedCustomerProduct = getProductFromProductCode(billingContract?.serviceContracts?.electricity?.detail?.productCode)
  return selectedCustomerProduct ? PRODUCTS_CONFIG[selectedCustomerProduct][ProductConfigOption.TYPE] === ProductTypes.FIX : false
}

/**
 * Defines products:
 * If at least one contract has early bird product: re-use early bird
 * Otherwise check based on delivery mode
 *
 * @param {DeliveryMode} deliveryMode
 * @param {boolean} needsGas
 * @param {boolean} hasEarlyBirdChecked
 * @returns {Products}
 */
export const defineProducts = (deliveryMode: DeliveryMode, needsGas: boolean, hasEarlyBirdChecked?: boolean): Products => {
  const hasEarlyBird = hasEarlyBirdChecked || checkIsEarlyBird()

  if (deliveryMode === DeliveryMode.EMAIL) {
    return {
      electricity: hasEarlyBird ? ELProduct.EarlyBirdOn : ELProduct.VariableOn,
      ...(needsGas && {
        gas: hasEarlyBird ? NGProduct.EarlyBirdOn : NGProduct.VariableOn
      })
    }
  }

  return {
    electricity: hasEarlyBird ? ELProduct.EarlyBirdOff : ELProduct.VariableOff,
    ...(needsGas && {
      gas: hasEarlyBird ? NGProduct.EarlyBirdOff : NGProduct.VariableOff
    })
  }
}
