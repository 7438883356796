import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DeliveryMode, Town } from 'types/types'
import { LETTERS_NUMBERS_SLASHES_DASHES, POSTAL_CODE } from 'constants/regex'
import request, { getAxiosRequestConfig } from 'utils/request'
import { Methods } from 'types/request'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import { Banner, Button, Form, Heading, InlineMessage, Select, TextField, Toggle, popSuccessToast } from '@boltenergy-be/design-system'
import { ContractResponseCodes } from 'types/errorCodes.ts'
import styles from './ContactForm.module.scss'
import { ContactFormInputs } from 'pages/App/user/types'
import { updateBillingContract, updateAccount } from 'store/contact/thunks'
import { selectCurrentAccount, selectCurrentContracts } from 'store/contact/selectors.ts'
import { ThunkDefaultErrorMsg } from 'store/contact/thunks/types.ts'
import { formatAddress } from 'utils/user'
import mixpanel from 'mixpanel-browser'
import { UserEvents } from 'types/tracking.ts'
import { routes } from 'types/routes.ts'
import { useNavigate } from 'react-router'

const ContactForm = () => {
  // Redux
  const contactStore = useStoreSelector((store) => store.contact)
  const { error, loading } = contactStore
  const dispatch = useStoreDispatch()

  // Contracts
  const billingContract = selectCurrentContracts(contactStore)
  const account = selectCurrentAccount(contactStore)

  // i18n
  const { t } = useTranslation('user')

  // Router
  const navigate = useNavigate()

  // Local state
  const [saved, setSaved] = useState(false)
  const [updating, setUpdating] = useState<boolean>(false)
  const [towns, setTowns] = useState<Town[]>([])

  // React hook form
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm<ContactFormInputs>({
    mode: 'onChange',
    defaultValues: {
      emailContactMode: billingContract.deliveryMode === DeliveryMode.EMAIL,
      address: account.correspondenceAddress
    }
  })
  const watchTownName = watch('address.townName')

  /**
   * Fetches the towns based on the current postal code
   * Triggered on first render
   */
  useEffect(() => {
    if (account.correspondenceAddress.postalCode) fetchTowns(Number(account.correspondenceAddress.postalCode))
  }, [])

  /**
   * Fetches the towns
   *
   * @param postalCode
   */
  const fetchTowns = async (postalCode: number) => {
    if (isValidPostalCode(postalCode)) {
      try {
        clearErrors()

        // Fetch the towns & the region
        const { success, data } = await request(getAxiosRequestConfig(Methods.GET, `towns/${postalCode}`))

        if (success) {
          const { towns } = data
          setTowns(towns)

          const filteredTowns = towns.filter((town: Town) => town.townName === account.correspondenceAddress.townName)
          setValue('address.townName', filteredTowns.length > 0 ? filteredTowns[0].townName : towns[0].townName)
        } else {
          setTowns([])
          setError('address.postalCode', { type: 'validate' })
        }
      } catch (e) {
        setTowns([])
        setError('address.postalCode', { type: 'api' })
      }
    } else {
      setTowns([])
      setError('address.postalCode', { type: 'validate' })
    }
  }

  /**
   * Returns if a given postal code is valid
   *
   * @param postalCode
   * @returns boolean
   */
  const isValidPostalCode = (postalCode: number): boolean => {
    return postalCode.toString().length === 4
  }

  /**
   * Handles the form submission after validation by React Hook Form
   *
   * @param {ContactFormInputs} data
   */
  const onSubmit = async (data: ContactFormInputs) => {
    setUpdating(true)

    const [accountRes, contractRes] = await Promise.all([
      // Update the account (only map the fields that have changed since it's a PATCH request)
      dispatch(
        updateAccount({
          accountId: account.sfId,
          contactId: account.contactSfId,
          account: {
            correspondenceAddress: {
              ...account.correspondenceAddress,
              streetName: data.address.streetName,
              streetBox: data.address.streetBox,
              streetNumber: data.address.streetNumber,
              postalCode: data.address.postalCode,
              townName: data.address.townName
            },
            deliveryMode: data.emailContactMode ? DeliveryMode.EMAIL : DeliveryMode.POST
          }
        })
      ),
      // Update billing contract
      dispatch(
        updateBillingContract({
          billingContractId: billingContract.contractNumber,
          updateBillingContractData: {
            deliveryMode: data.emailContactMode ? DeliveryMode.EMAIL : DeliveryMode.POST
          }
        })
      )
    ])

    if (accountRes.meta.requestStatus === 'fulfilled' && contractRes.meta.requestStatus === 'fulfilled') {
      navigate(routes.USER_CONTACT)
      popSuccessToast(t('formFeedback.saved', { ns: 'common' }))
    }

    mixpanel.track(UserEvents.SUBMIT_EDIT_CONTACT_INFO)

    setSaved(true)
    setUpdating(false)
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className={styles['contact-form']}>
      {account.billingContracts.length > 1 && (
        <Banner type="informative" className={styles['full-width']} title={t('contact.banner')}>
          {formatAddress(billingContract.serviceContracts.electricity.deliveryPoint.address)}
        </Banner>
      )}
      <Heading as="h2" variant="h4" className={styles['full-width']}>
        {t('contact.fields.preferences.title')}
      </Heading>

      <div className={classNames(styles['full-width'], styles['toggle-wrapper'])}>
        <label htmlFor="emailContactMode">{t('contact.fields.preferences.label')}</label>
        <Controller
          name="emailContactMode"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Toggle
              isFullwidth
              active={value}
              onClick={onChange}
              options={[
                { value: true, label: t('contact.fields.preferences.receiveEmail') },
                { value: false, label: t('contact.fields.preferences.receivePost') }
              ]}
            />
          )}
        />
      </div>

      <Heading as="h2" variant="h4" className={classNames(styles['full-width'], 'mt-400')}>
        {t('contact.fields.billing.title')}
      </Heading>

      {/*  STREET */}
      <TextField
        id="address.streetName"
        label={t('contact.fields.billing.streetName')}
        {...register('address.streetName', { required: t('required', { ns: 'validation' }) })}
        error={errors?.address?.streetName?.message}
      />

      <div className={styles.subgrid}>
        {/* STREET NUMBER */}
        <TextField
          id="address.streetNumber"
          label={t('contact.fields.billing.streetNumber')}
          {...register('address.streetNumber', {
            required: t('required', { ns: 'validation' }),
            maxLength: { value: 5, message: t('invalid.streetNumber', { ns: 'validation' }) },
            pattern: { value: LETTERS_NUMBERS_SLASHES_DASHES, message: t('invalid.streetNumber', { ns: 'validation' }) }
          })}
          error={errors?.address?.streetNumber?.message}
        />

        {/* STREET BOX */}
        <TextField
          id="address.streetBox"
          label={t('contact.fields.billing.streetBox')}
          {...register('address.streetBox')}
          error={errors?.address?.streetBox?.message}
        />
      </div>

      {/* POSTAL CODE */}
      <TextField
        id="address.postalCode"
        label={t('contact.fields.billing.postalCode')}
        {...register('address.postalCode', {
          required: t('required', { ns: 'validation' }),
          validate: (val) => isValidPostalCode(Number(val)) || t('invalid.postalCode', { ns: 'validation' }),
          pattern: { value: POSTAL_CODE, message: t('invalid.postalCode', { ns: 'validation' }) },
          onChange: (e) => fetchTowns(Number(e.target.value))
        })}
        error={errors?.address?.postalCode?.message}
      />

      {/* TOWN NAME */}
      <Select
        id="address.townName"
        label={t('contact.fields.billing.townName')}
        {...register('address.townName', {
          required: t('required', { ns: 'validation' })
        })}
        value={watchTownName}
        error={errors?.address?.townName?.message}
      >
        {towns.map((town) => (
          <option key={town.townCode} value={town.townName}>
            {town.townName}
          </option>
        ))}
      </Select>

      <Button
        isFullwidth
        type="submit"
        disabled={Object.keys(errors).length > 0}
        loading={loading || updating}
        className={styles['full-width']}
      >
        {t('save', { ns: 'common' })}
      </Button>

      {saved &&
        error &&
        [
          ThunkDefaultErrorMsg.UPDATE_BILLING_CONTRACT as string,
          ThunkDefaultErrorMsg.UPDATE_ACCOUNT as string,
          ContractResponseCodes.MERCATOR_CUSTOMER_NOT_FOUND
        ].includes(error) && (
          <InlineMessage type="negative">
            {error === ContractResponseCodes.MERCATOR_CUSTOMER_NOT_FOUND
              ? t('payment.errors.mercatorErrorNotFound', { ns: 'user' })
              : t('error', { ns: 'common' })}
          </InlineMessage>
        )}
    </Form>
  )
}

export default ContactForm
