import styles from './GasTipCard.module.scss'
import { GasTipCardProps } from 'components/EnergyOverview/GasTipCard/types'
import BackdropIcon from 'components/backdrop-icon/BackdropIcon.tsx'

const GasTipCard = ({ tip, icon, orderNumber }: GasTipCardProps) => {
  return (
    <div className={styles.card}>
      <span className={styles.order}>{`${orderNumber}`.padStart(2, '0')}</span>
      <BackdropIcon name={icon} className={styles.icon} />
      <p className={styles.tip}>{tip}</p>
    </div>
  )
}

export default GasTipCard
