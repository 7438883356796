import { EnergyType, Granularity } from 'types/contracts.ts'
import { ProductType } from 'types/types.ts'
import { GranularityOptions } from 'pages/App/consumption/your-consumption/components/consumption-section/types.ts'
import { OpUnitType } from 'dayjs'

export type ConsumptionNavigationProps = {
  billingCycles: { [key: string]: NavigationItem }
  granularities: GranularityOptions
  isLoading: boolean
  disabledFilters?: boolean
  energyType: EnergyType
}

export type NavigationItem = {
  id: string
  type: NavigationItemType
  periods?: {
    label: string
    defaultSelected: boolean
    value: string
  }[]
} & (
  | {
      translationKey: 'day' | 'month' | 'year'
      text?: never
    }
  | {
      translationKey?: never
      text: string
    }
)

export type DatePickerConfig = {
  valueFormat: string // dayjs formats (years are uppercase)
  viewFormat: string // date-fns formats (years are lowercase)
  endOf: OpUnitType
}

export type ConsumptionNavigationForm = {
  view: Granularity | NavigationItemType.CYCLE
  period: string
  activeProduct: ProductType
}

// ENUMS

export enum NavigationItemType {
  CYCLE = 'BILLING_CYCLE',
  GRANULARITY = 'GRANULARITY'
}
