import { CustomerAccountingDocument, CustomerInvoice, CustomerPayment } from 'types/types.ts'
import { Language } from 'store/app/types.ts'
import { TableDataRow } from 'components/Table/types.ts'

export enum InvoiceTableTabs {
  ALL_INVOICES = 'allInvoices',
  OUTSTANDING_INVOICES = 'outstandingInvoices',
  ALL_TRANSACTIONS = 'allTransactions',
  SUPER_USER_CUSTOMER = 'superUserCustomer',
  SUPER_USER_SUPPLIER = 'superUserSupplier'
}

export type useInvoicesTableProps = {
  tab: InvoiceTableTabs
}

export type filteredInvoicesProps = {
  tab: useInvoicesTableProps['tab']
  documents: CustomerAccountingDocument[]
}

export type FilterOutDirectDebitBounceProps = {
  documents: CustomerAccountingDocument[]
  usesDirectDebit: boolean
  tab: useInvoicesTableProps['tab']
}

// TABLE DATA

export enum InvoiceCells {
  ADDRESS = 'address',
  INVOICE_NUMBER = 'invoiceNumber',
  DATE = 'date',
  DOCUMENT_TYPE = 'documentType',
  AMOUNT = 'amount',
  STATUS = 'status',
  PAYMENT_ACTION = 'paymentAction',
  DOWNLOAD_ACTION = 'downloadAction',
  RUNNING_BALANCE = 'runningBalance',
  MATCHING_NUMBER = 'matchingNumber',
  PAYMENT_METHOD = 'paymentMethod',
  OPTIONS = 'options'
}

export type CommonCells = InvoiceCells.DATE | InvoiceCells.DOCUMENT_TYPE | InvoiceCells.AMOUNT | InvoiceCells.STATUS
export type RegularTableCells =
  | CommonCells
  | InvoiceCells.INVOICE_NUMBER
  | InvoiceCells.PAYMENT_ACTION
  | InvoiceCells.DOWNLOAD_ACTION
  | InvoiceCells.ADDRESS
export type SuperUserTableCells =
  | CommonCells
  | InvoiceCells.INVOICE_NUMBER
  | InvoiceCells.RUNNING_BALANCE
  | InvoiceCells.MATCHING_NUMBER
  | InvoiceCells.PAYMENT_METHOD
  | InvoiceCells.OPTIONS
  | InvoiceCells.ADDRESS

export type TransactionRows = CommonCells

export type InvoiceTableCellEnums = RegularTableCells | SuperUserTableCells | TransactionRows
export type InvoiceTableDataRow<Cells extends InvoiceTableCellEnums | InvoiceCells = InvoiceCells> = TableDataRow<Cells>

export type GetTableData<Document extends CustomerInvoice | CustomerPayment> = {
  documents: Document[]
  isTabletOrMobile: boolean
  language: Language
  showAddress?: boolean
  usesDirectDebit: boolean
}
