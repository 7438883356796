import { CardColors } from 'components/Card/types.ts'
import { routes } from 'types/routes.ts'
import OverviewCard from '../OverviewCard.tsx'
import { useTranslation } from 'react-i18next'
import { AccessRightsKeys } from 'types/contracts.ts'
import { useGetBillShockQuery } from 'store/queries/bolt-api/contracts'
import { BillShockOverviewCardProps } from './types.ts'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import { formatAddress } from 'utils/user.ts'

const BillShockOverviewCard = ({ accessRights, billingContract, email }: BillShockOverviewCardProps) => {
  // i18n
  const { t } = useTranslation('overview')

  // Constants
  const skipBillingCycles =
    !billingContract ||
    !accessRights[AccessRightsKeys.BILLING_CYCLES].canAccess ||
    !accessRights[AccessRightsKeys.BILLING_CYCLES].showContent

  // Redux queries
  const { data, isLoading, isError } = useGetBillShockQuery(
    { billingContractId: billingContract.contractNumber, email, serviceContracts: billingContract.serviceContracts },
    { skip: skipBillingCycles }
  )

  // Animation state
  const noBillShock =
    !data?.billShock ||
    (!!data?.billShock?.settlement?.billShockAmount &&
      data?.billShock?.settlement?.billShockAmount <= data.billShock.instalment.current * 1.5)

  // Hide card if skipping billingCycles or if bill shock amount is negative
  if (isLoading || skipBillingCycles || noBillShock || isError) return null

  return (
    <OverviewCard
      isLoading={isLoading}
      color={CardColors.ORANGE}
      button={{ label: t('overview.billingCycles.button'), href: routes.BILLING_CYCLE_DETAIL.replace(':id', 'current') }}
    >
      {isLoading ? (
        <>
          <LoadingSkeleton.Title />
          <LoadingSkeleton.Paragraph lines={3} />
        </>
      ) : (
        <>
          <header>
            <OverviewCard.Title>{t('overview.billingCycles.title')}</OverviewCard.Title>
            <small className="truncate mt-200">{formatAddress(billingContract.serviceContracts.electricity.deliveryPoint.address)}</small>
          </header>

          <p>{t('overview.billingCycles.description', { settlement: data?.billShock?.settlement?.billShockAmount })}</p>
        </>
      )}
    </OverviewCard>
  )
}

export default BillShockOverviewCard
