import { ScriptableContext } from 'chart.js'
import { createChartDataArray } from 'utils/chartjs.ts'
import { GetMarketPricesChartDatasets } from 'components/Charts/market-prices/types.ts'
import { CHART_COLORS, getBarChartDefaults } from 'components/Charts/constants.ts'
import { externalTooltipHandler } from 'components/Charts/chart-tooltip/tooltip.ts'
import { formatAmount } from 'utils/format.ts'
import i18n from 'i18next'
import { TOOLTIP_CONFIG } from 'components/Charts/chart-tooltip/config.ts'

/**
 * Get market prices chart options
 */
export const getMarketPricesChartOptions = (
  setHoverDataPointIndex: (index: number | null) => void,
  isMobile?: boolean,
  isCompact?: boolean
) => {
  const defaults = getBarChartDefaults({ isMobile })
  const {
    scales: { y, x }
  } = defaults

  return {
    ...defaults,
    maintainAspectRatio: false,
    barPercentage: 1,
    layout: {
      padding: {
        ...defaults.layout.padding,
        left: 5,
        right: 5
      }
    },
    scales: {
      y: {
        ...y,
        ticks: {
          ...y.ticks,
          callback: (value: number) => formatAmount(value, 2)
        }
      },
      x: {
        grid: {
          lineWidth: 0
        },
        ticks: x.ticks
      }
    },
    plugins: {
      yScaleTitle: {
        labels: {
          y: isCompact ? '' : '€ / kWh'
        }
      },
      tooltip: {
        ...TOOLTIP_CONFIG,
        external: (context: any) => {
          const { tooltip } = context
          const currentDataPoint = tooltip?.dataPoints?.[0]?.dataIndex
          const hideTooltip = tooltip.opacity === 0
          setHoverDataPointIndex(hideTooltip ? null : currentDataPoint)
          return externalTooltipHandler({ context, totalColumns: 24 })
        },
        callbacks: {
          title: (context: any) => {
            const from = context?.[0]?.label
            return i18n.t('marketPrices:tooltip.title', { label: from })
          }
        }
      }
    }
  }
}

export const getMarketPricesChartDatasets = ({ labels, data, lowestIndexes, compact, isProducer }: GetMarketPricesChartDatasets) => {
  const borderRadius = compact ? 2 : 4
  const maxBarThickness = 28

  return [
    {
      type: 'bar' as const,
      backgroundColor: (ctx: ScriptableContext<'bar'>) =>
        !isProducer && lowestIndexes.includes(ctx.dataIndex) ? CHART_COLORS.green : CHART_COLORS.grey,
      data: createChartDataArray(
        labels?.length,
        data.map(({ price }) => price || 0)
      ),
      skipNull: true,
      hideNullTooltip: true,
      borderSkipped: 'start',
      maxBarThickness,
      borderRadius
    }
  ]
}
