import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialAppState } from 'store/app/initial'
import { switchLanguage } from './thunks'
import { Language, MoveFlows, SetMoveFlowStatePayload, UrlSearchParamsKeys } from 'store/app/types'

export const appSlice = createSlice({
  name: 'app',
  initialState: initialAppState,
  reducers: {
    activateBetaMode: (state) => {
      return {
        ...state,
        betaMode: true
      }
    },
    saveRedirect: (state, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        redirect: payload,
        error: null
      }
    },
    deleteRedirect: (state) => {
      return {
        ...state,
        redirect: null,
        error: null
      }
    },
    toggleShowAllAddresses: (state) => {
      return {
        ...state,
        showAllAddresses: !state.showAllAddresses
      }
    },
    setShowAllAddresses: (state, { payload }: PayloadAction<boolean>) => {
      return {
        ...state,
        showAllAddresses: payload
      }
    },
    saveUrlSearchParam: (state, { payload }: PayloadAction<{ key: UrlSearchParamsKeys; value: string }>) => {
      return {
        ...state,
        urlSearchParams: {
          ...state.urlSearchParams,
          [payload.key]: payload.value
        }
      }
    },
    setMoveFlow: (state, { payload }: PayloadAction<MoveFlows>) => {
      return {
        ...state,
        move: {
          ...state.move,
          flow: payload
        }
      }
    },
    setMoveFlowState: (state, { payload }: PayloadAction<SetMoveFlowStatePayload>) => {
      return {
        ...state,
        move: {
          ...state.move,
          [payload.key]: payload.state
        }
      }
    },
    resetMoveFlow: (state) => {
      return {
        ...state,
        move: initialAppState.move
      }
    }
  },
  extraReducers: (builder) => {
    // IS PENDING

    // IS FULFILLED
    builder.addCase(switchLanguage.fulfilled, (state, { payload }) => {
      state.language = payload.language.toUpperCase() as Language
    })

    // IS REJECTED
  }
})

export const {
  activateBetaMode,
  deleteRedirect,
  resetMoveFlow,
  saveRedirect,
  saveUrlSearchParam,
  setMoveFlow,
  setMoveFlowState,
  setShowAllAddresses,
  toggleShowAllAddresses
} = appSlice.actions

export default appSlice.reducer
