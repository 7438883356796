import { useState, PropsWithChildren } from 'react'
import styles from 'features/contracts/add/components/ProducerSliderLayout/ProducerSliderLayout.module.scss'
import { ProducerSliderLayoutProps } from 'features/contracts/add/components/ProducerSliderLayout/types'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useStoreSelector } from 'hooks/store'
import { Language } from 'store/app/types'
import { AddContractSteps } from 'features/contracts/add/types'
import { getProductFromProductCode } from 'utils/products'
import { useGetProductContentQuery } from 'store/queries/cms-api'
import PriceDisplay from 'features/contracts/add/components/PriceDisplay/PriceDisplay'
import { Product } from 'types/products'
import { getProducerLocation } from 'utils/producer.ts'
import { SimulationType } from 'types/contacts.ts'
import { getLowerCaseLanguage } from 'utils/app.ts'
import Modal from 'components/Modals/Modal/Modal'
import { Icon } from '@boltenergy-be/design-system'

const ProducerSliderLayout = ({ children, contractData, currentStep }: PropsWithChildren<ProducerSliderLayoutProps>) => {
  // Redux store
  const { language } = useStoreSelector((store) => store.app)
  const lowerCaseLanguage = getLowerCaseLanguage(language)

  // Redux queries
  const { data: productContent } = useGetProductContentQuery({ language })

  // i18n
  const { t } = useTranslation('producer')

  // Local state
  const [showMobileModal, setShowMobileModal] = useState<boolean>(false)

  // Constants
  const producer = contractData[AddContractSteps.PRODUCER]
  const { needsGas } = contractData[AddContractSteps.ADDRESS]
  const { simulationType, instalments } = contractData[AddContractSteps.INSTALMENT_DATA]
  const totalInstalment = (instalments.electricity || 0) + (needsGas ? instalments.gas || 0 : 0)
  const product = getProductFromProductCode(contractData[AddContractSteps.PRODUCT].electricity)
  const productName =
    [
      AddContractSteps.INSTALMENT_DATA,
      AddContractSteps.METER_DETAILS,
      AddContractSteps.CUSTOMER_DATA,
      AddContractSteps.CONFIRMATION
    ].includes(currentStep) && product
      ? `Bolt ${productContent?.[product]?.name}`
      : undefined
  const showPrice = [AddContractSteps.METER_DETAILS, AddContractSteps.CUSTOMER_DATA, AddContractSteps.CONFIRMATION].includes(currentStep)

  return (
    <>
      <button className={classNames(styles.card, { [styles['has-price']]: showPrice })} onClick={() => setShowMobileModal(true)}>
        <div className={styles['producer-container']}>
          <picture>
            <img src={producer?.images.profile?.url} alt="" />
            <div className={styles['energy-types']}>
              {needsGas && <Icon name="gas" className="text-accent-purple" />}
              <Icon name="electricity" className="text-primary" />
            </div>
          </picture>

          <div className={styles.producer}>
            <small>
              {t('energyTypeAndLocation', '{{ energyType }} uit {{ location }} van', {
                energyType:
                  language === Language.FR
                    ? t(`energyType.${producer?.energyType || 'Solar'}`).toLowerCase()
                    : t(`energyType.${producer?.energyType || 'Solar'}`),
                location: getProducerLocation(lowerCaseLanguage, producer?.location)
              })}
            </small>
            <h3>{producer?.name?.[lowerCaseLanguage]}</h3>
          </div>

          <span className={styles['open-icon']}>
            <Icon name="arrowTop" />
          </span>
        </div>

        {productName && (
          <>
            <hr />
            <div className={styles['price-container']}>
              <span className={styles.product}>{productName}</span>

              {showPrice && productName && simulationType !== SimulationType.EAV && (
                <PriceDisplay amount={totalInstalment} label={product === Product.GO ? t('perYear', { ns: 'common' }) : undefined} />
              )}
            </div>
          </>
        )}
      </button>

      <Modal variant="no-padding" className={styles.drawer} isOpen={showMobileModal} setClose={() => setShowMobileModal(false)}>
        {children}
      </Modal>
    </>
  )
}

export default ProducerSliderLayout
