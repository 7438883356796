import ContactDetails from 'components/SalesDetails/ContactDetails'
import styles from './Details.module.scss'
import { useParams } from 'react-router-dom'
import { useGetContactQuery } from 'store/queries/bolt-api/contacts'
import { useGetSalesParametersByKeyQuery } from 'store/queries/bolt-api/sales'
import { useEffect, useState } from 'react'
import AccountDetails from 'components/SalesDetails/AccountDetails'
import PropositionDetails from 'components/SalesDetails/PropositionDetails'
import { Account } from 'types/contacts.ts'
import EditLayout from 'layouts/edit-layout/EditLayout.tsx'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import { Tab } from '@boltenergy-be/design-system'

const Details = () => {
  //REACT ROUTER
  const { contactId } = useParams<{ contactId: string }>()

  // FETCH DATA
  const { data: contact, isLoading, isFetching } = useGetContactQuery(contactId!)
  const { data: parameters } = useGetSalesParametersByKeyQuery({ key: 'case_subject' })

  // LOCAL STATE
  const [selectedAccount, setSelectedAccount] = useState<Account | undefined>()
  const [activeEditing, setActiveEditing] = useState<string>()

  useEffect(() => {
    if (!isFetching) {
      if (!selectedAccount) {
        setSelectedAccount(contact?.accounts?.[0])
      } else {
        setSelectedAccount(contact?.accounts?.find((account) => account.sfId === selectedAccount?.sfId))
      }
    }
  }, [contact, isFetching, isLoading])

  const tabs: Tab[] = contact?.accounts?.map((account) => ({ label: account.name, path: account.sfId })) || []

  /**
   * sets the active household to the item selected in the subnav
   * @param {string} path - account.salesforceId
   */
  const handleTabClick = (path: string) => {
    const account = contact?.accounts?.find((acc) => acc.sfId === path)
    setSelectedAccount(account)
  }

  return (
    <EditLayout title={contact ? `${contact.firstName} ${contact.lastName}` : ''} classes={{ body: styles.details }}>
      {isLoading ? (
        <LoadingSkeleton className="gap-600">
          {Array.from({ length: 3 }).map((_, index) => (
            <section key={index} className={styles['loading-section']}>
              <div className={styles.actions}>
                <LoadingSkeleton.Rectangle width={50} height={50} />
                {index === 2 && (
                  <>
                    <LoadingSkeleton.Rectangle width={50} height={50} />
                    <LoadingSkeleton.Rectangle width={50} height={50} />
                    <LoadingSkeleton.Rectangle width={50} height={50} />
                  </>
                )}
              </div>
              <LoadingSkeleton.Rectangle aspectRatio="19/6" />
            </section>
          ))}
        </LoadingSkeleton>
      ) : (
        <>
          {contact && (
            <ContactDetails
              identifier="contactDetails"
              activeEditing={activeEditing}
              setActiveEditing={setActiveEditing}
              contact={contact}
            />
          )}
          {selectedAccount && (
            <>
              <AccountDetails
                identifier="AccountDetails"
                tabs={tabs}
                account={selectedAccount!}
                changeSelectedAccount={handleTabClick}
                activeEditing={activeEditing}
                setActiveEditing={setActiveEditing}
                contactId={contact?.sfId}
              />

              {selectedAccount?.opportunities.map((opportunity, index: number) => (
                <PropositionDetails
                  identifier={`PropositionDetails${index}`}
                  key={opportunity.sfId}
                  opportunity={opportunity}
                  contract={selectedAccount.billingContracts?.find((contract) => contract.opportunitySfId === opportunity.sfId)}
                  propositionNumber={index + 1}
                  accountId={selectedAccount.sfId}
                  contactId={contact?.sfId}
                  activeEditing={activeEditing}
                  setActiveEditing={setActiveEditing}
                  customerName={`${selectedAccount?.firstName} ${selectedAccount?.lastName}`}
                  salesParameters={parameters?.parameters}
                />
              ))}
            </>
          )}
        </>
      )}
    </EditLayout>
  )
}

export default Details
