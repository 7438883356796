import { Icon } from '@boltenergy-be/design-system'
import styles from './BackdropIcon.module.scss'
import classNames from 'classnames'
import { BackdropIconProps } from 'components/backdrop-icon/types.ts'

const BackdropIcon = ({ hasBackdrop = true, ...props }: BackdropIconProps) => {
  return (
    <div className={styles.wrapper}>
      {hasBackdrop && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className={styles.backdrop}>
          <circle cx="15" cy="9" r="9" fill="#23D5AE" />
        </svg>
      )}
      <Icon {...props} className={classNames(styles.icon, props.className)} />
    </div>
  )
}

export default BackdropIcon
