import { createApi } from '@reduxjs/toolkit/query/react'
import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import { CMS_URL } from 'constants/envs'
import {
  GetFaqByMultipleSlugsPayload,
  GetFaqByMultipleSlugsResponse,
  GetFaqBySlugPayload,
  GetFaqBySlugResponse,
  GetProducerContactResponse,
  GetProductsContentResponse,
  ProducerContactCmsResponse,
  ProductsCmsReponse,
  RawFaqCategoryCmsResponse,
  RawReferralActionData,
  RawReferralActionsCmsResponse
} from './types'
import { stringify } from 'qs'
import { FaqCategory } from 'types/types'
import { Language, LanguageLowercase } from 'store/app/types'
import { convertProductsCmsResponseToDictionary } from 'utils/products'
import { Product } from 'types/products'
import { WithId, ImageCmsModel } from 'types/cms'
import { flattenCmsResponse } from 'utils/cms'
import { FAQ_SLUGS } from 'constants/faq.ts'
import dayjs from 'dayjs'

export const cmsApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${CMS_URL}/api`
  }),
  endpoints: (builder) => ({
    // GET FAQ BY SLUG(S)
    getFaqBySlug: builder.query<GetFaqBySlugResponse, GetFaqBySlugPayload>({
      query: ({ slug, language }) => {
        const query = stringify(
          {
            populate: 'articles',
            locale: language.toLowerCase(),
            filters: {
              slug: {
                $eq: FAQ_SLUGS[slug][language.toLowerCase() as LanguageLowercase]
              }
            }
          },
          {
            skipNulls: true,
            addQueryPrefix: true,
            encodeValuesOnly: true
          }
        )

        return {
          url: `/faq-categories${query}`
        }
      },
      transformResponse: ({ data }: RawFaqCategoryCmsResponse) => {
        const transformedData: FaqCategory | undefined = data?.length
          ? {
              id: data[0]?.id,
              ...data[0].attributes,
              questions: data[0].attributes.articles.data.map((article) => ({
                id: article?.id,
                ...article.attributes
              }))
            }
          : undefined

        return { category: transformedData }
      }
    }),

    // GET FAQ BY SLUG(S)
    getFaqByMultipleSlugs: builder.query<GetFaqByMultipleSlugsResponse, GetFaqByMultipleSlugsPayload>({
      query: ({ slugs, language }) => {
        const query = stringify(
          {
            populate: 'articles',
            locale: language,
            filters: {
              slug: {
                $in: slugs.map((slug) => FAQ_SLUGS[slug][language.toLowerCase() as LanguageLowercase])
              }
            }
          },
          {
            skipNulls: true,
            addQueryPrefix: true,
            encodeValuesOnly: true
          }
        )

        return {
          url: `/faq-categories${query}`
        }
      },
      transformResponse: ({ data }: RawFaqCategoryCmsResponse) => {
        const transformedData: FaqCategory[] = data.map((faq) => ({
          id: faq?.id,
          ...faq.attributes,
          questions: faq.attributes.articles.data
            .filter((article) => !!article.attributes.isNewPortal)
            .map((article) => ({
              id: article?.id,
              ...article.attributes
            }))
        }))

        return { categories: transformedData }
      }
    }),

    // GET PRODUCTS CONTENT
    getProductContent: builder.query<GetProductsContentResponse, { language: Language }>({
      query: ({ language }) => ({
        method: 'GET',
        url: `products?locale=${language.toLowerCase()}`
      }),
      transformResponse: (res: ProductsCmsReponse) => {
        const productsContent = convertProductsCmsResponseToDictionary(res)

        // TODO: temporary fix for the direct debit mandatory copy
        if (productsContent?.[Product.VARIABLE_ONLINE]?.perks) {
          const variableOnlinePerks = productsContent[Product.VARIABLE_ONLINE].perks
          variableOnlinePerks[0].children.pop()
          productsContent[Product.VARIABLE_ONLINE].perks = variableOnlinePerks
        }

        return productsContent
      }
    }),

    // GET PRODUCER CONTACT
    getProducerContact: builder.query<GetProducerContactResponse, { contactId: string }>({
      query: ({ contactId }) => ({
        method: 'GET',
        url: `producer-contact/slug/${contactId}`
      }),
      transformResponse: (res: ProducerContactCmsResponse) => {
        return {
          contact: {
            ...res.data.attributes,
            avatar: res.data.attributes.avatar ? (flattenCmsResponse(res.data.attributes.avatar) as WithId<ImageCmsModel>) : undefined
          }
        }
      }
    }),

    // REFERRAL ACTIONS
    getActiveReferralAction: builder.query<RawReferralActionData | null, { language: Language }>({
      query: ({ language }) => {
        const query = stringify(
          {
            locale: language
          },
          {
            skipNulls: true,
            addQueryPrefix: true,
            encodeValuesOnly: true
          }
        )

        return '/referral-actions' + query
      },
      transformResponse: ({ data }: RawReferralActionsCmsResponse) => {
        const today = dayjs()
        const currentlyActiveAction = data.find((action) =>
          today.isBetween(dayjs(action.attributes.start).startOf('day'), dayjs(action.attributes.end).endOf('day'))
        )

        return currentlyActiveAction || null
      }
    })
  }),
  reducerPath: 'cmsApi'
})

export const {
  useGetFaqBySlugQuery,
  useGetFaqByMultipleSlugsQuery,
  useGetProductContentQuery,
  useGetProducerContactQuery,
  useGetActiveReferralActionQuery
} = cmsApi
