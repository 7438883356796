import classNames from 'classnames'
import contractStyles from 'features/contracts/Contracts.module.scss'
import styles from './ProductStep.module.scss'
import { AddContractStepProps, AddContractSteps, ProductFormInputs } from 'features/contracts/add/types'
import FormButtons from 'features/contracts/components/form-buttons/FormButtons'
import { useForm } from 'react-hook-form'
import { CustomerType, ELProduct, NGProduct, Product } from 'types/products'
import { useEffect, useState } from 'react'
import { useStoreSelector } from 'hooks/store'
import { useGetProductContentQuery } from 'store/queries/cms-api'
import { checkIsEarlyBird, defineProducts, getProductFromProductCode, getUsableProducts } from 'utils/products'
import { BlocksContent, BlocksRenderer } from '@strapi/blocks-react-renderer'
import { useTranslation } from 'react-i18next'
import { Button, Heading, Tag } from '@boltenergy-be/design-system'
import ProductDrawer from 'features/contracts/add/components/ProductDetailsDrawer/ProductDrawer'
import { UserTypes } from 'store/auth/types.ts'
import { Region } from 'api/types'
import { SimulationType } from 'types/contacts.ts'
import { selectCurrentAccount, selectCurrentContracts } from 'store/contact/selectors.ts'

const ProductStep = ({ setNextStep, addContractData }: AddContractStepProps) => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)
  const { userType } = useStoreSelector((store) => store.auth)
  const contactStore = useStoreSelector((store) => store.contact)

  // Redux queries
  const { data: productsContent, isLoading } = useGetProductContentQuery({ language })

  // i18n
  const { t } = useTranslation('contracts')

  // React hook form
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<ProductFormInputs>({
    defaultValues: addContractData[AddContractSteps.PRODUCT],
    mode: 'onChange'
  })
  const watchElProduct = watch('electricity')
  const selectedProduct = getProductFromProductCode(watchElProduct)

  // Local state
  const [showDetailsFor, setShowDetailsFor] = useState<Product | undefined>(
    getProductFromProductCode(addContractData[AddContractSteps.PRODUCT]?.electricity)
  )
  const [detailsDrawerOpen, setDetailsDrawerOpen] = useState<boolean>(false)
  const [drawerProduct, setDrawerProduct] = useState<Product>(selectedProduct || Product.VARIABLE_ONLINE)

  // constants
  const contracts = userType === UserTypes.SALES ? undefined : selectCurrentContracts(contactStore)
  const account = selectCurrentAccount(contactStore)
  const isEarlyBird = checkIsEarlyBird()
  const usableProducts = getUsableProducts({
    availableProducts: addContractData[AddContractSteps.ADDRESS].event?.parameters.available_products as Product[] | undefined,
    isEarlyBird,
    region: addContractData.address.region,
    simulationType: addContractData[AddContractSteps.ADDRESS].event?.parameters.simulation_type as SimulationType | undefined,
    userType,
    withBoltGo: true
  })

  /**
   * Selects the early bird ticket if none is selected and isEarlyBird
   */
  useEffect(() => {
    const isEarlyBirdWithBoltVariableSelected =
      isEarlyBird &&
      ![ELProduct.EarlyBirdOn, ELProduct.EarlyBirdOff].includes(watchElProduct) &&
      [ELProduct.VariableOn, ELProduct.VariableOff].includes(watchElProduct)

    if (isEarlyBirdWithBoltVariableSelected) {
      const products = defineProducts(
        addContractData[AddContractSteps.CUSTOMER_DATA].deliveryMode,
        addContractData[AddContractSteps.ADDRESS].needsGas,
        true
      )
      setValue('electricity', products.electricity)
      if (addContractData[AddContractSteps.ADDRESS].needsGas) setValue('gas', products.gas)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Submit form data
   * @param {ProductFormInputs} data
   */
  const onSubmit = (data: ProductFormInputs) => {
    setNextStep(data, AddContractSteps.INSTALMENT_DATA)
  }

  return (
    <section>
      <form onSubmit={handleSubmit(onSubmit)} className={classNames(contractStyles['form-card'], styles['product-step'])}>
        <fieldset className={contractStyles['form-content']}>
          <Heading as="h1" variant="h4">
            {t('add.steps.product.title')}
          </Heading>

          <ul className={styles.list}>
            {isLoading && usableProducts.map((product) => <li key={product} className={styles.skeleton} />)}
            {!isLoading &&
              usableProducts.map((product) => (
                <li key={product} className={classNames(styles['product-wrapper'], { [styles.selected]: selectedProduct === product })}>
                  <label className={styles.label}>
                    <input
                      type="radio"
                      value={ELProduct[product]}
                      {...register('electricity', { required: true })}
                      onClick={() => {
                        setShowDetailsFor(product)
                        if (addContractData[AddContractSteps.ADDRESS].needsGas) {
                          setValue('gas', NGProduct[product])
                        }
                      }}
                    />
                    <div className={styles.product}>
                      <strong>{productsContent?.[product]?.name}</strong>
                      {!!contracts?.serviceContracts?.electricity &&
                        getProductFromProductCode(contracts.serviceContracts?.electricity.detail.productCode) === product && (
                          <Tag color="green">{t('add.steps.product.current')}</Tag>
                        )}
                    </div>
                  </label>

                  <Button
                    type="button"
                    leadingIcon="chevronDown"
                    variant="tertiary"
                    className={classNames(styles['dropdown-toggle'], { [styles.open]: showDetailsFor === product })}
                    onClick={() => setShowDetailsFor(showDetailsFor === product ? undefined : product)}
                  />

                  {showDetailsFor === product && (
                    <>
                      {productsContent && (productsContent[product].alternativePerks || productsContent[product].perks) && (
                        <div className={styles.perks}>
                          {addContractData.address.region !== Region.FLANDERS && productsContent?.[product]?.alternativePerks ? (
                            <BlocksRenderer content={productsContent[product].alternativePerks as BlocksContent} />
                          ) : (
                            <BlocksRenderer content={productsContent[product].perks as BlocksContent} />
                          )}
                        </div>
                      )}
                      <Button
                        type="button"
                        className={styles['details-button']}
                        representation="link"
                        variant="secondary"
                        onClick={() => {
                          setDrawerProduct(product)
                          setDetailsDrawerOpen(true)
                        }}
                      >
                        {t('common.showDetails', 'Toon details')}
                      </Button>
                    </>
                  )}
                </li>
              ))}
          </ul>

          {errors?.electricity?.type === 'required' && <p className="help-block text-negative">{t('required', { ns: 'validation' })}</p>}
        </fieldset>

        <FormButtons currentStep={AddContractSteps.PRODUCT} />
      </form>

      <ProductDrawer
        product={drawerProduct}
        productName={productsContent?.[drawerProduct]?.name}
        userType={account?.company ? CustomerType.PROFESSIONAL : CustomerType.RESIDENTIAL}
        isOpen={detailsDrawerOpen}
        onRequestClose={() => setDetailsDrawerOpen(false)}
        conditions={productsContent?.[drawerProduct]?.conditions || undefined}
        needsGas={addContractData[AddContractSteps.ADDRESS].needsGas}
      />
    </section>
  )
}

export default ProductStep
