import { Button } from '@boltenergy-be/design-system'
import { FaqButtonProps } from './types'
import FaqDrawer from 'components/faq-drawer/FaqDrawer'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreSelector } from 'hooks/store.ts'
import { useGetFaqByMultipleSlugsQuery } from 'store/queries/cms-api'
import { FaqSlugB2C } from 'types/faq.ts'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import mixpanel from 'mixpanel-browser'
import { SupportEvents } from 'types/tracking.ts'

const FaqButton = ({ faqCategories }: FaqButtonProps) => {
  // Redux store
  const { language } = useStoreSelector((store) => store.app)

  // Redux queries
  const { isLoading, data } = useGetFaqByMultipleSlugsQuery(
    {
      language,
      slugs: faqCategories as FaqSlugB2C[]
    },
    {
      skip: !faqCategories?.length
    }
  )

  // Local state
  const [faqOpen, setFaqOpen] = useState<boolean>(false)

  // i18n
  const { t } = useTranslation('common')

  return !isLoading && !data?.categories?.length ? null : (
    <>
      {isLoading ? (
        <LoadingSkeleton>
          <LoadingSkeleton.Circle widthHeight={24} />
        </LoadingSkeleton>
      ) : (
        data?.categories?.some((c) => !!c.questions.length) && (
          <Button
            leadingIcon="questionMarkCircle"
            variant="tertiary"
            onClick={() => {
              setFaqOpen(true)
              mixpanel.track(SupportEvents.OPEN_FAQ_DRAWER, { categories: faqCategories })
            }}
          />
        )
      )}

      {!!data?.categories?.length && (
        <FaqDrawer
          isOpen={faqOpen}
          onRequestClose={() => setFaqOpen(false)}
          questions={data.categories.flatMap((cat) => cat.questions)}
          title={t('faq.title')}
          hasSearch={false}
        />
      )}
    </>
  )
}

export default FaqButton
