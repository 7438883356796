import { ELProduct } from 'types/products.ts'
import InjectionAddressDetails from 'pages/App/addresses/injection-address/InjectionAddressDetails.tsx'
import OfftakeAddressDetails from 'pages/App/addresses/offtake-address/OfftakeAddressDetails.tsx'
import { useStoreSelector } from 'hooks/store.ts'
import { useParams } from 'react-router-dom'
import styles from './Addresses.module.scss'
import { FaqSlugB2C } from 'types/faq.ts'
import PageLayout from 'layouts/page-layout/PageLayout.tsx'
import { useTranslation } from 'react-i18next'
import { routes } from 'types/routes.ts'

const AddressDetail = () => {
  // URL Param
  const { id } = useParams<{ id: string }>()

  // Redux
  const { billingContracts } = useStoreSelector((store) => store.contact)
  const selectedBillingContract = id ? billingContracts?.[id] : undefined

  // i18n
  const { t } = useTranslation('addresses')

  return (
    !!selectedBillingContract && (
      <PageLayout
        title={t('myContract.title')}
        classes={{ body: styles['detail-body'] }}
        backButtonUrl={routes.ADDRESSES}
        faqCategories={{ b2c: [FaqSlugB2C.MY_ADDRESSES] }}
      >
        {[ELProduct.BoltInjection, ELProduct.BoltInjectionOn, ELProduct.BoltInjectionTa, ELProduct.Tailored].includes(
          selectedBillingContract.serviceContracts.electricity.detail.productCode
        ) ? (
          <InjectionAddressDetails contract={selectedBillingContract} />
        ) : (
          <OfftakeAddressDetails contract={selectedBillingContract} />
        )}
      </PageLayout>
    )
  )
}

export default AddressDetail
