import { routes } from 'types/routes.ts'
import OverviewCard from 'pages/App/overview/overview-cards/OverviewCard.tsx'
import { useTranslation } from 'react-i18next'
import { useStoreSelector } from 'hooks/store.ts'
import { useGetProducerContactQuery } from 'store/queries/cms-api'
import { selectCurrentAccount } from 'store/contact/selectors.ts'
import { isWeekend } from 'date-fns'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'

const SupportOverviewCard = () => {
  // Redux store
  const { isProducer, selected, accounts } = useStoreSelector((store) => store.contact)
  const selectedAccount = selectCurrentAccount({ selected, accounts })

  // Redux Queries
  const { data, isLoading } = useGetProducerContactQuery({ contactId: selectedAccount.ownerId || '' }, { skip: !isProducer })

  // i18n
  const { t } = useTranslation('overview')

  // Constants
  const today = new Date()
  const available = !isWeekend(today)
  const translationKey = isProducer ? 'producer' : 'consumer'

  if (isProducer && !data?.contact && !isLoading) {
    return null
  }

  return (
    <OverviewCard
      button={{ label: t(`overview.contact.button.${translationKey}`), href: routes.SUPPORT_CONTACT }}
      image={
        isProducer && !data?.contact?.avatar
          ? undefined
          : {
              src:
                isProducer && data?.contact?.avatar
                  ? data?.contact.avatar.url
                  : 'https://res.cloudinary.com/bolt-energie/image/upload/v1705664314/website/common/customer-care.png',
              alt: isProducer && data?.contact ? data.contact.firstName : 'customer-care'
            }
      }
      isLoading={isLoading}
    >
      {isLoading ? (
        <>
          <LoadingSkeleton.Title />
          <LoadingSkeleton.Paragraph lines={2} />
        </>
      ) : (
        <>
          <OverviewCard.Title>{t(`overview.contact.title.${translationKey}`, { name: data?.contact.firstName })}</OverviewCard.Title>
          <p>{t(`overview.contact.description.${translationKey}.${available ? 'available' : 'unavailable'}`)}</p>
        </>
      )}
    </OverviewCard>
  )
}

export default SupportOverviewCard
