import { Outlet } from 'react-router'
import { routes } from 'types/routes'
import { useTranslation } from 'react-i18next'
import PageLayout from 'layouts/page-layout/PageLayout.tsx'
import { PageTab } from 'layouts/page-layout/types.ts'
import { FaqSlugB2C } from 'types/faq'
import { useLocation } from 'react-router-dom'

const Referral = () => {
  // i18n
  const { t } = useTranslation('referral')

  // use location
  const { pathname } = useLocation()

  // Tabs
  const tabs: PageTab[] = [
    { label: t('tabs.howItWorks'), path: routes.REFERRAL_INFO },
    { label: t('tabs.invitedFriends'), path: routes.REFERRAL_FRIENDS }
  ]

  return (
    <PageLayout
      title={t('title')}
      {...{ tabs }}
      faqCategories={{ b2c: [FaqSlugB2C.COMMUNITY_SALES] }}
      description={pathname.includes(routes.REFERRAL_INFO) ? t('intro.description') : undefined}
    >
      <Outlet />
    </PageLayout>
  )
}

export default Referral
