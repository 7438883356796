import { SubnavWithoutNavigationProps } from './types'
import { Tabs } from '@boltenergy-be/design-system'

const SubnavWithoutNavigation = ({ setActiveTab, ...props }: SubnavWithoutNavigationProps) => {
  return (
    <Tabs
      {...props}
      renderTab={(tab, tabProps) => (
        <button key={tab.path} {...tabProps} onClick={() => setActiveTab(tab.path)}>
          {tab.label}
        </button>
      )}
    />
  )
}

export default SubnavWithoutNavigation
