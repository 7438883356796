import styles from './PageLoading.module.scss'
import { useStoreSelector } from 'hooks/store.ts'
import { useTranslation } from 'react-i18next'
import { PageLoadingProps } from 'components/PageLoading/types.ts'
import { Button, Loader } from '@boltenergy-be/design-system'
import { logout } from 'utils/app.ts'
import { getUserTypeLoginPage } from 'utils/user.ts'
import { useNavigate } from 'react-router'
import Card from 'components/Card/Card.tsx'
import parse from 'html-react-parser'

const PageLoading = ({ isLoading, showError }: PageLoadingProps) => {
  // Redux
  const { error } = useStoreSelector((store) => store.contact)
  const { userType } = useStoreSelector((store) => store.auth)

  // i18n
  const { t } = useTranslation()

  // Router
  const navigate = useNavigate()

  return (
    <div className={styles.loading}>
      {!showError && isLoading && <Loader size={32} className={styles.loader} />}
      {(showError || error) && (
        <Card className={styles.card}>
          <Card.Title variant="h3">{t('somethingWentWrong.title')}</Card.Title>
          <p>{parse(t('somethingWentWrong.description'))}</p>
          <Button variant="secondary" onClick={() => logout(() => navigate(getUserTypeLoginPage(userType)))}>
            {t('nav.logout')}
          </Button>
        </Card>
      )}
    </div>
  )
}

export default PageLoading
