import { Link, useLocation } from 'react-router-dom'
import { Tabs } from '@boltenergy-be/design-system'
import { SubNavProps } from 'components/Subnav/types.ts'

const SubNav = ({ className, tabs, wrapperClassName }: SubNavProps) => {
  // Router
  const location = useLocation()

  return (
    <div className={wrapperClassName}>
      <Tabs
        {...{ tabs, className }}
        activeTabPath={location.pathname}
        renderTab={(tab, props) => (
          <Link to={tab.path} key={tab.path} {...props}>
            {tab.label}
          </Link>
        )}
      />
    </div>
  )
}

export default SubNav
