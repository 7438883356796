export enum FaqSlugB2C {
  CONSUMPTION = 'consumption',
  COMMUNITY_SALES = 'community-sales',
  MOVE = 'move',
  TARIFFS = 'tariffs',
  ABOUT_BOLT = 'about-bolt',
  SWITCH = 'switch',
  PAYMENT = 'payment',
  INVOICES_AND_PAYMENTS = 'invoices-and-payments',
  MY_ADDRESSES = 'my-addresses',
  MY_DETAILS = 'my-details',
  MY_PRODUCER = 'my-producer',
  ENERGY_OVERVIEW = 'energy-overview',
  MARKET_PRICES = 'market-prices',
  PHISHING = 'phishing'
}

export enum FaqSlugB2B {
  INJECTION = 'injection',
  INVOICES = 'invoices',
  GVO = 'gvo',
  ABOUT_BOLT = 'about-bolt-b2b'
  //  ENERGY_SHARING = 'energy-sharing'
}
