import { ReactElement, useEffect, useState } from 'react'
import { AddContractStepProps, AddContractSteps, CustomerDataFormInputs } from '../../types'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import contractsStyles from '../../../Contracts.module.scss'
import styles from './CustomerData.module.scss'
import useIsComponentVisible from 'hooks/useIsComponentVisible'
import classNames from 'classnames'
import { LETTERS_NUMBERS_SLASHES_DASHES } from 'constants/regex'
import { dateStringToObject, hasNoNumber, isValidPostalCode, vatNumberToCompanyNumber } from 'utils/addContract'
import { ALLOWED_IBAN_COUNTRIES, GET_INITIAL_ADD_CONTRACT_DATA } from '../../constants'
import parse from 'html-react-parser'
import { Language } from 'store/app/types'
import FormButtons from 'features/contracts/components/form-buttons/FormButtons'
import { Towns } from 'api/types'
import { getTown } from 'api/addContract'
import IBAN from 'iban'
import { checkIfProductIsDigitalOnly, getProductFromProductCode } from 'utils/products'
import { Product, ProductConfigOption } from 'types/products'
import { PRODUCTS_CONFIG } from 'constants/products'
import { useGetProductContentQuery } from 'store/queries/cms-api'
import { Banner, Checkbox, Form, Heading, Icon, Select, TextField, Toggle } from '@boltenergy-be/design-system'
import { ContractFlowTrackingTypes } from 'types/tracking.ts'
import { useStoreSelector } from 'hooks/store.ts'
import { removeNonAlphanumericals } from 'utils/format.ts'
import { validateBelgianEnterpriseNumber } from 'utils/validation.ts'
import { NormalizedAccount, NormalizedContact } from 'store/contact/types.ts'
import DateFormField from 'components/FormFields/DateFormField/DateFormField'
import Phone from 'components/FormFields/Phone/Phone.tsx'
import { LegalForm } from 'types/general.ts'
import { DeliveryMode } from 'types/types.ts'
import { PaymentMode } from 'types/contacts.ts'

const CustomerDataStep = ({ addContractData, isSales, setCurrentStep, setNextStep }: AddContractStepProps) => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)
  const { accounts, billingContracts, contact, selected } = useStoreSelector((store) => store.contact)
  const currentAccount = addContractData.customerData.customerNumber
    ? accounts[addContractData.customerData.customerNumber]
    : isSales
      ? accounts[selected.account] || Object.values(accounts)[0]
      : undefined

  // Redux queries
  const { data: productsContent } = useGetProductContentQuery({ language })

  // Local state
  const [account, setAccount] = useState<NormalizedAccount | undefined>(currentAccount)
  const [loading, setLoading] = useState<boolean>(false)
  const [registerAsCompany, setRegisterAsCompany] = useState<boolean>(false)
  const [towns, setTowns] = useState<Towns>([])
  const [sameCorrespondenceAddress, setSameCorrespondenceAddress] = useState<boolean>(
    !!addContractData.customerData.sameCorrespondenceAddress
  )

  // Constants
  const billingContract = account ? billingContracts[account.billingContracts[0]] : undefined
  const hasBillingContract = !!billingContract
  const product = getProductFromProductCode(addContractData[AddContractSteps.PRODUCT].electricity) as Product
  const isDigitalOnly = product ? checkIfProductIsDigitalOnly(product)[ProductConfigOption.BILLING_OPTIONS] : true
  const isDirectDebitOnly = product ? PRODUCTS_CONFIG[product][ProductConfigOption.DIRECT_DEBIT_MANDATORY] : true
  const newCustomerData = typeof account === 'undefined'
  const isProfessional = !!account?.company
  const hasCustomersInDropdown = !!Object.keys(accounts).length

  // React Hook Form
  const hookForm = useForm<CustomerDataFormInputs>({
    defaultValues: {
      ...addContractData[AddContractSteps.CUSTOMER_DATA],

      // Set default deliveryMode on digital if it's a digital only product or Bolt Go simulationType
      deliveryMode: isDigitalOnly ? DeliveryMode.EMAIL : addContractData[AddContractSteps.CUSTOMER_DATA].deliveryMode,

      // Set default paymentMethod on digital if it's a digital only product
      paymentMethod: isDirectDebitOnly ? PaymentMode.DOMICILATION : addContractData[AddContractSteps.CUSTOMER_DATA].paymentMethod
    }
  })
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
    watch
  } = hookForm
  const watchTownCode = watch('correspondenceAddress.townCode')
  const watchTownName = watch('correspondenceAddress.townName')
  const watchDeliveryMode = watch('deliveryMode')
  const watchPaymentMethod = watch('paymentMethod')
  const watchSubjectToVat = watch('subjectToVat')

  // i18n
  const { t } = useTranslation(['contracts', 'common', 'validation'])

  // Custom hook
  const { ref, isComponentVisible, setIsComponentVisible } = useIsComponentVisible(false)

  /**
   * Triggered on first render
   * Resets the form to the values of the selected customer
   */
  useEffect(() => {
    if (isSales && account && !addContractData[AddContractSteps.CUSTOMER_DATA].firstName) {
      reset(generateDefaultFormValuesForCustomer(account, contact))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Fetches towns when postalcode is present in registration data state object so towns are prefilled when navigating back
   */
  useEffect(() => {
    if ((hasBillingContract || addContractData.customerData.correspondenceAddress?.postalCode) && towns.length === 0) {
      const postalCode = hasBillingContract
        ? account?.correspondenceAddress?.postalCode
        : addContractData.customerData.correspondenceAddress?.postalCode
      validatePostalCodeAndFetchTowns(postalCode as number)
    }
  }, [addContractData, towns])

  /**
   * Generates the content for the customer dropdown item
   *
   * @param {NormalizedAccount} account
   * @returns {ReactElement}
   */
  const generateCustomerDropdownItemContent = (account: NormalizedAccount): ReactElement => {
    const isProfessional = !!account.company

    return (
      <div className={styles.user}>
        <span>
          {isProfessional && `${account.company?.name} - `}
          {account.firstName} {account.lastName}
        </span>
        <span>
          {account.customerNumber} {isProfessional && `(${t('common:company')})`}
        </span>
      </div>
    )
  }

  /**
   * Generates the form values for a given customer
   *
   * @param {NormalizedAccount=} acc
   * @param {NormalizedContact=} contact
   * @returns {CustomerDataFormInputs}
   */
  const generateDefaultFormValuesForCustomer = (acc?: NormalizedAccount, contact?: NormalizedContact): CustomerDataFormInputs => {
    if (!acc) {
      return {
        ...GET_INITIAL_ADD_CONTRACT_DATA(language).customerData,
        customerNumber: undefined,
        email: contact?.email || '',
        emailConfirmation: contact?.email || '',
        mobilePhone: '',
        correspondenceAddress: {
          streetName: '',
          streetNumber: '',
          streetBox: '',
          postalCode: '',
          townCode: 0,
          townName: '',
          countryCode: 'BE'
        },
        deliveryMode: DeliveryMode.EMAIL,
        paymentMethod: PaymentMode.DOMICILATION,
        iban: ''
      }
    }

    const isCompany = !!acc.company

    if (acc.correspondenceAddress.postalCode && !isNaN(Number(acc.correspondenceAddress.postalCode))) {
      validatePostalCodeAndFetchTowns(Number(acc.correspondenceAddress.postalCode))
    }

    return {
      customerNumber: acc.customerNumber,
      isCompany,
      ...(isCompany && {
        companyNumber: vatNumberToCompanyNumber(acc.company?.enterpriseNumber ?? ''),
        legalForm: acc.company?.legalForm ?? LegalForm.SRL_BV,
        companyName: acc.company?.name || '',
        subjectToVat: acc.company?.vatApplication
      }),
      firstName: acc.firstName,
      lastName: acc.lastName,
      email: contact?.email || '',
      emailConfirmation: contact?.email || '',
      mobilePhone: contact?.phone || addContractData[AddContractSteps.CUSTOMER_DATA].mobilePhone,
      dateOfBirth: acc.birthDate ? dateStringToObject(acc.birthDate) : null,
      language: contact?.language || language,
      correspondenceAddress: {
        streetName: acc?.correspondenceAddress.streetName ?? '',
        streetNumber: acc?.correspondenceAddress.streetNumber ?? '',
        streetBox: acc?.correspondenceAddress.streetBox ?? '',
        postalCode: acc?.correspondenceAddress.postalCode ?? '',
        townCode: watchTownCode || 0,
        townName: acc?.correspondenceAddress.townName ?? '',
        countryCode: 'BE'
      },
      paymentMethod: acc?.paymentDetails?.directDebit ? PaymentMode.DOMICILATION : PaymentMode.TRANSFER,
      deliveryMode: billingContract?.deliveryMode ?? DeliveryMode.EMAIL,
      iban: acc?.paymentDetails?.iban
    }
  }

  /**
   * Handles the customer button click
   *
   * @param {NormalizedAccount|undefined} acc
   */
  const handleCustomerButtonClick = (acc?: NormalizedAccount) => {
    setAccount(acc)
    setIsComponentVisible(false)
    setSameCorrespondenceAddress(false)

    reset(generateDefaultFormValuesForCustomer(acc, contact))
  }

  /**
   * Handles the town select change & sets the town code
   * TODO: write custom hook for this (same as in AddressStep.tsx)
   *
   * @param {string} selectedTown
   */
  const handleTownSelectChange = (selectedTown: string) => {
    const town = towns.find((town) => town.townName === selectedTown)
    const townCode = town?.townCode ?? 0
    const townName = town?.townName ?? ''

    setValue('correspondenceAddress.townCode', townCode)
    setValue('correspondenceAddress.townName', townName)
  }

  /**
   * Handles the submit after validation by React Hook Form
   *
   * @param {CustomerDataFormInputs} data
   */
  const onSubmit = (data: CustomerDataFormInputs) => {
    setLoading(true)

    const { companyNumber } = data
    const formattedData = {
      ...data,
      isCompany: !!companyNumber
    }

    if (!account && !registerAsCompany) {
      formattedData.isCompany = false
      delete formattedData.companyName
      delete formattedData.companyNumber
      delete formattedData.legalForm
      delete formattedData.subjectToVat
    }

    if (sameCorrespondenceAddress) {
      formattedData.correspondenceAddress = addContractData.address.deliveryAddress
      formattedData.sameCorrespondenceAddress = true
    }

    setNextStep(formattedData, AddContractSteps.CONFIRMATION)

    setLoading(false)
  }

  /**
   * Fetches the town names based on the given postalCode
   * TODO: write custom hook for this (same as in AddressStep.tsx)
   *
   * @param {number} postalCode
   */
  const validatePostalCodeAndFetchTowns = async (postalCode: number) => {
    if (isValidPostalCode(postalCode)) {
      const response = await getTown(postalCode)

      if (response !== null) {
        const { towns } = response

        setTowns(towns)

        if (
          !towns.some(
            (town) => town.townName === (addContractData[AddContractSteps.CUSTOMER_DATA].correspondenceAddress?.townName || watchTownName)
          )
        ) {
          setValue('correspondenceAddress.townName', towns[0].townName)
          setValue('correspondenceAddress.townCode', towns[0].townCode)
        }

        return true
      }
    }

    return false
  }

  return (
    <section className={styles['customer-data']}>
      <Heading as="h1" variant="h4">
        {t('add.steps.customerData.title')}
      </Heading>
      <Banner type="informative">{t('add.steps.customerData.info')}</Banner>

      <FormProvider {...hookForm}>
        <Form onSubmit={handleSubmit(onSubmit)} className={contractsStyles['narrow-grid']}>
          {/* CUSTOMER DROPDOWN */}
          {hasCustomersInDropdown && (
            <>
              <div className={styles['customer-dropdown']}>
                <div className={styles['button-container']}>
                  <button
                    className={styles['selected-customer']}
                    onClick={(e) => {
                      e.preventDefault()
                      setIsComponentVisible(true)
                    }}
                  >
                    {account ? generateCustomerDropdownItemContent(account) : t('add.steps.customerData.customerSelect.newCustomer')}
                    <Icon name="chevronDown" />
                  </button>
                </div>

                {isComponentVisible && (
                  <>
                    <div ref={ref} className={styles['dropdown-container']}>
                      <div className={styles.customers}>
                        {Object.values(accounts).map((acc, index) => (
                          <button
                            key={index}
                            className={classNames(styles['customer-button'], {
                              [styles.active]: account?.customerNumber === acc.customerNumber
                            })}
                            onClick={() => handleCustomerButtonClick(acc)}
                          >
                            {generateCustomerDropdownItemContent(acc)}
                          </button>
                        ))}

                        <button
                          className={classNames(styles['new-customer-button'], { [styles.active]: account === undefined })}
                          onClick={(e) => {
                            e.preventDefault()
                            handleCustomerButtonClick(undefined)
                          }}
                        >
                          + {t('add.steps.customerData.customerSelect.newCustomer')}
                        </button>
                      </div>
                    </div>
                    <div className="overlay dropdown-overlay" />
                  </>
                )}
              </div>
            </>
          )}

          {newCustomerData && (
            <Checkbox
              name="registerAsCompany"
              className="grid-col-full"
              id="registerAsCompany"
              label={t('add.steps.customerData.fields.registerAsCompany')}
              checked={registerAsCompany}
              onChange={() => setRegisterAsCompany(!registerAsCompany)}
            />
          )}

          {(registerAsCompany || isProfessional) && (
            <>
              <Heading as="h2" variant="h4" className={classNames(contractsStyles['label-large'], 'grid-col-full')}>
                {t('add.steps.customerData.sections.company')}
              </Heading>

              {/* COMPANY NAME */}
              <TextField
                grid="full"
                id="companyName"
                label={t('add.steps.customerData.fields.companyName')}
                {...register('companyName', { required: t('validation:required') })}
                disabled={!newCustomerData}
                error={errors?.companyName?.message}
              />

              {/* COMPANY VAT NUMBER */}
              <TextField
                grid="full"
                id="companyNumber"
                label={t('add.steps.customerData.fields.companyNumber')}
                {...register('companyNumber', {
                  required: t('validation:required'),
                  validate: (value) => validateBelgianEnterpriseNumber(value as string) || t('validation:invalid.invalidCompanyNumber')
                })}
                disabled={!newCustomerData}
                placeholder="0123456789"
                error={errors?.companyNumber?.message}
              />

              {/* COMPANY LEGAL FORM */}
              <Select
                grid="full"
                id="legalForm"
                label={t('add.steps.customerData.fields.legalForm')}
                {...register('legalForm', { required: t('validation:required') })}
                disabled={!newCustomerData}
                error={errors?.legalForm?.message}
              >
                <option value="" disabled>
                  {t('common:defaultPlaceholders.select')}
                </option>
                {Object.values(LegalForm)
                  .sort((a, b) => t(`common:legalForms.${a}`).localeCompare(t(`common:legalForms.${b}`)))
                  .map((legalForm) => (
                    <option key={legalForm} value={legalForm}>
                      {t(`common:legalForms.${legalForm}`)}
                    </option>
                  ))}
              </Select>

              {/* COMPANY SUBJECT TO VAT */}
              <Checkbox
                className="grid-col-full"
                id="subjectToVat"
                name="subjectToVat"
                label={t('add.steps.customerData.fields.subjectToVat')}
                checked={watchSubjectToVat}
                onChange={(e) => setValue('subjectToVat', e.currentTarget.checked)}
                disabled={!newCustomerData}
              />
            </>
          )}

          <Heading as="h2" variant="h4" className="grid-col-full">
            {t('add.steps.customerData.sections.personal')}
          </Heading>

          {/* FIRST NAME */}
          <TextField
            id="firstName"
            label={t('add.steps.customerData.fields.firstName')}
            {...register('firstName', {
              required: t('validation:required'),
              minLength: { value: 2, message: t('validation:min', { min: 2 }) },
              validate: (value) =>
                hasNoNumber(value) || t('validation:noNumbers', '', { attribute: t('add.steps.customerData.fields.firstName') })
            })}
            error={errors?.firstName?.message}
            readOnly={!newCustomerData}
            grid="span 1"
          />

          {/* LAST NAME */}
          <TextField
            id="lastName"
            label={t('add.steps.customerData.fields.lastName')}
            {...register('lastName', {
              required: t('validation:required'),
              validate: (value) =>
                hasNoNumber(value) || t('validation:noNumbers', '', { attribute: t('add.steps.customerData.fields.lastName') })
            })}
            error={errors?.lastName?.message}
            readOnly={!newCustomerData}
            grid="span 1"
          />

          {/* EMAIL */}
          <TextField grid="full" id="email" label={t('add.steps.customerData.fields.email')} {...register('email')} readOnly disabled />

          {/* EMAIL CONFIRMATION */}
          <TextField
            grid="full"
            id="emailConfirmation"
            label={t('add.steps.customerData.fields.emailConfirmation')}
            {...register('emailConfirmation')}
            disabled
            readOnly
          />

          {!isSales && newCustomerData && (
            <Banner type="informative" className={contractsStyles['alert-container']}>
              <p>{parse(t('add.steps.customerData.fields.emailInfo'))}</p>
            </Banner>
          )}

          {/* MOBILE PHONE */}
          <Phone
            grid="full"
            label={t('add.steps.customerData.fields.mobilePhone')}
            hookFormIdentifier="mobilePhone"
            disabled={!newCustomerData}
            {...{ hookForm }}
          />

          {/* LANGUAGE */}
          <Select
            grid="full"
            id="language"
            label={t('add.steps.customerData.fields.language.label')}
            {...register('language')}
            disabled={!newCustomerData}
          >
            <option value={Language.NL}>{t('add.steps.customerData.fields.language.dutch')}</option>
            <option value={Language.FR}>{t('add.steps.customerData.fields.language.french')}</option>
          </Select>

          {(newCustomerData && !registerAsCompany) || (!newCustomerData && !isProfessional) ? (
            <div className={classNames('column form-group', styles['date-form-field'], 'grid-col-full')}>
              {/* DATE OF BIRTH */}
              <DateFormField label={t('add.steps.customerData.fields.birthDate.label')} isDisabled={!newCustomerData} checkDateOfBirth />
            </div>
          ) : (
            <div className="column" />
          )}

          <Heading as="h2" variant="h4" className={classNames(contractsStyles['label-large'], 'grid-col-full')}>
            {t('add.steps.customerData.sections.correspondenceAddress')}
          </Heading>

          <Checkbox
            className="grid-col-full"
            name="sameCorrespondenceAddress"
            id="sameCorrespondenceAddress"
            checked={sameCorrespondenceAddress}
            onChange={() => setSameCorrespondenceAddress(!sameCorrespondenceAddress)}
            label={t('add.steps.customerData.fields.sameCorrespondenceAddress')}
          />

          {!sameCorrespondenceAddress && (
            <>
              {/* CORRESPONDENCE ADDRESS STREET */}
              <TextField
                id="correspondenceAddress.streetName"
                label={t('add.steps.customerData.fields.correspondenceAddress.streetName')}
                {...register('correspondenceAddress.streetName', { required: t('validation:required') })}
                error={errors?.correspondenceAddress?.streetName?.message}
              />

              <div className={styles.subgrid}>
                {/* CORRESPONDENCE ADDRESS STREET NUMBER */}
                <TextField
                  id="correspondenceAddress.streetNumber"
                  label={t('add.steps.customerData.fields.correspondenceAddress.streetNumber')}
                  {...register('correspondenceAddress.streetNumber', {
                    required: t('validation:required'),
                    maxLength: { value: 5, message: t('validation:invalid.streetNumber') },
                    pattern: { value: LETTERS_NUMBERS_SLASHES_DASHES, message: t('validation:invalid.streetNumber') }
                  })}
                  error={errors?.correspondenceAddress?.streetNumber?.message}
                />

                {/* CORRESPONDENCE ADDRESS STREET BOX */}
                <TextField
                  id="correspondenceAddress.streetBox"
                  label={t('add.steps.customerData.fields.correspondenceAddress.streetBox')}
                  {...register('correspondenceAddress.streetBox')}
                  error={errors?.correspondenceAddress?.streetBox?.message}
                />
              </div>

              {/* CORRESPONDENCE ADDRESS POSTAL CODE */}
              <TextField
                id="correspondenceAddress.postalCode"
                label={t('add.steps.customerData.fields.correspondenceAddress.postalCode')}
                {...register('correspondenceAddress.postalCode', {
                  required: t('validation:required'),
                  validate: (val) => validatePostalCodeAndFetchTowns(parseInt(val as string))
                })}
                onChange={(e) => {
                  const value = e.target.value
                  setValue('correspondenceAddress.postalCode', value)
                  validatePostalCodeAndFetchTowns(parseInt(value))
                }}
                inputMode="numeric"
                error={errors?.correspondenceAddress?.postalCode?.message}
              />

              {/* CORRESPONDENCE ADDRESS TOWN NAME */}
              <Select
                id="correspondenceAddress.townName"
                label={t('add.steps.customerData.fields.correspondenceAddress.townName')}
                {...register('correspondenceAddress.townName', { required: t('validation:required') })}
                value={watchTownName}
                error={errors?.correspondenceAddress?.townName?.message}
                onChange={(e) => handleTownSelectChange(e.target.value)}
              >
                <option disabled>
                  {watch('correspondenceAddress.postalCode') === ''
                    ? t('add.steps.customerData.fields.correspondenceAddress.townNamePlaceholder')
                    : t('common:defaultPlaceholders.select')}
                </option>
                {towns.map((town) => (
                  <option key={`town.geoCode-${town.townCode}`} value={town.townName}>
                    {town.townName}
                  </option>
                ))}
              </Select>
            </>
          )}

          <fieldset>
            {/* DELIVERY MODE */}
            <legend className={contractsStyles['fieldset-legend']}>
              {isDigitalOnly ? t('add.steps.customerData.deliveryMode.digitalOnlyLabel') : t('add.steps.customerData.deliveryMode.label')}
            </legend>

            {isDigitalOnly ? (
              <p className={styles['no-margin']}>
                {t(
                  'add.steps.customerData.deliveryMode.digitalOnly',
                  'Je hebt gekozen voor Bolt {{ product }}. Als je facturen op papier wil, moet je een ',
                  {
                    product: productsContent?.[product]?.name
                  }
                )}
                <button type="button" className={styles['inline-btn']} onClick={() => setCurrentStep(AddContractSteps.PRODUCT)}>
                  {t('add.steps.customerData.chooseAnotherTariff', 'ander tarief kiezen')}
                </button>
                .
              </p>
            ) : (
              <Toggle
                isFullwidth
                active={watchDeliveryMode}
                options={[
                  {
                    value: DeliveryMode.EMAIL,
                    label: t('add.steps.customerData.fields.deliveryMode.digital')
                  },
                  {
                    value: DeliveryMode.POST,
                    label: t('add.steps.customerData.fields.deliveryMode.paper')
                  }
                ]}
                onClick={(value) => setValue('deliveryMode', value)}
              />
            )}
          </fieldset>

          <fieldset>
            {/* PAYMENT METHOD */}
            <div className={classNames({ [contractsStyles['less-margin-bottom']]: isDirectDebitOnly })}>
              <legend className={contractsStyles['fieldset-legend']}>
                {isDirectDebitOnly
                  ? t('add.steps.customerData.paymentMethod.directDebitOnlyLabel')
                  : t('add.steps.customerData.paymentMethod.label')}
              </legend>

              {!isDirectDebitOnly && (
                <Toggle
                  isFullwidth
                  active={watchPaymentMethod}
                  options={[
                    {
                      value: PaymentMode.DOMICILATION,
                      label: t('add.steps.customerData.fields.paymentMethod.directDebit')
                    },
                    {
                      value: PaymentMode.TRANSFER,
                      label: t('add.steps.customerData.fields.paymentMethod.bankTransfer')
                    }
                  ]}
                  onClick={(value) => setValue('paymentMethod', value)}
                />
              )}
            </div>

            {(isDirectDebitOnly || watchPaymentMethod === PaymentMode.DOMICILATION) && (
              <>
                {/* IBAN */}
                <TextField
                  id="iban"
                  label={t('add.steps.customerData.fields.iban')}
                  {...register('iban', {
                    required: t('validation:required'),
                    validate: {
                      valid: (value) => (value && IBAN.isValid(value)) || t('validation:invalid.iban'),
                      allowed: (value) =>
                        (value && ALLOWED_IBAN_COUNTRIES.includes(value.slice(0, 2))) || t('validation:invalid.ibanCountries')
                    }
                  })}
                  onPaste={(e) => {
                    const paste = removeNonAlphanumericals(e.clipboardData.getData('text'))
                    e.currentTarget.value = paste
                    setValue('iban', paste)
                    e.preventDefault()
                  }}
                  error={errors?.iban?.message}
                />

                {isDirectDebitOnly && (
                  <p>
                    {t(
                      'add.steps.customerData.paymentMethod.directDebitOnly',
                      'Je hebt gekozen voor Bolt {{ product }}. Daardoor ben je verplicht te betalen via domiciliëring. Als je dat niet wil, moet je een ',
                      { product: productsContent?.[product]?.name }
                    )}
                    <button type="button" className={styles['inline-btn']} onClick={() => setCurrentStep(AddContractSteps.PRODUCT)}>
                      {t('add.steps.customerData.chooseAnotherTariff', 'ander tarief kiezen')}
                    </button>
                    .
                  </p>
                )}

                <small className={classNames(contractsStyles.description, contractsStyles['is-small'])}>
                  {t('add.steps.customerData.fields.mandateLegal')}
                </small>
              </>
            )}
          </fieldset>

          <FormButtons currentStep={AddContractSteps.CUSTOMER_DATA} loading={loading} trackingId={ContractFlowTrackingTypes.ADD} />
        </Form>
      </FormProvider>
    </section>
  )
}

export default CustomerDataStep
