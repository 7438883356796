import { Accordion, Drawer, Heading } from '@boltenergy-be/design-system'
import SearchBar from 'components/Search/SearchBar.tsx'
import { t } from 'i18next'
import parse from 'html-react-parser'
import { Fragment, useMemo, useState } from 'react'
import { FaqQuestion } from 'types/types.ts'
import { searchMultipleKeys } from 'utils/objects.ts'
import styles from './FaqDrawer.module.scss'
import { routes } from 'types/routes.ts'
import Link from 'components/Link/Link.tsx'
import { FaqDrawerProps } from './types.ts'
import useWindowSize from 'hooks/useWindowSize.tsx'

const EMPTY_FAQ_SUBCATEGORY = 'General'

const FaqDrawer = ({ isOpen, onRequestClose, questions, title, hasSearch = true }: FaqDrawerProps) => {
  // Window size
  const { isTablet } = useWindowSize()

  // Local state
  const [searchQuery, setSearchQuery] = useState<string>('')

  // Memoized filtered questions
  const filteredQuestions = useMemo<FaqQuestion[]>(
    () => (hasSearch ? questions.filter((q) => searchMultipleKeys([q.question, q.answer], searchQuery)) : questions),
    [hasSearch, questions, searchQuery]
  )

  // Group questions by subcategory
  const groupedQuestions = useMemo(() => {
    return filteredQuestions.reduce(
      (acc, question) => {
        const subcategory = question?.subcategory?.data?.attributes?.name || EMPTY_FAQ_SUBCATEGORY
        if (!acc[subcategory]) {
          acc[subcategory] = []
        }
        acc[subcategory].push(question)
        return acc
      },
      {} as Record<string, FaqQuestion[]>
    )
  }, [filteredQuestions])

  return (
    <Drawer {...{ isOpen, onRequestClose }} title={title} className={styles.faq}>
      {hasSearch && (
        <SearchBar className={styles.search} onInput={(query) => setSearchQuery(query)} placeholder={t('faq.searchPlaceholder')} />
      )}

      {Object.keys(groupedQuestions).length > 0 ? (
        Object.entries(groupedQuestions).map(([subcategory, questions]) => (
          <Fragment key={subcategory}>
            {subcategory !== EMPTY_FAQ_SUBCATEGORY && (
              <Heading as="h2" variant="h5" className="mt-400">
                {subcategory}
              </Heading>
            )}

            <Accordion
              variant={isTablet ? 'transparent' : 'filled'}
              items={questions.map((item) => ({ title: parse(item?.question), children: parse(item?.answer) }))}
            />
          </Fragment>
        ))
      ) : (
        <div className={styles['no-results-container']}>
          <small>{t('footer.noAnswer', { ns: 'support' })}</small>
          <Link href={routes.SUPPORT_CONTACT} variant="secondary" size="small">
            {t('contactTab', { ns: 'support' })}
          </Link>
        </div>
      )}
    </Drawer>
  )
}

export default FaqDrawer
