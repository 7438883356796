import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import EnergyOverviewCarousel from 'components/Slider/EnergyOverviewSlider/EnergyOverviewCarousel'
import EnergyOverviewSlide from 'components/Slider/EnergyOverviewSlide/EnergyOverviewSlide'
import { formatNumber } from 'utils/format'
import { getAmbition, getProvinceCode, getSelectedRegion } from 'utils/energyOverview'
import { CommunityProps } from 'components/EnergyOverview/types'
import { BrandColors } from 'components/Slider/EnergyOverviewSlide/types'
import StaticMap from 'components/StaticMap/StaticMap'
import ThanksSlide from 'components/Slider/ThanksSlide/ThanksSlide'
import RenewableSourceCard from 'components/EnergyOverview/RenewableSourceCard/RenewableSourceCard'
import { PROVINCES_WITH_CITIES } from 'constants/energyOverview'
import { City } from './types'
import styles from './EnergyOverviewSection.module.scss'
import { Province, Region } from 'pages/App/energy-overview/types'
import SmallProducerCard from 'components/SmallProducerCard/SmallProducerCard'
import svgAmbition2 from '/images/year-overview/ambition-2.svg'
import svgAmbition3 from '/images/year-overview/ambition-3.svg'
import svgAmbition4 from '/images/year-overview/ambition-4.svg'
import imageMiniGenerator from '/images/year-overview/mini-generator.png'
import Card from 'components/Card/Card.tsx'
import { useStoreSelector } from 'hooks/store.ts'
import { selectCurrentContracts } from 'store/contact/selectors.ts'
import { ProducerEnergyType } from 'types/producer.ts'

const SectionCommunity = ({ data, openFeedbackWidget }: CommunityProps) => {
  // REDUX STORE
  const { language } = useStoreSelector((store) => store.app)
  const contactStore = useStoreSelector((store) => store.contact)

  // i18n
  const { t } = useTranslation()

  // Local state
  const [city, setCity] = useState<City | null>(null)
  const [region, setRegion] = useState<Region | null>(null)
  const [zoom, setZoom] = useState<number>(11)
  const [province, setProvince] = useState<Province | null>(null)
  const [confetti, setConfetti] = useState<boolean>(false)

  // Constants
  const { electricity } = selectCurrentContracts(contactStore).serviceContracts
  const { households, producers, carbonDioxide, miniProducers } = data

  // Memo's
  const ambition = useMemo(
    () => getAmbition(households.people, region || Region.FLA, language, typeof city?.name === 'string' ? city.name : city?.name[language]),
    [households.people, region, language, city]
  )
  const emissionBolt = useMemo(
    () => (city?.population ? Math.round(((producers.usage * 209) / 1000) * (households.people / city.population)) : null),
    [city?.population, households.people, producers.usage]
  )

  const getAmbitionChartImage = useCallback((): string => {
    switch (ambition.level) {
      case 3:
        return svgAmbition3
      case 4:
        return svgAmbition4
      default:
        return svgAmbition2
    }
  }, [ambition.level])

  /**
   * Calculate which city to compare to
   **/
  useEffect(() => {
    // 1. Get postalCode
    const postalCode = electricity.deliveryPoint.address?.postalCode || '1000'

    // 2. Get/set province code
    const provinceCode = getProvinceCode(parseInt(postalCode as string))
    setProvince(provinceCode)

    // 3. Get/set region
    setRegion(getSelectedRegion(provinceCode, language, true))

    // 4. Get cities
    const filteredProvince = PROVINCES_WITH_CITIES.filter((province) => province.province === provinceCode)
    const { cities } = filteredProvince[0]

    // 5. Compare households.people with population of cities
    const goal = households.people
    // weird fix for TS issue with reduce (see https://github.com/microsoft/TypeScript/issues/33591#issuecomment-841832390)
    const _cities: Array<City> = cities
    const beatenCity = _cities.filter((city) => city.population <= goal).sort((a, b) => b.population - a.population)[0]

    setCity(beatenCity)

    // 6. Calc/set zoom level
    // the default zoom (12) + (index of city inside array (sorted big to small) / 1.5 (to reduce zoom level on small cities))
    const zoom: number = 12 + cities.findIndex((city: City) => city.population === beatenCity.population) / 1.5

    setZoom(zoom)
  }, [contactStore, electricity.deliveryPoint.address?.postalCode, households.people, language])

  return (
    <>
      <Card.Title>{t('community.title', { ns: 'energyOverview' })}</Card.Title>

      <EnergyOverviewCarousel
        id="community"
        openFeedbackWidget={() => openFeedbackWidget(true)}
        triggerConfetti={(value) => setConfetti(value)}
      >
        {/* HOUSEHOLDS SLIDE */}
        {households?.total && (
          <EnergyOverviewSlide
            color={BrandColors.YELLOW}
            data={formatNumber(households.people)}
            label={t('community.households.label', { ns: 'energyOverview' })}
            description={t('community.households.description', {
              amount: formatNumber(households.people),
              city: city ? (typeof city.name === 'string' ? city.name : city.name[language]) : '',
              ns: 'energyOverview'
            })}
          >
            {!!city && <StaticMap city={city?.name} zoom={zoom} />}
          </EnergyOverviewSlide>
        )}

        {/* CARBON DIOXIDE AVERAGE SLIDE */}
        {carbonDioxide?.reduction && emissionBolt && city && (
          <EnergyOverviewSlide
            color={BrandColors.BLUE}
            data={`-${formatNumber(carbonDioxide.reduction / 1000)}`}
            label={t('community.carbonDioxideAverage.label', { ns: 'energyOverview' })}
            metric={t('community.carbonDioxideAverage.metric', { ns: 'energyOverview' })}
            description={t('community.carbonDioxideAverage.description', {
              amount: formatNumber(carbonDioxide.reduction / 1000),
              ns: 'energyOverview'
            })}
          >
            <div className={classNames(styles.graph, styles['graph-emissions-community'])}>
              <div className={classNames(styles.bar, styles.community)}>
                <data
                  value={emissionBolt}
                  className={styles['with-bolt-icon']}
                  style={{
                    height: `${(emissionBolt / city.emission) * 100}%`
                  }}
                />
                <div className={styles.label}>Bolt</div>
              </div>

              {city && (
                <div key={province} className={styles.bar}>
                  <data value={city.emission} style={{ height: '100%' }} />
                  <div className={styles.label}>{typeof city.name === 'string' ? city.name : city.name[language]}</div>
                </div>
              )}
            </div>
          </EnergyOverviewSlide>
        )}

        {/* PRODUCERS SLIDE */}
        {producers?.total && (
          <EnergyOverviewSlide
            color={BrandColors.PINK}
            data={formatNumber(producers.total)}
            label={t('community.producers.label', { ns: 'energyOverview' })}
            description={t('community.producers.description', { amount: formatNumber(producers.total), ns: 'energyOverview' })}
          >
            <div className={styles['renewable-sources-container']}>
              {producers.production?.solar && (
                <RenewableSourceCard
                  energyType={ProducerEnergyType.SOLAR}
                  tag={t(`energyType.${ProducerEnergyType.SOLAR}`, { ns: 'producer' })}
                  pct={Math.round((producers.production.solar / producers.production.total) * 100)}
                  mwh={formatNumber(producers.production.solar / 1000)}
                />
              )}
              {producers.production?.wind && (
                <RenewableSourceCard
                  energyType={ProducerEnergyType.WIND}
                  tag={t(`energyType.${ProducerEnergyType.WIND}`, { ns: 'producer' })}
                  pct={Math.round((producers.production.wind / producers.production.total) * 100)}
                  mwh={formatNumber(producers.production.wind / 1000)}
                />
              )}
              {producers.production?.water && (
                <RenewableSourceCard
                  energyType={ProducerEnergyType.WATER}
                  tag={t(`energyType.${ProducerEnergyType.WATER}`, { ns: 'producer' })}
                  pct={Math.round((producers.production.water / producers.production.total) * 100)}
                  mwh={formatNumber(producers.production.water / 1000)}
                />
              )}
              {producers.production?.bio && (
                <RenewableSourceCard
                  energyType={ProducerEnergyType.BIO}
                  tag={t(`energyType.${ProducerEnergyType.BIO}`, { ns: 'producer' })}
                  pct={Math.round((producers.production.bio / producers.production.total) * 100)}
                  mwh={formatNumber(producers.production.bio / 1000)}
                />
              )}
            </div>
          </EnergyOverviewSlide>
        )}

        {/* BOLT AMBITION SLIDE */}
        {ambition.nextGoal && !!ambition.list?.length && (
          <EnergyOverviewSlide
            color={BrandColors.YELLOW}
            data={formatNumber(ambition.nextGoal)}
            label={t('community.ambition.label', { ns: 'energyOverview' })}
            description={t('community.ambition.description', {
              now: city ? (typeof city.name === 'string' ? city.name : city.name[language]) : '',
              soon: ambition.list[ambition.level],
              ns: 'energyOverview'
            })}
          >
            <div className={styles['graph-ambition-container']}>
              <img src={getAmbitionChartImage()} alt={t('community.ambition.imageAlt', { ns: 'energyOverview' })} />
              <ul className={styles.cities}>
                {ambition.list.map((city) => (
                  <li key={city} className={classNames({ [styles.bolt]: city.toLowerCase() === 'bolt' })}>
                    {city}
                  </li>
                ))}
              </ul>
            </div>
          </EnergyOverviewSlide>
        )}

        {/* MINI GENERATORS SLIDE */}
        {miniProducers?.amount && (
          <EnergyOverviewSlide
            color={BrandColors.ORANGE}
            data={formatNumber(miniProducers.amount)}
            label={t('community.miniGenerators.label', { ns: 'energyOverview' })}
            description={t('community.miniGenerators.description', {
              households: formatNumber(miniProducers.households),
              ns: 'energyOverview'
            })}
          >
            <img src={imageMiniGenerator} alt={t('personal.miniGenerators.imageAlt', { ns: 'energyOverview' })} />
            <SmallProducerCard
              className={styles['small-card']}
              energyType={ProducerEnergyType.SOLAR}
              location={t('community.miniGenerators.location', { ns: 'energyOverview' })}
            />
          </EnergyOverviewSlide>
        )}

        {/* THANKS SLIDE */}
        <ThanksSlide popConfetti={confetti} color="blue" text={t('community.thanks.text', { ns: 'energyOverview' })} />
      </EnergyOverviewCarousel>
    </>
  )
}

export default SectionCommunity
