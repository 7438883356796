import { DeliveryMode, PreviousIndex, SimplifiedContractStatus, TimeframeCode } from './types'
import { VolumeEntry } from 'pages/App/consumption/your-consumption/components/consumption-section/types.ts'
import { CounterTypes, Situation } from 'features/contracts/add/types.ts'
import { ELProduct, NGProduct } from 'types/products.ts'
import { Region } from 'api/types.ts'
import { AddressWithCountryAndTownCode } from 'types/general.ts'
import { TagProps } from '@boltenergy-be/design-system'

// NEW TYPES

export enum Direction {
  PRODUCTION = 'PRODUCTION',
  COMBINATION = 'COMBINATION',
  CONSUMPTION = 'CONSUMPTION'
}

export enum EnergyType {
  ELECTRICITY = 'EL',
  GAS = 'NG'
}

export enum ReadingFrequency {
  CONTINUOUS = 'CONTINUOUS',
  MONTH = 'MONTH',
  YEAR = 'YEAR'
}

export enum TargetServiceComponent {
  COMM_OFFTAKE_AND_INJECTION = 'COMM_OFFTAKE_AND_INJECTION',
  COMPENSATED_OFFTAKE = 'COMPENSATED_OFFTAKE',
  PURE_INJECTION = 'PURE_INJECTION',
  PURE_OFFTAKE = 'PURE_OFFTAKE'
}

export enum PhysicalMeterType {
  SMART_METER = 'SMART_METER',
  MECHANICAL_METER = 'MECHANICAL_METER',
  BUDGET_METER = 'BUDGET_METER',
  ELECTRONIC_METER = 'ELECTRONIC_METER'
}

export enum ContractStatus {
  ABANDONED = 'Abandoned',
  ACTIVE = 'Active',
  CANCELLED = 'Cancelled',
  EFFECTIVE = 'Effective',
  ERROR = 'Error',
  ILC_ONGOING = 'ILConGoing',
  INACTIVE = 'Inactive',
  RECOVERY = 'Recovery',
  REJECTED = 'Rejected',
  TERMINATED = 'Terminated',
  UNKNOWN_POINT = 'UnknownPoint',
  VALIDATION_REJECT = 'ValidationReject',
  WAITING_14_DAYS = 'waiting-14d',
  WRONG_ENERGY_PRODUCT = 'WrongEnergyProduct'
}

export enum GoStatus {
  NA = 'NA',
  INACTIVE = 'Inactive',
  CONFIRMED = 'Confirmed'
}

export type BillingContract = {
  // Key
  contractNumber: string

  // Foreign keys
  accountSfId?: string
  customerNumber: string
  opportunitySfId?: string

  // Properties
  deliveryMode: DeliveryMode

  // Relations
  serviceContracts: {
    electricity: ServiceContractElectricity
    gas?: ServiceContractGas
  }
}

export type ServiceContract<ET extends EnergyType> = {
  // Key
  contractNumber: string

  // Foreign keys
  billingContractNumber: string
  customerNumber: string

  // Properties
  detail: {
    contractualEndDate?: string // YYYY-MM-DD
    contractualStartDate: string // YYYY-MM-DD
    dynamicTariff?: boolean
    effectiveEndDate?: string
    energyType: ET
    goStatus?: GoStatus
    injectionContract?: string
    instalment?: number
    monthlyBilling?: boolean
    priceListUrl?: string
    producer: ET extends EnergyType.ELECTRICITY ? string : null
    productCode: ET extends EnergyType.ELECTRICITY ? ELProduct : NGProduct
    status: ContractStatus
    subscriptionDate: string
    renewalPriceList: {
      fromDate: string
      url: string
    }
  }
  deliveryPoint: {
    address: AddressWithCountryAndTownCode
    direction: Direction
    ean: string
    meterNumber?: string
    meterType?: PhysicalMeterType
    readingFrequency: ReadingFrequency
    region: Region
    previousIndex?: PreviousIndex
    serviceComponent: TargetServiceComponent
  }
}
export type ServiceContractElectricity = ServiceContract<EnergyType.ELECTRICITY>
export type ServiceContractGas = ServiceContract<EnergyType.GAS>
export type UnknownServiceContract = ServiceContractElectricity | ServiceContractGas

// REQUEST & RESPONSE TYPES

export type CreateMeterReadingsRequest = {
  date: string // ISO string
  contractNumber: string
  ean: string
  indexes?: Index[]
}

export type GetLastMeterReadingResponseData = {
  lastMeterReading: MeterReadingEntry | null
}

export type GetServiceContractVolumesResponseData = {
  volumes: VolumeEntry[]
}

// GENERAL CONTRACT TYPES

export type ContractDataWithIndexes = {
  contractNumber: string
  ean: string
  indexes?: Index[]
}

export type Index = {
  meterNumber: string
  registerName: string
  timeframeCode: TimeframeCode
  value: number
  direction: Direction
}

export type MeterReading = {
  singleRate?: number
  exclNight?: number
  doubleRate?: {
    day?: number
    night?: number
  }
}

export type MeterReadings = {
  electricity: MeterReadingEntry | null
  gas?: MeterReadingEntry | null
}

export type MeterReadingEntry = {
  ean: string
  date: string // ISO date string
  source: MeterReadingSource
  consumption: MeterReading
  injection?: MeterReading
}

export type MeterReadingsPayload = {
  date: string // ISO
  electricity: {
    ean: string
    consumption: MeterReading
    injection?: MeterReading
  }
  gas?: {
    ean: string
    consumption: MeterReading
  }
}

export type FormatContractStatus = {
  key:
    | SimplifiedContractStatus.INACTIVE
    | SimplifiedContractStatus.IN_PROGRESS
    | SimplifiedContractStatus.CANCELLED
    | 'activeSince'
    | 'startingOn'
    | 'terminatedSince'
    | 'terminatingOn'
  color: TagProps['color']
  date?: string // ISO
}

export type CheckHasMeterAndReadingInputs = {
  situation: Situation
  counterType: CounterTypes
  contractStartDate: string | null
}

// FUNCTION TYPES

export type AccessRights = {
  [AccessRightsKeys.BILLING_CYCLES]: {
    canAccess: boolean
    showContent: boolean
  }
  [AccessRightsKeys.CONSUMPTION]: {
    showContent: boolean
  }
  [AccessRightsKeys.INSTALMENT]: {
    canAccess: boolean
    showContent: boolean
  }
  [AccessRightsKeys.METER_READINGS]: {
    canAccess: boolean
    showContent: boolean
  }
  [AccessRightsKeys.MARKET_PRICES]: {
    canAccess: boolean
  }
}

// ENUMS

export enum AccessRightsKeys {
  BILLING_CYCLES = 'billingCycles',
  CONSUMPTION = 'consumption',
  INSTALMENT = 'instalment',
  METER_READINGS = 'meterReadings',
  MARKET_PRICES = 'marketPrices'
}

export enum Granularity {
  MONTH = 'MONTH',
  DAY = 'DAY',
  HOUR = 'HOUR'
  // QUARTER_HOUR = 'QUARTER_HOUR'
}

export enum MeterIndexType {
  TOTAL = 'total',
  DAY = 'day',
  NIGHT = 'night',
  EXCL_NIGHT = 'exclNight'
}

export enum MeterReadingSource {
  CLIENT_PROVIDED = 'CLIENT_PROVIDED',
  EOD_DGO = 'EOD_DGO'
}
