import { useEffect, useState } from 'react'
import { useNavigate, Outlet } from 'react-router'
import { isAuthenticated } from 'utils/localStorageService.ts'
import { routes } from 'types/routes.ts'
import { checkIsSales } from 'utils/sales.ts'
import { ProtectedRouteProps } from 'router/ProtectedRoute/types.ts'
import { useStoreSelector } from 'hooks/store.ts'
import { getUserTypeLoginPage } from 'utils/user.ts'
import PageLoading from 'components/PageLoading/PageLoading.tsx'

const ProtectedRoute = ({ userTypes }: ProtectedRouteProps) => {
  // Redux
  const { userType } = useStoreSelector((state) => state.auth)

  // Router
  const navigate = useNavigate()

  // Local state
  const [authorized, setAuthorized] = useState<boolean>(false)

  /**
   * Checks if the user is authenticated, if not, redirects to the login page
   */
  useEffect(() => {
    const authenticatedUserTypes = isAuthenticated()

    if (userTypes.every((userType) => !authenticatedUserTypes[userType])) {
      navigate(checkIsSales() ? routes.LOGIN_SALES : getUserTypeLoginPage(userType))
    } else {
      setAuthorized(true)
    }
  }, [])

  return authorized ? <Outlet /> : <PageLoading isLoading />
}

export default ProtectedRoute
