import imageMiniGeneratorNl from '/images/year-overview/mini-opwekkers-nl.png'
import imageMiniGeneratorFr from '/images/year-overview/mini-opwekkers-fr.png'
import referralDefaultImg from '/images/referral/referral-default.jpg'
import classNames from 'classnames'
import ComparisonCard from 'components/EnergyOverview/ComparisonCard/ComparisonCard'
import GasTipCard from 'components/EnergyOverview/GasTipCard/GasTipCard'
import { PersonalProps } from 'components/EnergyOverview/types'
import EnergyOverviewSlide from 'components/Slider/EnergyOverviewSlide/EnergyOverviewSlide'
import { BrandColors } from 'components/Slider/EnergyOverviewSlide/types'
import EnergyOverviewCarousel from 'components/Slider/EnergyOverviewSlider/EnergyOverviewCarousel'
import ThanksSlide from 'components/Slider/ThanksSlide/ThanksSlide'
import VerticalSlider from 'components/Slider/VerticalSlider/VerticalSlider'
import SmallProducerCard from 'components/SmallProducerCard/SmallProducerCard'
import { DATE_FORMAT_LONG } from 'constants/constants'
import dayjs from 'dayjs'
import parse from 'html-react-parser'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Language } from 'store/app/types'
import { formatAmount } from 'utils/format'
import styles from './EnergyOverviewSection.module.scss'
import Card from 'components/Card/Card.tsx'
import Link from 'components/Link/Link.tsx'
import { getProducerLocation } from 'utils/producer.ts'
import { useStoreSelector } from 'hooks/store.ts'
import { selectCurrentAccount, selectCurrentContracts, selectProducerId } from 'store/contact/selectors.ts'
import LoadingCard from 'components/LoadingCard/LoadingCard.tsx'
import { useGetProducerByIdQuery } from 'store/queries/bolt-api/producers'
import { getLowerCaseLanguage } from 'utils/app.ts'
import BackdropIcon from 'components/backdrop-icon/BackdropIcon.tsx'
import { Button, Modal } from '@boltenergy-be/design-system'

const SectionPersonal = ({ data, communityReferrals, openFeedbackWidget }: PersonalProps) => {
  // REDUX STORE
  const { language } = useStoreSelector((store) => store.app)
  const { billingContracts, selected, accounts, isProducer } = useStoreSelector((store) => store.contact)
  const billingContract = selectCurrentContracts({ billingContracts, selected })
  const account = selectCurrentAccount({ accounts, selected })
  const producerId = selectProducerId(billingContract)

  // Redux Queries
  const { data: producerData, isLoading } = useGetProducerByIdQuery({ producerIdOrSlug: producerId })

  // i18n
  const { t } = useTranslation(['energyOverview', 'common'])

  // Local state
  const [infoModalOpen, setInfoModalOpen] = useState<boolean>(false)
  const [confetti, setConfetti] = useState<boolean>(false)

  // Constants
  const lowercaseLanguage = getLowerCaseLanguage(language)
  const { electricity, gas, referrals } = data

  return (
    <>
      <Card.Title>{t('personal.title')}</Card.Title>

      {isLoading ? (
        <LoadingCard />
      ) : (
        <EnergyOverviewCarousel
          id="personal"
          openFeedbackWidget={() => openFeedbackWidget(true)}
          triggerConfetti={(value) => setConfetti(value)}
        >
          {/* CONSUMED ELECTRICITY SLIDE */}
          {electricity?.consumed?.total && producerData?.producer && (
            <EnergyOverviewSlide
              data={formatAmount(electricity.consumed.total, 0)}
              label={t('personal.electricityProducer.label')}
              metric="kWh"
              description={t('personal.electricityProducer.description', {
                producer: producerData.producer.name[lowercaseLanguage] ?? '',
                from: dayjs(electricity.consumed.from).locale(lowercaseLanguage).format(DATE_FORMAT_LONG),
                to: dayjs(electricity.consumed.to).locale(lowercaseLanguage).format(DATE_FORMAT_LONG),
                ns: 'energyOverview'
              })}
            >
              {producerData.producer.images && (
                <img
                  src={producerData.producer.images.profile?.url || producerData.producer.images.header?.url}
                  alt={t('alt', '', {
                    producer: producerData.producer.name[lowercaseLanguage] ?? '',
                    ns: 'producer'
                  })}
                />
              )}
              <SmallProducerCard
                className={styles['small-card']}
                energyType={producerData.producer.energyType}
                location={getProducerLocation(lowercaseLanguage, producerData.producer.location)}
                title={producerData.producer.name[lowercaseLanguage] ?? ''}
              />
            </EnergyOverviewSlide>
          )}

          {/* CONSUMED ELECTRICITY COMPARISON SLIDE */}
          {electricity?.consumed?.total && (
            <EnergyOverviewSlide
              data={formatAmount(electricity.consumed.total, 0)}
              label={t('personal.electricityComparison.label')}
              metric="kWh"
              description={t('personal.electricityComparison.description', {
                phone: formatAmount(electricity.consumed.amountOfPhoneCharges, 0),
                tv: formatAmount(electricity.consumed.tvHours, 0),
                ns: 'energyOverview'
              })}
            >
              <VerticalSlider>
                <ComparisonCard
                  data={formatAmount(electricity.consumed.microwaveHours, 0)}
                  description={t('personal.electricityComparison.microwave')}
                  icon={<BackdropIcon name="microwave" />}
                />
                <ComparisonCard
                  data={formatAmount(electricity.consumed.amountOfPhoneCharges, 0)}
                  description={t('personal.electricityComparison.phone')}
                  icon={<BackdropIcon name="smartPhone" />}
                />
                <ComparisonCard
                  data={formatAmount(electricity.consumed.laundryMachines, 0)}
                  description={t('personal.electricityComparison.laundry')}
                  icon={<BackdropIcon name="washingMachine" />}
                />
                <ComparisonCard
                  data={formatAmount(electricity.consumed.tvHours, 0)}
                  description={t('personal.electricityComparison.tv')}
                  icon={<BackdropIcon name="tv" />}
                />
                <ComparisonCard
                  data={formatAmount(electricity.consumed.vacuumHours, 0)}
                  description={t('personal.electricityComparison.vacuum')}
                  icon={<BackdropIcon name="vacuum" />}
                />
              </VerticalSlider>
            </EnergyOverviewSlide>
          )}

          {/* CARBON DIOXIDE AVERAGE SLIDE */}
          {electricity?.carbonDioxide?.reduction && (
            <EnergyOverviewSlide
              color={BrandColors.BLUE}
              data={`-${formatAmount(electricity.carbonDioxide.reduction, 0)}`}
              label={t('personal.carbonDioxideAverage.label')}
              metric={t('personal.carbonDioxideAverage.metric')}
              description={t('personal.carbonDioxideAverage.description')}
            >
              <div className={classNames(styles.graph, styles['graph-emissions-personal'])}>
                <data
                  value={100 / 7.5}
                  className={classNames(styles.you, styles['with-bolt-icon'], { [styles.fr]: language === Language.FR })}
                />
                <data value={100} className={classNames(styles.average, { [styles.fr]: language === Language.FR })} />
              </div>
            </EnergyOverviewSlide>
          )}

          {/* CARBON DIOXIDE COMPARISON SLIDE */}
          {electricity?.carbonDioxide?.reduction && (
            <EnergyOverviewSlide
              color={BrandColors.BLUE}
              data={`-${formatAmount(electricity.carbonDioxide.reduction, 0)}`}
              label={t('personal.carbonDioxideComparison.label')}
              metric={t('personal.carbonDioxideComparison.metric')}
              description={t('personal.carbonDioxideComparison.description')}
            >
              <VerticalSlider>
                <ComparisonCard
                  data={formatAmount(electricity.carbonDioxide.dieselKilometers, 0)}
                  description={t('personal.carbonDioxideComparison.diesel')}
                  icon={<BackdropIcon name="car" />}
                />
                <ComparisonCard
                  data={formatAmount(electricity.carbonDioxide.trees, 0)}
                  description={t('personal.carbonDioxideComparison.trees')}
                  icon={<BackdropIcon name="trees" />}
                />
                <ComparisonCard
                  data={formatAmount(electricity.carbonDioxide.veggieDays, 0)}
                  description={t('personal.carbonDioxideComparison.veggie')}
                  icon={<BackdropIcon name="saladBowl" />}
                />
                <ComparisonCard
                  data={formatAmount(electricity.carbonDioxide.avocados, 0)}
                  description={t('personal.carbonDioxideComparison.avocado')}
                  icon={<BackdropIcon name="avocado" />}
                />
                <ComparisonCard
                  data={formatAmount(electricity.carbonDioxide.steaks, 0)}
                  description={t('personal.carbonDioxideComparison.steak')}
                  icon={<BackdropIcon name="steak" />}
                />
              </VerticalSlider>
            </EnergyOverviewSlide>
          )}

          {/* GAS SLIDE */}
          {gas?.consumed?.total && (
            <EnergyOverviewSlide
              color={BrandColors.PURPLE}
              data={formatAmount(gas.consumed.total, 0)}
              label={t('personal.gas.label')}
              metric="kWh"
              description={t('personal.gas.description', {
                from: dayjs(gas.consumed.from).locale(language).format(DATE_FORMAT_LONG),
                to: dayjs(gas.consumed.to).locale(language).format(DATE_FORMAT_LONG),
                ns: 'energyOverview'
              })}
            >
              <VerticalSlider duration={30000} reverse>
                <GasTipCard icon="thermostat" tip={t('personal.gas.tips.temperature')} orderNumber={1} />
                <GasTipCard icon="shower" tip={t('personal.gas.tips.shower')} orderNumber={2} />
                <GasTipCard icon="gas" tip={t('personal.gas.tips.heating')} orderNumber={3} />
                <GasTipCard icon="moon" tip={t('personal.gas.tips.night')} orderNumber={4} />
                <GasTipCard icon="hills" tip={t('personal.gas.tips.away')} orderNumber={5} />
              </VerticalSlider>

              {/* INFO MODAL TRIGGER */}
              <Button
                leadingIcon="info"
                variant="tertiary"
                className={styles['info-button']}
                size="small"
                aria-label="info"
                onClick={() => setInfoModalOpen(true)}
              />

              {/* INFO MODAL */}
              <Modal isOpen={infoModalOpen} title={parse(t('personal.gas.modalText'))} onRequestClose={() => setInfoModalOpen(false)} />
            </EnergyOverviewSlide>
          )}

          {/* MINI GENERATORS SLIDE */}
          {electricity?.produced?.total && (
            <EnergyOverviewSlide
              color={BrandColors.ORANGE}
              data={formatAmount(electricity.produced.total, 0)}
              label={t('personal.miniGenerators.label')}
              metric="kWh"
              description={t('personal.miniGenerators.description', {
                from: dayjs(electricity.produced.from).locale(language).format(DATE_FORMAT_LONG),
                to: dayjs(electricity.produced.to).locale(language).format(DATE_FORMAT_LONG),
                ns: 'energyOverview'
              })}
            >
              <figure className={styles['mini-figure']}>
                <img
                  src={language === Language.FR ? imageMiniGeneratorFr : imageMiniGeneratorNl}
                  alt={t('personal.miniGenerators.imageAlt')}
                />
              </figure>
            </EnergyOverviewSlide>
          )}

          {/* REFERRALS SLIDE */}
          {(referrals?.amount || communityReferrals) && (
            <EnergyOverviewSlide
              color={BrandColors.PINK}
              data={formatAmount(referrals?.amount || communityReferrals, 0)}
              label={t(`${!referrals?.amount ? 'personal' : 'community'}.referrals.label`)}
              description={t(`${referrals?.amount ? 'personal' : 'community'}.referrals.description`, {
                amount: referrals?.amount || communityReferrals,
                ns: 'energyOverview'
              })}
            >
              <img src={referralDefaultImg} className={styles['referrals-image']} alt={t('personal.referrals.imageAlt')} />
              <div className={styles['referrals-container']}>
                <Link
                  as="a"
                  representation="button"
                  href={
                    language === Language.FR
                      ? `https://www.boltenergie.be/fr/convaincre-un-ami${referrals?.code ? `?generatedReferralCode=${referrals?.code}` : ''}`
                      : `https://www.boltenergie.be/nl/overtuig-een-vriend${
                          referrals?.code ? `?generatedReferralCode=${referrals.code}` : ''
                        }`
                  }
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  {t(`${referrals?.amount ? 'personal' : 'community'}.referrals.button`)}
                </Link>
              </div>
            </EnergyOverviewSlide>
          )}

          {/* THANKS SLIDE */}
          {!isProducer && <ThanksSlide popConfetti={confetti} name={account.firstName} text={t('personal.thanks.text')} />}
        </EnergyOverviewCarousel>
      )}
    </>
  )
}

export default SectionPersonal
