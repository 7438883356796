import { useGetFaqByMultipleSlugsQuery } from 'store/queries/cms-api'
import { useStoreSelector } from 'hooks/store.ts'
import { FaqSlugB2B, FaqSlugB2C } from 'types/faq'
import SearchBar from 'components/Search/SearchBar.tsx'
import styles from './Faq.module.scss'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import { Heading, Icon } from '@boltenergy-be/design-system'
import parse, { Element } from 'html-react-parser'
import Link from 'components/Link/Link.tsx'
import { routes } from 'types/routes.ts'
import FaqDrawer from 'components/faq-drawer/FaqDrawer.tsx'
import { useMemo, useState } from 'react'
import { FaqCategory } from 'types/types.ts'
import { useTranslation } from 'react-i18next'
import { selectCurrentContracts } from 'store/contact/selectors.ts'
import { determineAccessRights } from 'utils/contracts.ts'
import { AccessRightsKeys } from 'types/contracts.ts'

const FAQ_CATEGORIES_B2C = Object.values(FaqSlugB2C)
const FAQ_CATEGORIES_B2B = Object.values(FaqSlugB2B)

const Faq = () => {
  // Redux store
  const { language } = useStoreSelector((store) => store.app)
  const { isProducer, selected, billingContracts } = useStoreSelector((store) => store.contact)
  const { electricity } = selectCurrentContracts({ selected, billingContracts }).serviceContracts

  // Access rights
  const accessRights = determineAccessRights(electricity)

  // Queries
  const { data, isLoading } = useGetFaqByMultipleSlugsQuery({
    slugs: (isProducer ? FAQ_CATEGORIES_B2B : FAQ_CATEGORIES_B2C).filter((slug) =>
      !accessRights[AccessRightsKeys.MARKET_PRICES].canAccess ? slug !== FaqSlugB2C.MARKET_PRICES : slug
    ),
    language
  })

  // i18n
  const { t } = useTranslation()

  // Local state
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [detailModalOpen, setDetailModalOpen] = useState<boolean>(false)
  const [selectedCategory, setSelectedCategory] = useState<FaqCategory | null>(null)

  // Constants
  const filteredCategories = useMemo<FaqCategory[]>(() => {
    const categories = data?.categories || []
    return searchQuery ? categories?.filter((cat) => cat.name.toLowerCase().includes(searchQuery.toLowerCase())) : categories
  }, [data?.categories, searchQuery])

  return (
    <section>
      <SearchBar className={styles.search} onInput={(query) => setSearchQuery(query)} placeholder={t('faq.searchPlaceholder')} />

      {isLoading ? (
        <LoadingSkeleton>
          {[...Array(5)].map((_, i) => (
            <LoadingSkeleton.Rectangle height={50} key={i} />
          ))}
        </LoadingSkeleton>
      ) : (
        <ul className={styles['faq-section']}>
          {filteredCategories.length > 0 ? (
            filteredCategories.map((cat) =>
              cat.questions.length > 0 ? (
                <li
                  key={cat.id}
                  onClick={() => {
                    setSelectedCategory(cat)
                    setDetailModalOpen(true)
                  }}
                >
                  <button>
                    <Heading as="h2" variant="h6" className="my-200">
                      {cat.name}
                    </Heading>

                    <Icon name="chevronRight" />
                  </button>
                </li>
              ) : null
            )
          ) : (
            <li className={styles['no-results-container']}>
              {parse(t('faq.noResults'), {
                replace: (domNode) => {
                  if (domNode instanceof Element && domNode.attribs && domNode.attribs.id === 'icon') {
                    return <Icon name="externalLink" />
                  }

                  return domNode
                }
              })}
            </li>
          )}
        </ul>
      )}

      <footer className={styles['faq-footer']}>
        <small>{t('footer.noAnswer', { ns: 'support' })}</small>
        <Link href={routes.SUPPORT_CONTACT} variant="secondary" size="small">
          {t('contactTab', { ns: 'support' })}
        </Link>
      </footer>

      {selectedCategory && (
        <FaqDrawer
          isOpen={detailModalOpen}
          onRequestClose={() => setDetailModalOpen(false)}
          title={selectedCategory.name}
          questions={selectedCategory.questions}
        />
      )}
    </section>
  )
}

export default Faq
