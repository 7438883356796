import { useTranslation } from 'react-i18next'
import { useStoreSelector } from 'hooks/store.ts'
import { GoStatus } from 'types/contracts.ts'
import { formatAddress } from 'utils/user.ts'
import { AddressFormats } from 'types/format.ts'
import styles from '../GuaranteeOfOrigin.module.scss'
import { Banner, Tag } from '@boltenergy-be/design-system'
import { formatContractStatus, isActiveContract } from 'utils/contracts.ts'
import { useState } from 'react'
import ShowInactiveAddresses from 'pages/App/addresses/components/ShowInactiveAddresses.tsx'
import dayjs from 'dayjs'

const GuaranteeOfOriginStatus = () => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)
  const { billingContracts } = useStoreSelector((store) => store.contact)
  const electricityContracts =
    (!!billingContracts && Object.values(billingContracts).map((billingContract) => billingContract.serviceContracts.electricity)) || []
  const serviceContractsWithGo = electricityContracts.filter((c) => c.detail.goStatus && c.detail.goStatus !== GoStatus.NA)
  const inactiveContracts = serviceContractsWithGo.filter((c) => !isActiveContract(c)).map((c) => c.contractNumber)

  // i18n
  const { t } = useTranslation(['guaranteeOfOrigin', 'addresses'])

  // Local state
  const [showInactive, setShowInactive] = useState<boolean>(inactiveContracts.length === serviceContractsWithGo.length)

  return (
    <>
      {serviceContractsWithGo?.length ? (
        <ul className={styles['go-list']}>
          {serviceContractsWithGo
            .filter((c) => showInactive || isActiveContract(c))
            .map((serviceContract) => {
              const { goStatus } = serviceContract.detail
              const electricityStatus = formatContractStatus(serviceContract)

              return (
                goStatus &&
                goStatus !== GoStatus.NA && (
                  <li key={serviceContract.contractNumber} className={styles.go}>
                    <strong>{formatAddress(serviceContract.deliveryPoint.address, AddressFormats.STREET_NUMBER_BOX)}</strong>
                    <span>{formatAddress(serviceContract.deliveryPoint.address, AddressFormats.POSTAL_CODE_TOWN)}</span>
                    {inactiveContracts.includes(serviceContract.contractNumber) ? (
                      <Tag size="small" color="gray" icon="cross">
                        {t(`addresses:overview.status.${electricityStatus.key}`, {
                          date: dayjs(electricityStatus.date).locale(language).format('D MMMM YYYY')
                        })}
                      </Tag>
                    ) : (
                      <Tag
                        size="small"
                        color={goStatus === GoStatus.CONFIRMED ? 'green' : 'red'}
                        icon={goStatus === GoStatus.CONFIRMED ? 'check' : 'cross'}
                      >
                        {t(`status.myGoStatus.${goStatus}`)}
                      </Tag>
                    )}
                  </li>
                )
              )
            })}
        </ul>
      ) : (
        <Banner title={t('status.noneFound')} />
      )}

      <ShowInactiveAddresses inactiveContractsCount={inactiveContracts.length} {...{ showInactive, setShowInactive }} />
    </>
  )
}

export default GuaranteeOfOriginStatus
