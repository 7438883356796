import OverviewCard from 'pages/App/overview/overview-cards/OverviewCard.tsx'
import { routes } from 'types/routes.ts'
import { useGetProducerByIdQuery } from 'store/queries/bolt-api/producers'
import { ProducerOverviewCardProps } from './types.ts'
import { useTranslation } from 'react-i18next'
import { useStoreSelector } from 'hooks/store.ts'
import { getLowerCaseLanguage } from 'utils/app.ts'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'

const ProducerOverviewCard = ({ producerId }: ProducerOverviewCardProps) => {
  // REDUX STORE
  const { language } = useStoreSelector((store) => store.app)
  const { isProducer } = useStoreSelector((store) => store.contact)
  const lowerCaseLanguage = getLowerCaseLanguage(language)

  // Redux Queries
  const { data, isLoading } = useGetProducerByIdQuery({ producerIdOrSlug: producerId }, { skip: !producerId })

  // i18n
  const { t } = useTranslation('overview')

  // Constants
  const translationKey = isProducer ? 'producer' : 'consumer'

  if (!producerId || (isProducer && !data?.producer?.enabled)) return null

  return isLoading || data?.producer ? (
    <OverviewCard
      isLoading={isLoading}
      button={{
        label: t(`overview.producer.button.${translationKey}`),
        href: routes.PRODUCER
      }}
      image={
        isLoading
          ? { loading: isLoading }
          : data?.producer
            ? {
                src: `${data?.producer?.images?.profile?.url}`,
                alt: `Producer: ${data?.producer?.name?.[lowerCaseLanguage]}`
              }
            : undefined
      }
    >
      {isLoading ? (
        <>
          <LoadingSkeleton.Title />
          <LoadingSkeleton.Paragraph lines={2} />
        </>
      ) : (
        <>
          <OverviewCard.Title>
            {t(`overview.producer.title.${translationKey}`, { producer: data?.producer?.name?.[lowerCaseLanguage] })}
          </OverviewCard.Title>
          <p>{t(`overview.producer.description.${translationKey}`)}</p>
        </>
      )}
    </OverviewCard>
  ) : null
}

export default ProducerOverviewCard
