import ProducerMap from 'components/Map/ProducerMap'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'
import styles from './Producer.module.scss'
import { verifyCoordinates } from 'utils/user'
import { useStoreSelector } from 'hooks/store.ts'
import { selectCurrentAccount, selectCurrentContracts, selectProducerId } from 'store/contact/selectors.ts'
import { useGetParentProducerBySlugQuery, useGetProducerByIdQuery } from 'store/queries/bolt-api/producers'
import { useEffect, useMemo, useState } from 'react'
import { getLowerCaseLanguage } from 'utils/app.ts'
import PageLayout from 'layouts/page-layout/PageLayout.tsx'
import { Button, Icon, Heading, List } from '@boltenergy-be/design-system'
import Link from 'components/Link/Link'
import { Link as RouterLink } from 'react-router-dom'
import useWindowSize from 'hooks/useWindowSize'
import ProducerFooter from './producer-footer/ProducerFooter'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton'
import { FaqSlugB2C } from 'types/faq.ts'
import classNames from 'classnames'
import mixpanel from 'mixpanel-browser'
import { ProducerEvents } from 'types/tracking.ts'
import Carousel from 'components/Carousel/Carousel.tsx'
import { CarouselProps } from 'components/Carousel/types.ts'
import { routes } from 'types/routes.ts'
import SupportOverviewCard from 'pages/App/overview/overview-cards/support/SupportOverviewCard.tsx'

const Producer = () => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)
  const { billingContracts, selected, accounts, isProducer } = useStoreSelector((store) => store.contact)
  const lowerCaseLanguage = getLowerCaseLanguage(language)
  const billingContract = selectCurrentContracts({ billingContracts, selected })
  const selectedAccount = selectCurrentAccount({ selected, accounts })
  const location = billingContract.serviceContracts.electricity.deliveryPoint.address.coordinates
  const producerId = isProducer && selectedAccount.producerId ? selectedAccount.producerId : selectProducerId(billingContract)

  // Redux Queries
  const { data, isLoading } = useGetProducerByIdQuery({ producerIdOrSlug: producerId, full: true })
  const shouldFetchParentProducer = !!data?.producer?.parentSlug && data?.producer?.slug !== data?.producer?.parentSlug
  const { data: parentProducer, isLoading: parentProducerLoading } = useGetParentProducerBySlugQuery(
    { slug: data?.producer?.parentSlug || '', full: true },
    {
      skip: !shouldFetchParentProducer
    }
  )

  // i18n
  const { t } = useTranslation(['producer', 'common'])

  // Window size
  const { isTablet } = useWindowSize()

  // Local state
  const [readingFullDescription, setReadingFullDescription] = useState<boolean>(false)

  // Constants
  const hasConsumerMap =
    !isProducer &&
    !!data?.producer?.coordinates &&
    !!location &&
    verifyCoordinates([data.producer.coordinates?.lat && data.producer.coordinates?.long])
  const hasProducerMap =
    isProducer &&
    (data?.producer?.parentSlug
      ? !!parentProducer?.locations &&
        verifyCoordinates(parentProducer.locations.flatMap((loc) => [loc.coordinates.lat, loc.coordinates.long]))
      : !!data?.producer?.coordinates && verifyCoordinates([data.producer.coordinates?.lat && data.producer.coordinates?.long]))
  const producerContent = shouldFetchParentProducer ? parentProducer || data?.producer : data?.producer
  const showProducerNotOnWebsite = isProducer && !producerContent?.description

  const producerImages = useMemo<CarouselProps['images']>(() => {
    const images: CarouselProps['images'] = []

    if (data?.producer?.images?.profile?.url) {
      images.push({ src: data.producer.images.profile.url, alt: t('alt', { producer: data.producer.name[lowerCaseLanguage] }) })
    }

    if (data?.producer?.images?.header?.url) {
      images.push({ src: data.producer.images.header.url, alt: t('alt', { producer: data.producer.name[lowerCaseLanguage] }) })
    }

    if (data?.producer?.images?.extra) {
      data.producer.images.extra.forEach((img) => {
        if (img.url) images.push({ src: img.url, alt: t('alt', { producer: data?.producer?.name[lowerCaseLanguage] }) })
      })
    }

    return images
  }, [data?.producer?.images, lowerCaseLanguage, t, data?.producer?.name])

  // Track page opened event
  useEffect(() => {
    mixpanel.track(ProducerEvents.PAGE_OPENED)
  }, [])

  return (
    <>
      {isTablet && (
        <section className={styles['cover-image']}>
          {isLoading ? (
            <LoadingSkeleton.Rectangle width="100%" aspectRatio="16/9" className={styles.carousel} />
          ) : (
            !showProducerNotOnWebsite && <Carousel className={styles.carousel} images={producerImages} />
          )}
        </section>
      )}

      <PageLayout
        title={t(`title.${isProducer ? 'producer' : 'consumer'}`)}
        classes={{ body: styles.container }}
        faqCategories={{ b2c: [FaqSlugB2C.MY_PRODUCER] }}
        withSwitcher={!isProducer}
      >
        <div className={styles['left-content']}>
          {isLoading || (shouldFetchParentProducer && parentProducerLoading) ? (
            <LoadingSkeleton>
              <LoadingSkeleton.Title />
              <LoadingSkeleton.Paragraph lines={5} />
            </LoadingSkeleton>
          ) : showProducerNotOnWebsite ? (
            <>
              <section className={styles['no-public-profile']}>
                <Heading as="h1" variant="h4">
                  {t('noPublicProfile.intro.title')}
                </Heading>
                <p>{t('noPublicProfile.intro.paragraphOne')}</p>
                <p>{t('noPublicProfile.intro.paragraphTwo')}</p>
              </section>

              <section className={styles['no-public-profile']}>
                <Heading as="h1" variant="h4">
                  {t('noPublicProfile.includes.title')}
                </Heading>
                <List as="ul">
                  <li>
                    <strong>{t('noPublicProfile.includes.interview.title')}</strong> {t('noPublicProfile.includes.interview.paragraph')}
                  </li>
                  <li>
                    <strong>{t('noPublicProfile.includes.photoShoot.title')}</strong> {t('noPublicProfile.includes.photoShoot.paragraph')}
                  </li>
                  <li>
                    <strong>{t('noPublicProfile.includes.visibility.title')}</strong> {t('noPublicProfile.includes.visibility.paragraph')}
                  </li>
                </List>
              </section>

              <section className={styles['no-public-profile']}>
                <Heading as="h1" variant="h4">
                  {t('noPublicProfile.actions.title')}
                </Heading>
                <p>
                  {t('noPublicProfile.actions.easy')}{' '}
                  <RouterLink to={routes.SUPPORT_CONTACT}>{t('noPublicProfile.actions.contact')}</RouterLink>{' '}
                  {t('noPublicProfile.actions.contactParagraph')}
                </p>
              </section>
            </>
          ) : (
            !!producerContent && (
              <>
                <section className={styles['brief-info']}>
                  <Heading as="h1" variant="h4">
                    {producerContent.name[lowerCaseLanguage]}
                  </Heading>
                  {!producerContent.facts?.[lowerCaseLanguage].every((fact) => !fact) && (
                    <>
                      <strong>{t('inShort')}</strong>
                      <ul className={styles['facts-list']}>
                        {producerContent.facts?.[lowerCaseLanguage].map(
                          (fact, index) =>
                            !!fact && (
                              <li key={index}>
                                <Icon name="check" />
                                {fact}
                              </li>
                            )
                        )}
                      </ul>
                    </>
                  )}
                </section>

                {!!producerContent.longDescription?.[lowerCaseLanguage] && (
                  <section className={styles.description}>
                    <div className={classNames(styles['long-description'], { [styles.open]: readingFullDescription })}>
                      {parse(producerContent.longDescription?.[lowerCaseLanguage]?.replaceAll('style=', 'data-style='))}
                    </div>

                    <Button
                      representation="link"
                      variant="secondary"
                      onClick={() => setReadingFullDescription(!readingFullDescription)}
                      trailingIcon={readingFullDescription ? 'chevronUp' : 'chevronDown'}
                    >
                      {readingFullDescription ? t('readLess', { ns: 'common' }) : t('readMore', { ns: 'common' })}
                    </Button>
                  </section>
                )}

                {!!producerContent.companyDescription?.[lowerCaseLanguage] && (
                  <section className={styles.company}>
                    <Heading as="h2" variant="h4">
                      {t('about')}
                    </Heading>

                    <div className={styles.logo}>
                      <img src={producerContent.images?.logo?.url} alt={producerContent.images?.logo?.name} />
                    </div>

                    <div className={styles['company-content']}>
                      <p>{parse(producerContent.companyDescription?.[lowerCaseLanguage])}</p>

                      {data?.producer?.website && (
                        <Link external href={data.producer.website} variant="secondary" className="mt-200">
                          {t('website')}
                        </Link>
                      )}
                    </div>
                  </section>
                )}

                {(hasProducerMap || hasConsumerMap) && (
                  <section className={styles['gmap-wrapper']}>
                    <Heading as="h2" variant="h4">
                      {t(isProducer ? 'yourLocations' : 'locations')}
                    </Heading>
                    <div className={styles.gmap}>
                      {hasProducerMap ? (
                        parentProducerLoading ? (
                          <LoadingSkeleton>
                            <LoadingSkeleton.Rectangle width="100%" aspectRatio="1/1" />
                          </LoadingSkeleton>
                        ) : (
                          <ProducerMap
                            producerLocations={
                              parentProducer
                                ? parentProducer?.locations.map((childProducer) => ({
                                    lat: childProducer.coordinates.lat,
                                    lng: childProducer.coordinates.long,
                                    energyType: childProducer.energyType
                                  }))
                                : data?.producer
                                  ? [
                                      {
                                        lat: data?.producer?.coordinates.lat,
                                        lng: data?.producer?.coordinates.long,
                                        energyType: data?.producer?.energyType
                                      }
                                    ]
                                  : []
                            }
                          />
                        )
                      ) : data?.producer ? (
                        <ProducerMap
                          producerLocation={{
                            lat: data.producer.coordinates.lat,
                            lng: data.producer.coordinates.long
                          }}
                          userLocation={
                            location && {
                              lat: location.lat,
                              lng: location.long
                            }
                          }
                          producerEnergyType={data.producer.energyType}
                        />
                      ) : null}
                    </div>
                  </section>
                )}
              </>
            )
          )}
        </div>

        <aside className={styles.aside}>
          {isLoading ? (
            <LoadingSkeleton.Rectangle width="100%" aspectRatio="1/1" />
          ) : showProducerNotOnWebsite ? (
            <SupportOverviewCard />
          ) : (
            <>
              {!isTablet && <Carousel className={styles.carousel} images={producerImages} />}
              {isProducer && <ProducerFooter />}
            </>
          )}
        </aside>
      </PageLayout>
    </>
  )
}

export default Producer
