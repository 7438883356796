import { UserTypes } from 'store/auth/types'
import { AuthenticatedUserTypes, AuthTokens, Token } from 'types/auth'
import { clearSessionUserType, getUserType, identifyUserType, setUserType } from 'utils/userType'
import dayjs from 'dayjs'

/**
 * Clears the tokens in localStorage
 * @param {UserTypes | null} userType
 */
const clearTokens = (userType: UserTypes | null) => {
  localStorage.removeItem('is_superuser')
  if (userType) {
    setIsAuthenticated(false, userType)
    localStorage.removeItem(`access_token_${userType}`)
    localStorage.removeItem(`refresh_token_${userType}`)
  }
  clearSessionUserType()
}

/**
 * Checks if the tokens are still valid for a user type
 * @param {UserTypes} userType
 * @returns {boolean}
 */
const checkTokenIsValid = (userType: UserTypes): boolean => {
  // Raw tokens
  const accessTokenJSON = localStorage.getItem(`access_token_${userType}`)
  const refreshTokenJSON = localStorage.getItem(`refresh_token_${userType}`)

  // No token stored
  if (!accessTokenJSON && !refreshTokenJSON) return false

  // Parsed tokens
  const accessToken: Token | undefined = accessTokenJSON ? JSON.parse(accessTokenJSON) : undefined
  const refreshToken: Token | undefined = refreshTokenJSON ? JSON.parse(refreshTokenJSON) : undefined

  // No parsed token stored
  if (!accessToken && !refreshToken) return false

  if (accessToken?.expiresAt) {
    const isValidAccessToken = (accessToken.expiresAt || 0) > dayjs().unix()

    // Check refresh token if access token is invalid
    if (!isValidAccessToken && refreshToken?.expiresAt) {
      return (refreshToken.expiresAt || 0) > dayjs().unix()
    }

    // Token is not valid
    return isValidAccessToken
  }

  // Handle as "expired" due to expiresAt not being stored yet.
  return false
}

/**
 * Returns the authenticated user types
 * @returns {AuthenticatedUserTypes}
 */
const isAuthenticated = (): AuthenticatedUserTypes => {
  if (localStorage) {
    const localIsAuth = localStorage.getItem('authenticated_user_types')
    const parsedLocalIsAuth: UserTypes[] = localIsAuth ? JSON.parse(localIsAuth) : []

    // Filter out the user types that don't have a token
    const authenticatedUserTypes = Object.values(UserTypes).filter((userType) => checkTokenIsValid(userType))

    // Return an object with the authenticated user types
    return authenticatedUserTypes.reduce(
      (prev: AuthenticatedUserTypes, curr) => {
        return {
          ...prev,
          [curr]: parsedLocalIsAuth.includes(curr)
        }
      },
      {
        [UserTypes.SALES]: false,
        [UserTypes.CUSTOMER]: false,
        [UserTypes.SUPER_USER]: false
      }
    )
  }

  return {
    [UserTypes.SALES]: false,
    [UserTypes.CUSTOMER]: false,
    [UserTypes.SUPER_USER]: false
  }
}

// SETTERS

/**
 * Sets the is_authenticated in localStorage
 *
 * @param {boolean} isAuthenticated
 * @param {UserTypes} userType
 */
const setIsAuthenticated = (isAuthenticated: boolean, userType: UserTypes) => {
  const localAuthenticatedUsers = localStorage.getItem('authenticated_user_types')
  let parsedLocalAuthenticatedUsers: UserTypes[] = localAuthenticatedUsers ? JSON.parse(localAuthenticatedUsers) : []

  if (isAuthenticated) {
    if (!parsedLocalAuthenticatedUsers.includes(userType)) parsedLocalAuthenticatedUsers.push(userType)
  } else {
    parsedLocalAuthenticatedUsers = parsedLocalAuthenticatedUsers.filter((type) => type !== userType)
  }

  localStorage.setItem('authenticated_user_types', JSON.stringify(parsedLocalAuthenticatedUsers))
}

/**
 * Sets the is_superuser in localStorage
 *
 * @param {boolean} isSuperUser
 */
const setIsSuperUser = (isSuperUser: boolean) => {
  localStorage.setItem('is_superuser', JSON.stringify(isSuperUser))
}

/**
 * Sets the tokens in localStorage
 *
 * @param {AuthTokens} tokens
 * @param {UserTypes} userType
 */
const setTokens = (tokens: AuthTokens, userType: UserTypes) => {
  localStorage.setItem(`access_token_${userType}`, JSON.stringify(tokens.token))
  localStorage.setItem(`refresh_token_${userType}`, JSON.stringify(tokens.refreshToken))
}

// GETTERS

/**
 * Returns the access token from localStorage
 *
 * @param {UserTypes} customUserType
 * @returns {Token}
 */
const getAccessToken = (customUserType?: UserTypes): Token | undefined => {
  const userType = customUserType || getUserType()

  if (!userType) return undefined

  const localStorageAccessToken = localStorage.getItem(`access_token_${userType}`)

  return localStorageAccessToken ? JSON.parse(localStorageAccessToken) : undefined
}

/**
 * Returns the access token from localStorage
 *
 * @param {string} token
 * @returns {Token}
 */
const getAccessTokenFromSession = (token: string): Token => {
  const sessionStorageAccessToken = sessionStorage.getItem(token)

  return sessionStorageAccessToken ? JSON.parse(sessionStorageAccessToken) : undefined
}

/**
 * Returns the refresh token from localStorage
 *
 * @param {UserTypes} customUserType
 * @returns {Token}
 */
const getRefreshToken = (customUserType?: UserTypes): Token | undefined => {
  let userType = customUserType || getUserType()
  const isAuthenticatedForUserType = isAuthenticated()

  if (!userType) {
    const hasAuthenticatedUserType = Object.values(isAuthenticatedForUserType).some((isAuthenticated) => isAuthenticated)

    if (!hasAuthenticatedUserType) return undefined

    userType = identifyUserType()
    setUserType(userType)
  }

  const localStorageRefreshToken = localStorage.getItem(`refresh_token_${userType}`)

  return localStorageRefreshToken ? JSON.parse(localStorageRefreshToken) : undefined
}

export {
  clearTokens,
  isAuthenticated,

  // SETTERS
  setIsAuthenticated,
  setIsSuperUser,
  setTokens,

  // GETTERS
  getAccessToken,
  getAccessTokenFromSession,
  getRefreshToken
}
