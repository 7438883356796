import { ReactNode } from 'react'
import { TableHeader } from 'pages/App/billing/invoices/invoices-table/types.ts'
import { InvoiceCells } from 'pages/App/billing/invoices/table-data/types.ts'
import { EventLogCells } from 'pages/App/event-log/types.ts'

export enum SortingDirection {
  ASC = 'asc',
  DESC = 'desc'
}

type OnSortProps = {
  key: AvailableTableCells
  direction: SortingDirection
}

type TableSorting = {
  onSort?: ({ key, direction }: OnSortProps) => void
  sorting?: OnSortProps
}

export type TableProps<Cells extends AvailableTableCells> = {
  data: TableDataRow<Cells>[]
  className?: string
  showHeader?: boolean
  handleClickRow?: (index: number) => void
} & TableSorting

export type TableHeaderProps = {
  header: TableHeader
  cellKey: AvailableTableCells
  hidden?: boolean
} & TableSorting

export type AvailableTableCells = InvoiceCells | EventLogCells

export type TableEntryData = {
  header: string
  data: any // TODO: temporary fix for typescript error in InvoiceEntryModal (was data: unknown)
  content: string | ReactNode
  hidden?: boolean
  hideOnSmallDesktop?: boolean
  extraClasses?: string
  extraContent?: AvailableTableCells
}

export type TableDataRow<Cells extends AvailableTableCells> = {
  id: string
  uuid: string
  entries: {
    [key in Cells]?: TableEntryData
  }
}
