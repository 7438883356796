import { useTranslation } from 'react-i18next'
import styles from './ContractDetailView.module.scss'
import { ContractDetailViewProps } from './types'
import { DATE_FORMAT } from 'constants/constants'
import dayjs from 'dayjs'
import Link from 'components/Link/Link'
import { formatAddress } from 'utils/user'
import parse from 'html-react-parser'
import { useStoreSelector } from 'hooks/store.ts'
import { ZAPIER_RENEWAL_WEBHOOK } from 'pages/App/addresses/offtake-address/constants.ts'
import { useState } from 'react'
import { log } from 'utils/logging.ts'
import { Flow } from 'types/logging.ts'
import { Button, InlineMessage } from '@boltenergy-be/design-system'
import { UrlSearchParamsKeys } from 'store/app/types.ts'
import mixpanel from 'mixpanel-browser'
import { AddressEvents } from 'types/tracking.ts'

const ContractDetailView = ({ serviceContract }: ContractDetailViewProps) => {
  // Redux store
  const { urlSearchParams } = useStoreSelector((store) => store.app)

  // i18n
  const { t } = useTranslation('addresses')

  // Constants
  const formattedAddress = formatAddress(serviceContract?.deliveryPoint?.address)

  // Local state
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false)
  const [isErrored, setIsErrored] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  /**
   * Confirms the fixed tariff renewal
   */
  const confirmFixedTariffRenewal = async () => {
    setIsLoading(true)

    try {
      const formData = new FormData()
      formData.append('contractNumber', serviceContract.contractNumber)
      formData.append('confirmationDate', dayjs().format())

      await fetch(ZAPIER_RENEWAL_WEBHOOK, {
        method: 'POST',
        mode: 'no-cors',
        body: formData
      })

      mixpanel.track(AddressEvents.EXTEND_FIXED_TARIFF, { type: serviceContract.detail.energyType })

      setIsConfirmed(true)
    } catch (error) {
      setIsErrored(true)

      log({ error: error as Error, identifier: `[${Flow.CONTRACT}:confirmFixedTariffRenewal]` })
    }

    setIsLoading(false)
  }

  return (
    <table className={styles['product-table']}>
      <tbody>
        {/* STATUS */}
        <tr>
          <th>{t('myContract.status')}</th>
          <td>{t(`myContract.contractStatus.${serviceContract?.detail.status}`)}</td>
        </tr>

        {/* ADDRESS */}
        {formattedAddress && (
          <tr>
            <th>{t('myContract.address')}</th>
            <td>{parse(formattedAddress)}</td>
          </tr>
        )}

        {/* EAN */}
        <tr>
          <th>{t('myContract.ean')}</th>
          <td>{serviceContract.deliveryPoint.ean}</td>
        </tr>

        {/* METER NUMBER */}
        {serviceContract.deliveryPoint.meterNumber && (
          <tr>
            <th>{t('myContract.meterNumber')}</th>
            <td>{serviceContract.deliveryPoint.meterNumber}</td>
          </tr>
        )}

        {/* START DATE */}
        <tr>
          <th>{t('myContract.startDate')}</th>
          <td>{dayjs(serviceContract.detail.contractualStartDate).format(DATE_FORMAT)}</td>
        </tr>

        {/* CURRENT TARIFF CARD */}
        <tr>
          <th>{t('myContract.tariff.current')}</th>
          <td>
            <Link
              variant="secondary"
              href={`https://${serviceContract.detail.priceListUrl}`}
              download
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => mixpanel.track(AddressEvents.DOWNLOAD_TARIFF_CARD, { type: serviceContract.detail.energyType })}
            >
              {t('myContract.tariff.download')}
            </Link>
          </td>
        </tr>

        {/* RENEWAL TARIFF CARD */}
        {'renewalPriceList' in serviceContract.detail && serviceContract.detail.renewalPriceList && (
          <tr>
            <th>{`${t('myContract.tariff.renewal')} ${dayjs(serviceContract.detail.renewalPriceList.fromDate).format(DATE_FORMAT)}`}</th>
            <td className={styles.downloadpdf}>
              <a
                href={`https://${serviceContract.detail.renewalPriceList.url}`}
                download={true}
                onClick={() => mixpanel.track(AddressEvents.VIEW_RENEWAL, { type: serviceContract.detail.energyType })}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('myContract.tariff.download')}
              </a>
              {urlSearchParams[UrlSearchParamsKeys.FIXED_PRICE_RENEWAL_CONFIRMATION] === 'true' && (
                <>
                  {!isConfirmed && !isErrored && (
                    <Button representation="link" onClick={confirmFixedTariffRenewal} loading={isLoading}>
                      {t('confirm', { ns: 'common' })}
                    </Button>
                  )}
                  {isConfirmed && <InlineMessage type="positive">{t('myContract.tariff.confirmed')}</InlineMessage>}
                  {isErrored && <InlineMessage type="negative">{t('error', { ns: 'common' })}</InlineMessage>}
                </>
              )}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default ContractDetailView
