import styles from './ConsumptionNavigation.module.scss'
import { EnergyType, Granularity } from 'types/contracts.ts'
import {
  ConsumptionNavigationForm,
  ConsumptionNavigationProps,
  DatePickerConfig,
  NavigationItemType
} from 'components/Charts/consumption-navigation/types.ts'
import { getCycleIndex, getGranularityPeriodIndex } from 'components/Charts/consumption-navigation/utils.ts'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useStoreSelector } from 'hooks/store.ts'
import { registerLocale } from 'react-datepicker'
import { Language } from 'store/app/types.ts'
import { nl } from 'date-fns/locale/nl'
import { fr } from 'date-fns/locale/fr'
import { Button, DatePicker, Select } from '@boltenergy-be/design-system'
import { selectCurrentContracts } from 'store/contact/selectors.ts'
import { getLowerCaseLanguage } from 'utils/app.ts'
import useWindowSize from 'hooks/useWindowSize.tsx'
import AddressSwitcher from 'components/AddressSwitcher/AdressSwitcher.tsx'
import { SMALL_DESKTOP_BREAKPOINT } from 'constants/viewport.ts'
import { CONSUMPTION_VIEW_DATE_PICKER_CONFIG } from 'components/Charts/consumption-navigation/constants.ts'

const ConsumptionNavigation = ({ billingCycles, granularities, disabledFilters, energyType }: ConsumptionNavigationProps) => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)
  const contactStore = useStoreSelector((store) => store.contact)

  // Locale for React Datepicker
  registerLocale(language, language === Language.NL ? nl : fr)

  // Contracts
  const { electricity } = selectCurrentContracts(contactStore).serviceContracts

  // i18n
  const { t } = useTranslation('consumption')

  // Window size
  const { isTablet, isSmaller } = useWindowSize(SMALL_DESKTOP_BREAKPOINT)

  // React hook form
  const { control, register, watch, setValue } = useFormContext<ConsumptionNavigationForm>()
  const watchView = watch('view')
  const watchPeriod = watch('period')

  /**
   * Handle the change of the view
   * @param {Granularity | NavigationItemType.CYCLE} newView
   */
  const handleChangeView = (newView: Granularity | NavigationItemType.CYCLE) => {
    setValue('view', newView)

    if (newView === NavigationItemType.CYCLE) {
      setValue('period', Object.keys(billingCycles).sort((a, b) => Number(b) - Number(a))?.[0])
    } else {
      const defaultPeriod = granularities[newView]?.periods?.find((period) => period.defaultSelected)
      const newPeriod = defaultPeriod ? defaultPeriod?.value : granularities[newView]?.periods?.[0]?.value
      setValue('period', newPeriod ?? '')
    }
  }

  return (
    <form className={styles.form}>
      {/* CONSUMPTION VIEW (granularity/billing cycles) */}
      {((energyType === EnergyType.ELECTRICITY && electricity.detail.dynamicTariff) || !!Object.keys(billingCycles).length) && (
        <Select
          disabled={disabledFilters}
          label=""
          {...register('view')}
          layoutClassName={styles['granularity-layout']}
          className={styles.granularity}
          onChange={(event) => handleChangeView(event.target.value as Granularity | NavigationItemType.CYCLE)}
        >
          {billingCycles && !!Object.keys(billingCycles).length && (
            <option value={NavigationItemType.CYCLE}>{t('chart.header.pagination.billingCycle')}</option>
          )}
          {Object.values(Granularity)
            .filter((g) => !!granularities[g])
            .map((g) => (
              <option key={g} value={g}>
                {granularities[g]!.translationKey
                  ? t(`chart.header.pagination.${granularities[g]!.translationKey}`)
                  : granularities[g]!.text}
              </option>
            ))}
        </Select>
      )}

      {/* SELECTED PERIOD NAVIGATION */}
      <div className={styles.navigation}>
        <Button
          className={styles.previous}
          type="button"
          variant={isTablet ? 'secondary' : 'layered'}
          size="small"
          leadingIcon="chevronLeft"
          disabled={
            disabledFilters ||
            (watchView === NavigationItemType.CYCLE
              ? getCycleIndex(billingCycles, watchPeriod).previous === null
              : getGranularityPeriodIndex(granularities[watchView]?.periods || [], watchPeriod).previous === null)
          }
          onClick={() => {
            if (watchView === NavigationItemType.CYCLE) {
              const previousCycleIndex = getCycleIndex(billingCycles, watchPeriod).previous
              if (typeof previousCycleIndex === 'number') {
                const previous = Object.values(billingCycles)[previousCycleIndex]
                setValue('period', previous.id)
              }
            } else {
              const previousGranularityIndex = getGranularityPeriodIndex(granularities[watchView]?.periods || [], watchPeriod).previous
              if (typeof previousGranularityIndex === 'number') {
                const previous = granularities[watchView]?.periods?.[previousGranularityIndex]
                setValue('period', previous?.value || '')
              }
            }
          }}
        />

        {watchView === NavigationItemType.CYCLE ? (
          <Select disabled={disabledFilters} label="" {...register('period')} className={styles.period} value={watchPeriod}>
            {Object.keys(billingCycles)
              .sort((a, b) => Number(b) - Number(a))
              .map((key) => (
                <option key={key} value={billingCycles[key].id}>
                  {billingCycles[key].text}
                </option>
              ))}
          </Select>
        ) : (
          <Controller
            name="period"
            {...{ control }}
            render={({ field: { value } }) => {
              const { valueFormat, endOf, viewFormat }: DatePickerConfig = CONSUMPTION_VIEW_DATE_PICKER_CONFIG[watchView]

              return (
                <DatePicker
                  disabled={disabledFilters}
                  name="period"
                  enableTabLoop={false}
                  language={getLowerCaseLanguage(language)}
                  dateFormat={viewFormat}
                  showYearPicker={watchView === Granularity.MONTH}
                  showMonthYearPicker={watchView === Granularity.DAY}
                  selected={dayjs(value).toDate()}
                  onChange={(date) => {
                    const periodToSelect = granularities[watchView]?.periods?.find(
                      (p) =>
                        dayjs(p.value).format(valueFormat) ===
                        dayjs(date as string)
                          .endOf(endOf)
                          .format(valueFormat)
                    )?.value
                    const defaultPeriod = granularities[watchView]?.periods?.[0]?.value

                    setValue('period', periodToSelect || defaultPeriod || '')
                  }}
                  maxDate={dayjs(granularities[watchView]?.periods?.[0]?.value).toDate()}
                  minDate={dayjs(granularities[watchView]?.periods?.[(granularities[watchView]?.periods?.length || 1) - 1]?.value).toDate()}
                  calendarStartDay={1}
                  layoutClassName={styles['datepicker-layout']}
                  popperClassName={styles['date-picker-popup']}
                  showPopperArrow={false}
                />
              )
            }}
          />
        )}

        <Button
          className={styles.next}
          type="button"
          variant={isTablet ? 'secondary' : 'layered'}
          size="small"
          leadingIcon="chevronRight"
          disabled={
            disabledFilters ||
            (watchView === NavigationItemType.CYCLE
              ? getCycleIndex(billingCycles, watchPeriod).next === null
              : getGranularityPeriodIndex(granularities[watchView]?.periods || [], watchPeriod).next === null)
          }
          onClick={() => {
            if (watchView === NavigationItemType.CYCLE) {
              const nextCycleIndex = getCycleIndex(billingCycles, watchPeriod).next
              if (typeof nextCycleIndex === 'number') {
                const next = Object.values(billingCycles)[nextCycleIndex]
                setValue('period', next.id)
              }
            } else {
              const nextGranularityIndex = getGranularityPeriodIndex(granularities[watchView]?.periods || [], watchPeriod).next
              if (typeof nextGranularityIndex === 'number') {
                const next = granularities[watchView]?.periods?.[nextGranularityIndex]
                setValue('period', next?.value || '')
              }
            }
          }}
        />
      </div>

      {!isSmaller && <AddressSwitcher containerClassName={styles['switcher-container']} className={styles.switcher} />}
    </form>
  )
}

export default ConsumptionNavigation
