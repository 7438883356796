import { Coordinates } from './producer'

export type Address = {
  postalCode: number | string
  townName: string
  streetName: string
  streetNumber: string
  streetBox: string
  coordinates?: Coordinates
}

export type AddressWithCountryAndTownCode = Address & {
  countryCode: 'BE'
  townCode: number
}

export type Company = {
  name: string
  enterpriseNumber: string
  legalForm?: LegalForm
  vatApplication: boolean
}

// Enums

export enum LegalForm {
  ACP_VME = 'ACP - VME',
  ASBL_VZW = 'ASBL - VZW',
  INDIVIDUAL = 'Entreprise Individuelle - Eenmanszaak',
  SA_NV = 'SA - NV',
  SCOMM_COMMV = 'SComm - CommV',
  SCRL_CVBA = 'SCRL - CVBA',
  SCS_GCV = 'SCS - GCV',
  SNC_VOF = 'SNC - VOF',
  SPRLU_EBVBA = 'SPRLU - EBVBA',
  SPRL_BVBA = 'SPRL - BVBA',
  SRL_BV = 'SRL - BV'
}
