import parse from 'html-react-parser'
import { Icon } from '@boltenergy-be/design-system'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { ProductType, TimeframeCode } from 'types/types'
import { Direction, MeterReadingSource } from 'types/contracts'
import dayjs from 'dayjs'
import styles from './MeterReadings.module.scss'
import resources from 'translations/dutch'
import { useStoreSelector } from 'hooks/store'
import Card from 'components/Card/Card.tsx'
import { Heading } from '@boltenergy-be/design-system'
import EmptyState from 'components/ReturnLater/EmptyState.tsx'
import { determineAccessRights, isActiveContract } from 'utils/contracts'
import { Navigate } from 'react-router'
import { routes } from 'types/routes.ts'
import { selectCurrentContracts } from 'store/contact/selectors.ts'
import useWindowSize from 'hooks/useWindowSize.tsx'
import { CardColors } from 'components/Card/types.ts'
import Link from 'components/Link/Link.tsx'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import mixpanel from 'mixpanel-browser'
import { CommonTrackingEvents, ConsumptionEvents } from 'types/tracking.ts'
import AddressSwitcherBar from 'components/AddressSwitcherBar/AddressSwitcherBar.tsx'
import { SMALL_DESKTOP_BREAKPOINT } from 'constants/viewport.ts'

const MeterReadings = () => {
  // REDUX STORE
  const {
    meterReadings: { data, loading }
  } = useStoreSelector((store) => store.contracts)
  const { billingContracts, selected } = useStoreSelector((store) => store.contact)

  // Window size
  const { isSmaller } = useWindowSize(SMALL_DESKTOP_BREAKPOINT)

  // Contracts
  const { electricity, gas } = selectCurrentContracts({ billingContracts, selected }).serviceContracts

  // Access rights
  const accessRights = determineAccessRights(electricity)

  // i18n
  const { t } = useTranslation(['consumption', 'common'])

  /**
   * Returns the correct meter reading entry based on the given product type & timeframe code
   *
   * @param {ProductType} type
   * @param {Direction} direction
   * @param {TimeframeCode} timeframeCode
   * @returns {number|undefined}
   */
  const getMeterReadingEntry = (type: ProductType, direction: Direction, timeframeCode: TimeframeCode): number | undefined => {
    if (type === ProductType.GAS) return data?.gas?.consumption?.singleRate

    switch (timeframeCode) {
      case TimeframeCode.HIGH:
        return data?.electricity?.[direction === Direction.CONSUMPTION ? 'consumption' : 'injection']?.doubleRate?.day

      case TimeframeCode.LOW:
        return data?.electricity?.[direction === Direction.CONSUMPTION ? 'consumption' : 'injection']?.doubleRate?.night

      case TimeframeCode.NIGHT_EXCLUSIVE:
        return data?.electricity?.[direction === Direction.CONSUMPTION ? 'consumption' : 'injection']?.exclNight

      default:
        return data?.electricity?.[direction === Direction.CONSUMPTION ? 'consumption' : 'injection']?.singleRate
    }
  }

  /**
   * Returns the meter reading row for the given type, direction & timeframe code
   *
   * @param {ProductType} type
   * @param {Direction} direction
   * @param {TimeframeCode} timeframeCode
   * @returns {ReactElement}
   */
  const getMeterReadingRow = (type: ProductType, direction: Direction, timeframeCode: TimeframeCode): ReactElement => {
    const isGas = type === ProductType.GAS

    return (
      <li className={styles['meter-reading-row']}>
        <strong className={styles.type}>
          {t(`${!isGas && direction === Direction.PRODUCTION ? 'injection' : type}`, { ns: 'common' })}
          &nbsp;
          {!isGas &&
            timeframeCode !== TimeframeCode.TOTAL_HOUR &&
            t(`indexes.${timeframeCode as keyof typeof resources.common.indexes}`, { ns: 'common' })}
        </strong>

        <small className={styles.ean}>
          {t('common:units.textWithColon', { text: t('common:ean') })} {data?.[type]?.ean}
        </small>

        <data className={styles['meter-reading']} value={getMeterReadingEntry(type, direction, timeframeCode)?.toFixed(0)}>
          {getMeterReadingEntry(type, direction, timeframeCode)?.toFixed(0)}
          &nbsp;{isGas ? parse('m<sup>3</sup>') : 'kWh'}
        </data>
      </li>
    )
  }

  return !accessRights.meterReadings.canAccess ? (
    <Navigate to={routes.CONSUMPTION} replace />
  ) : !accessRights.meterReadings.showContent ? (
    <EmptyState description={t('meterReadings.returnLater')} />
  ) : (
    <>
      {isSmaller ? (
        <Heading as="h1" variant="h5" className={styles.title}>
          {t('meterReadings.title')}
        </Heading>
      ) : (
        <AddressSwitcherBar className="mb-500">
          <Heading as="h1" variant="h5">
            {t('meterReadings.title')}
          </Heading>
        </AddressSwitcherBar>
      )}

      {!loading ? (
        <div className={styles['cards-wrapper']}>
          <Card as="section" className={styles['last-meter-readings']}>
            <header className={styles['title-section']}>
              <Heading as="h1" variant="h5">
                {t('electricity', { ns: 'common' })}
              </Heading>
              <Icon name="electricity" className={styles.el} />
              <small>
                {parse(
                  t(
                    `meterReadings.lastMeterReadings.${
                      data?.electricity?.source === MeterReadingSource.CLIENT_PROVIDED ? 'self' : 'networkOperator'
                    }`,
                    {
                      date: dayjs(data?.electricity?.date).format('DD/MM/YYYY')
                    }
                  )
                )}
              </small>
            </header>

            <ul className={styles['table-section']}>
              {/* CONSUMPTION ELECTRICITY INPUTS */}
              {typeof data?.electricity?.consumption.singleRate !== 'undefined' &&
                getMeterReadingRow(ProductType.ELECTRICITY, Direction.CONSUMPTION, TimeframeCode.TOTAL_HOUR)}
              {typeof data?.electricity?.consumption.doubleRate?.day !== 'undefined' &&
                getMeterReadingRow(ProductType.ELECTRICITY, Direction.CONSUMPTION, TimeframeCode.HIGH)}
              {typeof data?.electricity?.consumption.doubleRate?.night !== 'undefined' &&
                getMeterReadingRow(ProductType.ELECTRICITY, Direction.CONSUMPTION, TimeframeCode.LOW)}
              {typeof data?.electricity?.consumption.exclNight !== 'undefined' &&
                getMeterReadingRow(ProductType.ELECTRICITY, Direction.CONSUMPTION, TimeframeCode.NIGHT_EXCLUSIVE)}

              {/* INJECTION ELECTRICITY INPUTS */}
              {typeof data?.electricity?.injection?.singleRate !== 'undefined' &&
                getMeterReadingRow(ProductType.ELECTRICITY, Direction.PRODUCTION, TimeframeCode.TOTAL_HOUR)}
              {typeof data?.electricity?.injection?.doubleRate?.day !== 'undefined' &&
                getMeterReadingRow(ProductType.ELECTRICITY, Direction.PRODUCTION, TimeframeCode.HIGH)}
              {typeof data?.electricity?.injection?.doubleRate?.night !== 'undefined' &&
                getMeterReadingRow(ProductType.ELECTRICITY, Direction.PRODUCTION, TimeframeCode.LOW)}
            </ul>
          </Card>

          {data?.gas && (
            <Card as="section" className={styles['last-meter-readings']}>
              <header className={styles['title-section']}>
                <Heading as="h1" variant="h5">
                  {t('gas', { ns: 'common' })}
                </Heading>
                <Icon name="gas" className={styles.ng} />
                <small>
                  {parse(
                    t(
                      `meterReadings.lastMeterReadings.${
                        data.gas.source === MeterReadingSource.CLIENT_PROVIDED ? 'self' : 'networkOperator'
                      }`,
                      {
                        date: dayjs(data.gas.date).format('DD/MM/YYYY')
                      }
                    )
                  )}
                </small>
              </header>

              <ul className={styles['table-section']}>
                {/* CONSUMPTION GAS INPUTS */}
                {typeof data.gas?.consumption.singleRate !== 'undefined' &&
                  getMeterReadingRow(ProductType.GAS, Direction.CONSUMPTION, TimeframeCode.TOTAL_HOUR)}
              </ul>
            </Card>
          )}

          <Link
            className="grid-col-full"
            representation="button"
            size="large"
            href={routes.CONSUMPTION_EDIT_METER_READINGS}
            onClick={() => mixpanel.track(ConsumptionEvents.METER_READING_DETAILS_OPENED, { route: routes.CONSUMPTION_METER_READINGS })}
          >
            {t('meterReadings.button')}
          </Link>

          <Card as="section" color={CardColors.PINK} className="grid-col-full">
            <Card.Title as="h1">{t('common:moveBanner.title')}</Card.Title>
            <p>{parse(t('common:moveBanner.description'))}</p>
            <Link variant="secondary" href={routes.MOVE} onClick={() => mixpanel.track(CommonTrackingEvents.CLICK_MOVE_BANNER)}>
              {t('common:moveBanner.linkText')}
            </Link>
          </Card>
        </div>
      ) : (
        <LoadingSkeleton className={styles['cards-wrapper']}>
          <LoadingSkeleton.Rectangle height={360} />
          {gas && isActiveContract(gas) && <LoadingSkeleton.Rectangle height={360} />}
        </LoadingSkeleton>
      )}
    </>
  )
}

export default MeterReadings
