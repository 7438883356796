import { IndexesFormType } from 'components/IndexesFormInputs/types'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import {
  AccessRights,
  CheckHasMeterAndReadingInputs,
  ContractDataWithIndexes,
  ContractStatus,
  Direction,
  FormatContractStatus,
  MeterIndexType,
  PhysicalMeterType,
  ServiceContractElectricity,
  ServiceContractGas,
  UnknownServiceContract
} from 'types/contracts'
import { SimplifiedContractStatus, TimeframeCode } from 'types/types'
import { CounterTypes, Situation } from 'features/contracts/add/types.ts'
import { getMeterReadings } from 'store/contracts/thunks'
import { store } from 'store/index.ts'
import { ELProduct, NGProduct } from 'types/products.ts'
import { BillingContract } from 'types/contracts.ts'
import { NormalizedEntities } from 'store/contact/types.ts'

dayjs.extend(isBetween)

/**
 * Determines access rights based on the given contract
 *
 * @param {ServiceContractElectricity} contract
 * @returns {AccessRights}
 */
export const determineAccessRights = (contract: ServiceContractElectricity): AccessRights => {
  if (!contract) {
    return {
      billingCycles: {
        canAccess: false,
        showContent: false
      },
      consumption: {
        showContent: false
      },
      instalment: {
        canAccess: false,
        showContent: false
      },
      meterReadings: {
        canAccess: false,
        showContent: false
      },
      marketPrices: {
        canAccess: false
      }
    }
  }

  const activeContract = isActiveContract(contract)
  const atLeastAMonthOld = contractIsAtLeastAMonthOld(contract)
  const isBoltGo = [ELProduct.Go, NGProduct.Go].includes(contract.detail?.productCode)
  const { isProducer } = store.getState().contact

  return {
    billingCycles: {
      canAccess: !isProducer && !isBoltGo && !contract.detail.monthlyBilling,
      showContent: activeContract && atLeastAMonthOld
    },
    consumption: {
      showContent: (activeContract && atLeastAMonthOld) || contract.detail.status === ContractStatus.TERMINATED
    },
    instalment: {
      canAccess: !isProducer && !isBoltGo && !contract.detail.monthlyBilling,
      showContent: activeContract
    },
    meterReadings: {
      canAccess: !isProducer && !contract.detail.dynamicTariff,
      showContent: activeContract && atLeastAMonthOld
    },
    marketPrices: {
      canAccess: !!contract.detail.dynamicTariff
    }
  }
}

/**
 * Checks if the given contract is at least a month old (both start & subscription date)
 *
 * @param {UnknownServiceContract} contract
 * @returns {boolean}
 */
export const contractIsAtLeastAMonthOld = (contract: UnknownServiceContract): boolean => {
  if (!contract) {
    return false
  }

  return (
    dayjs().diff(dayjs(contract.detail.contractualStartDate), 'month') >= 1 &&
    dayjs().diff(dayjs(contract.detail.subscriptionDate), 'month') >= 1
  )
}

/**
 * Checks if the meter and number inputs are visible
 * @param {CheckHasMeterAndReadingInputs} params
 * @returns {boolean}
 */
export const checkHasMeterAndNumberInputs = ({ situation, counterType, contractStartDate }: CheckHasMeterAndReadingInputs): boolean => {
  return situation === Situation.MOVE && counterType === CounterTypes.ANALOG && !dayjs(contractStartDate).isAfter(dayjs())
}

/**
 * Helper function that checks if the contract has a digital meter or not
 * @param {UnknownServiceContract} contract
 * @returns {boolean}
 */
export const hasDigitalMeter = (contract: UnknownServiceContract): boolean => {
  return (
    !!contract.deliveryPoint.meterType &&
    [PhysicalMeterType.SMART_METER, PhysicalMeterType.ELECTRONIC_METER].includes(contract.deliveryPoint.meterType)
  )
}

/**
 * Helper function that checks if the contract has a digital meter or not
 * @param {UnknownServiceContract} contract
 * @returns {boolean}
 */
export const hasSmartMeter = (contract: UnknownServiceContract): boolean => {
  return contract.deliveryPoint.meterType === PhysicalMeterType.SMART_METER
}

/**
 * Generates the contracts data with indexes based on the given parameters
 *
 * @param {IndexesFormType} indexes
 * @param {ServiceContractElectricity} electricityContract
 * @param {ServiceContractGas=} gasContract
 * @param {boolean=} isInFuture
 * @returns {ContractDataWithIndexes[]}
 */
export const generateContractsDataWithIndexes = (
  indexes: IndexesFormType,
  electricityContract: ServiceContractElectricity,
  gasContract?: ServiceContractGas,
  isInFuture?: boolean
): ContractDataWithIndexes[] => {
  const contractsDataWithIndexes: ContractDataWithIndexes[] = []

  if (!hasDigitalMeter(electricityContract) && electricityContract?.deliveryPoint?.previousIndex) {
    // Generate electricity indexes
    if (indexes.electricity) {
      contractsDataWithIndexes.push({
        contractNumber: electricityContract.contractNumber,
        ean: electricityContract.deliveryPoint.ean,
        indexes: isInFuture
          ? undefined
          : electricityContract.deliveryPoint.previousIndex?.registers
              .filter((register) => register.timeframeCode !== TimeframeCode.NOT_USED)
              .map((register, i) => ({
                meterNumber: electricityContract.deliveryPoint.previousIndex!.meterNumber,
                registerName: register.registerName,
                timeframeCode: register.timeframeCode,
                value: indexes.electricity![i],
                direction: register.direction
              }))
      })
    }

    // Generate gas indexes if necessary
    if (gasContract && indexes.gas) {
      contractsDataWithIndexes.push({
        contractNumber: gasContract.contractNumber,
        ean: gasContract.deliveryPoint.ean,
        indexes: isInFuture
          ? undefined
          : gasContract?.deliveryPoint?.previousIndex?.registers
              ?.filter((register) => register.timeframeCode !== TimeframeCode.NOT_USED)
              ?.map((register, i) => ({
                meterNumber: gasContract?.deliveryPoint?.previousIndex!.meterNumber,
                registerName: register.registerName,
                timeframeCode: register.timeframeCode,
                value: indexes.gas![i],
                direction: register.direction
              }))
      })
    }
  } else {
    // Generate electricity indexes (always present)
    contractsDataWithIndexes.push({
      contractNumber: electricityContract.contractNumber,
      ean: electricityContract.deliveryPoint.ean
    })

    // Generate gas indexes if necessary
    if (gasContract) {
      contractsDataWithIndexes.push({
        contractNumber: gasContract.contractNumber,
        ean: gasContract.deliveryPoint.ean
      })
    }
  }

  return contractsDataWithIndexes
}

/**
 * Returns the digital meter register name based on the given direction & meterIndexType
 *
 * @param {Direction} direction
 * @param {MeterIndexType} meterIndexType
 * @returns {string}
 */
export const getDigitalMeterRegisterName = (direction: Direction, meterIndexType: MeterIndexType): string => {
  if ([MeterIndexType.DAY, MeterIndexType.NIGHT].includes(meterIndexType)) {
    return ` (${direction === Direction.CONSUMPTION ? '1' : '2'}.8.${meterIndexType === MeterIndexType.DAY ? '1' : '2'})`
  }

  return ''
}

/**
 * Checks if the given contract is active
 *
 * @param {UnknownServiceContract} contract
 * @returns {boolean}
 */
export const isActiveContract = (contract?: UnknownServiceContract): boolean => {
  if (!contract) {
    return false
  }

  return (
    // Contract start date has passed AND
    dayjs().isSameOrAfter(contract.detail.contractualStartDate) &&
    // Contract has 'Active' or 'Effective' status OR Contract has 'Terminated' status but end date has not passed yet
    (isContractStatusActive(contract.detail.status) ||
      (contract.detail.status === ContractStatus.TERMINATED && dayjs().isSameOrBefore(contract.detail.effectiveEndDate)))
  )
}

/**
 * Checks if the given contract is set to be terminated within a month
 *
 * @param {UnknownServiceContract} contract
 * @returns {boolean}
 */
export const isCloseToTermination = (contract: UnknownServiceContract): boolean => {
  return (
    contract.detail.status === ContractStatus.TERMINATED &&
    dayjs(contract.detail.effectiveEndDate).isBetween(dayjs(), dayjs().add(1, 'month'), 'day', '[]')
  )
}

/**
 * Defines if contract status is active
 *
 * @param {ContractStatus} contractStatus
 * @returns {boolean}
 */
export const isContractStatusActive = (contractStatus: ContractStatus): boolean => {
  return contractStatus === ContractStatus.ACTIVE || contractStatus === ContractStatus.EFFECTIVE
}

/**
 * Checks if the given contract is terminated or cancelled
 *
 * @param {UnknownServiceContract} contract
 * @returns {boolean}
 */
export const isInactiveContract = (contract: UnknownServiceContract): boolean => {
  return contract.detail.status === ContractStatus.TERMINATED || contract.detail.status === ContractStatus.CANCELLED
}

/**
 * Returns if a CustomerLibrary has more than one active electricity contract
 *
 * @param {NormalizedEntities['billingContracts']} billingContracts
 * @returns {boolean}
 */
export const hasMultipleActiveElectricityContracts = (billingContracts: NormalizedEntities['billingContracts']): boolean => {
  const electricityContracts = Object.values(billingContracts).map((contract) => contract?.serviceContracts?.electricity)
  const activeContracts = electricityContracts.filter((contract) => isActiveContract(contract))
  return activeContracts.length > 1
}

/**
 * Returns the first active electricity contract
 *
 * @param {NormalizedEntities['billingContracts']} billingContracts
 * @returns {BillingContract | undefined}
 */
export const findFirstBillingContractWithActiveElectricityContract = (
  billingContracts: NormalizedEntities['billingContracts']
): BillingContract | undefined => {
  for (const billingContractKey in billingContracts) {
    const billingContract = billingContracts[billingContractKey]
    if (isActiveContract(billingContract.serviceContracts.electricity)) {
      return billingContract
    }
  }
  return undefined
}

/**
 * Group billing contract id's by active electricity service contract
 * @param {string[]} billingContractNumbers
 * @param {NormalizedEntities["billingContracts"]} normalizedContracts
 * @returns {{active: string[], inactive: string[]}}
 */
export const groupBillingContractsByActiveServiceContract = (
  billingContractNumbers: string[],
  normalizedContracts: NormalizedEntities['billingContracts']
): { active: string[]; inactive: string[] } => {
  return billingContractNumbers.reduce(
    (filteredContracts: { active: string[]; inactive: string[] }, contractNumber) => {
      const isActiveAddress = isActiveContract(normalizedContracts[contractNumber].serviceContracts.electricity)
      if (isActiveAddress) {
        filteredContracts.active.push(contractNumber)
      } else {
        filteredContracts.inactive.push(contractNumber)
      }

      return filteredContracts
    },
    {
      active: [],
      inactive: []
    }
  )
}

/**
 * Determines the contracts simplified status
 * @param {UnknownServiceContract} contract
 * @returns {SimplifiedContractStatus}
 */
const determineContractStatusType = (contract: UnknownServiceContract): SimplifiedContractStatus => {
  switch (contract.detail.status) {
    case ContractStatus.EFFECTIVE:
      return SimplifiedContractStatus.ACTIVE
    case ContractStatus.TERMINATED:
      return SimplifiedContractStatus.TERMINATED
    case ContractStatus.CANCELLED:
      return SimplifiedContractStatus.CANCELLED
    case ContractStatus.ACTIVE:
    case ContractStatus.ILC_ONGOING:
    case ContractStatus.INACTIVE:
      return SimplifiedContractStatus.IN_PROGRESS
    default:
      return SimplifiedContractStatus.INACTIVE
  }
}

/**
 * Calculates the contract status based on the given contract
 * @param {UnknownServiceContract} contract
 * @returns {FormatContractStatus}
 */
export const formatContractStatus = (contract: UnknownServiceContract): FormatContractStatus => {
  const simplifiedStatus = determineContractStatusType(contract)
  const {
    detail: { effectiveEndDate, contractualStartDate }
  } = contract

  switch (simplifiedStatus) {
    case SimplifiedContractStatus.ACTIVE: {
      return {
        key: dayjs(contractualStartDate).isAfter(dayjs()) ? 'startingOn' : 'activeSince',
        color: 'green',
        date: dayjs(contractualStartDate).toISOString()
      }
    }
    case SimplifiedContractStatus.TERMINATED: {
      const date = dayjs(effectiveEndDate).toISOString()
      return dayjs(effectiveEndDate).isAfter(dayjs())
        ? { date, key: 'terminatingOn', color: 'orange' }
        : { date, key: 'terminatedSince', color: 'gray' }
    }
    default:
      return { key: simplifiedStatus, color: 'gray' }
  }
}

/**
 * Triggers the getMeterReadings action
 *
 * @param {ServiceContractElectricity} electricityContract
 * @param {ServiceContractGas=} gasContract
 */
export const triggerGetMeterReadings = (electricityContract: ServiceContractElectricity, gasContract?: ServiceContractGas) => {
  store.dispatch(
    getMeterReadings({
      electricityContractNumber: electricityContract.contractNumber,
      ...(gasContract &&
        isActiveContract(gasContract) && {
          gasContractNumber: gasContract.contractNumber
        })
    })
  )
}
