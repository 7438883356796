import { Button } from '@boltenergy-be/design-system'
import styles from './InjectionAddressDetails.module.scss'
import { useTranslation } from 'react-i18next'
import { AddressDetailProps } from '../types'
import { convertBase64toBlob } from 'utils/format'
import dayjs from 'dayjs'
import { DATE_FORMAT } from 'constants/constants.ts'

const InjectionAddressDetails = ({ contract: billingContract }: AddressDetailProps) => {
  //i18n
  const { t } = useTranslation('addresses')

  const { electricity: contract } = billingContract.serviceContracts
  const documentPreviewUrl =
    contract.detail.injectionContract && URL.createObjectURL(convertBase64toBlob(contract.detail.injectionContract, 'application/pdf'))

  return (
    <>
      <ul className={styles.producer}>
        <li>
          <strong>{t('myContract.address')}</strong>
          <span>{`${contract.deliveryPoint.address.streetName} ${contract.deliveryPoint.address.streetNumber}`}</span>
          <span>{`${contract.deliveryPoint.address.postalCode} ${contract.deliveryPoint.address.townName}`}</span>
        </li>
        <li>
          <strong>{t('myContract.status')}</strong>
          <span>{t(`myContract.contractStatus.${contract.detail.status}`)}</span>
        </li>
        <li>
          <strong>{t('myContract.ean')}</strong>
          <small>{contract.deliveryPoint.ean}</small>
        </li>
        <li>
          <strong>{t('myContract.startDate')}</strong>
          <span>{dayjs(contract.detail.contractualStartDate).format(DATE_FORMAT)}</span>
        </li>
        {(contract.detail.contractualEndDate || contract.detail.effectiveEndDate) && (
          <li>
            <strong>{t('myContract.endDate')}</strong>
            <span>{dayjs(contract.detail.effectiveEndDate || contract.detail.contractualEndDate).format(DATE_FORMAT)}</span>
          </li>
        )}
        {documentPreviewUrl && (
          <li>
            <strong>{t('myContract.contract')}</strong>
            <Button
              className={styles['download-pdf']}
              representation="link"
              variant="secondary"
              onClick={() => {
                // Open the PDF in a new tab
                window.open(documentPreviewUrl, '_blank')
              }}
            >
              {t('myContract.tariff.download')}
            </Button>
          </li>
        )}
      </ul>
    </>
  )
}

export default InjectionAddressDetails
