import { Language } from 'store/app/types'
import { AddContractData, AddContractSteps, CounterTypes, EanCaptureType, MeterType, Situation } from 'features/contracts/add/types'
import { dateStringToObject } from 'utils/addContract'
import { store } from 'store'
import { UserTypes } from 'store/auth/types'
import { Region } from 'api/types'
import { ELProduct } from 'types/products'
import { defineProducts } from 'utils/products'
import { PaymentMode, SimulationType } from 'types/contacts.ts'
import { selectCurrentContracts } from 'store/contact/selectors.ts'
import { NormalizedAccount, NormalizedContact } from 'store/contact/types.ts'
import { DeliveryMode } from 'types/types.ts'

export const GET_INITIAL_ADD_CONTRACT_DATA = (
  language: Language,
  currentAccount?: NormalizedAccount,
  contact?: NormalizedContact
): AddContractData => {
  // Redux
  const { userType } = store.getState().auth

  // Constants
  const isSales = userType === UserTypes.SALES
  const billingContract = !isSales ? selectCurrentContracts(store.getState().contact) : undefined
  const deliveryMode = billingContract?.deliveryMode ?? DeliveryMode.EMAIL
  const elProduct = billingContract?.serviceContracts.electricity?.detail?.productCode || defineProducts(deliveryMode, false).electricity

  return {
    [AddContractSteps.ADDRESS]: {
      deliveryAddress: {
        countryCode: 'BE',
        postalCode: '',
        townCode: 0,
        townName: '',
        streetName: '',
        streetNumber: '',
        streetBox: ''
      },
      needsGas: false,
      region: Region.FLANDERS
    },
    [AddContractSteps.PRODUCER]: undefined,
    [AddContractSteps.PRODUCT]: {
      electricity: elProduct as ELProduct,
      gas: undefined
    },
    [AddContractSteps.INSTALMENT_DATA]: {
      simulationType: SimulationType.EAV,
      instalments: {
        electricity: 0
      }
    },
    [AddContractSteps.METER_DETAILS]: {
      contractStartDate: null,
      counterType: CounterTypes.DIGITAL,
      eanCaptureType: EanCaptureType.NO_LOOKUP_DONE,
      electricity: {
        total: null,
        day: null,
        night: null,
        exclNight: null,
        ean: '',
        eanExclNight: null,
        meterNumber: null
      },
      hasExclNight: false,
      hasExclNightEan: true,
      hasSolarPanels: false,
      meterReadingMonth: null,
      meterType: MeterType.DOUBLE_RATE,
      situation: Situation.SWITCH,
      dynamicTariff: false
    },
    [AddContractSteps.CUSTOMER_DATA]: {
      customerNumber: currentAccount?.customerNumber,
      isCompany: !!currentAccount?.company?.enterpriseNumber || false,
      ...(!!currentAccount?.company?.enterpriseNumber && {
        companyNumber: currentAccount.company.enterpriseNumber,
        legalForm: currentAccount.company.legalForm,
        companyName: currentAccount.company.name,
        subjectToVat: currentAccount.company.vatApplication
      }),
      firstName: currentAccount?.firstName || '',
      lastName: currentAccount?.lastName || '',
      email: contact?.email || '',
      emailConfirmation: contact?.email || '',
      mobilePhone: contact?.phone || '',
      dateOfBirth: currentAccount?.birthDate
        ? dateStringToObject(currentAccount.birthDate)
        : {
            day: '',
            month: '',
            year: ''
          },
      language,
      sameCorrespondenceAddress: true,
      correspondenceAddress: currentAccount?.correspondenceAddress
        ? {
            ...currentAccount.correspondenceAddress,
            countryCode: 'BE',
            townCode: 0
          }
        : undefined,
      deliveryMode,
      paymentMethod: currentAccount?.paymentDetails?.directDebit ? PaymentMode.DOMICILATION : PaymentMode.TRANSFER,
      iban: currentAccount?.paymentDetails?.iban || undefined
    }
  }
}

export const ADD_CONTRACT_FLOW = [
  AddContractSteps.ADDRESS,
  AddContractSteps.PRODUCER,
  AddContractSteps.PRODUCT,
  AddContractSteps.INSTALMENT_DATA,
  AddContractSteps.METER_DETAILS,
  AddContractSteps.CUSTOMER_DATA,
  AddContractSteps.CONFIRMATION,
  AddContractSteps.DONE
]

export const MOVE_CONTRACT_FLOW = [
  AddContractSteps.ADDRESS,
  AddContractSteps.REFERRAL,
  AddContractSteps.PRODUCER,
  AddContractSteps.PRODUCT,
  AddContractSteps.INSTALMENT_DATA,
  AddContractSteps.METER_DETAILS,
  AddContractSteps.CUSTOMER_DATA,
  AddContractSteps.CONFIRMATION,
  AddContractSteps.DONE
]

export const MIN_AGE = 18

export const DAYS_TO_YEARS_COEFFICIENT = 0.002738

export const DATE_FORMAT = 'DD/MM/YYYY'

export const ALLOWED_IBAN_COUNTRIES = ['BE', 'FR', 'DE', 'LU', 'NL']
