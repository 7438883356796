import { useTranslation } from 'react-i18next'
import PageLayout from 'layouts/page-layout/PageLayout.tsx'
import { useStoreSelector } from 'hooks/store.ts'
import { selectContact, selectCurrentAccount, selectCurrentContracts, selectProducerId } from 'store/contact/selectors.ts'
import styles from './Overview.module.scss'
import { routes } from 'types/routes.ts'
import { determineAccessRights } from 'utils/contracts.ts'
import parse from 'html-react-parser'
import MarketPricesContent from 'pages/App/market-prices/market-prices-content/MarketPricesContent.tsx'
import OverviewCard from './overview-cards/OverviewCard.tsx'
import InvoicesOverviewCard from './overview-cards/invoices/InvoicesOverviewCard.tsx'
import ProducerOverviewCard from './overview-cards/producer/ProducerOverviewCard.tsx'
import ReferralOverviewCard from './overview-cards/referral/ReferralOverviewCard.tsx'
import WelcomeOverviewCard from './overview-cards/welcome/WelcomeOverviewCard.tsx'
import BillShockOverviewCard from './overview-cards/bill-shock/BillShockOverviewCard.tsx'
import UsageOverviewCard from './overview-cards/usage/UsageOverviewCard.tsx'
import { useLayoutEffect, useRef, useState } from 'react'
import useOnScreen from 'hooks/useOnScreen.ts'
import mixpanel from 'mixpanel-browser'
import { OverviewEvents } from 'types/tracking.ts'
import SupportOverviewCard from 'pages/App/overview/overview-cards/support/SupportOverviewCard.tsx'
import GuaranteeOfOriginOverviewCard from 'pages/App/overview/overview-cards/guarantee-of-origin/GuaranteeOfOriginOverviewCard.tsx'
import { Accordion, Button, Drawer } from '@boltenergy-be/design-system'
import { useGetFaqBySlugQuery } from 'store/queries/cms-api'
import { FaqSlugB2C } from 'types/faq.ts'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import dayjs from 'dayjs'

const PHISHING_COOKIE_SLUG = 'phishing-info-card'

const Overview = () => {
  // Redux store
  const { selected, accounts, billingContracts, contact, isProducer } = useStoreSelector((store) => store.contact)
  const { language } = useStoreSelector((store) => store.app)

  // Selected data
  const { email, firstName } = selectContact({ contact })
  const { producerId: accountProducerId } = selectCurrentAccount({ selected, accounts })
  const billingContract = selectCurrentContracts({ selected, billingContracts })
  const { serviceContracts } = billingContract
  const producerId = isProducer && accountProducerId ? accountProducerId : selectProducerId(billingContract)

  // Queries
  const { data: phishingFaqs } = useGetFaqBySlugQuery({ slug: FaqSlugB2C.PHISHING, language })

  // Access rights
  const accessRights = determineAccessRights(serviceContracts.electricity)

  // Is on screen
  const layoutRef = useRef<HTMLDivElement>(null)
  const secondCardRef = useRef<HTMLDivElement>(null)
  const isOnScreen = useOnScreen(secondCardRef, { root: layoutRef?.current, threshold: 0.75 })

  // Local state
  const [phishingDrawer, setPhishingDrawer] = useState<boolean>(false)

  // Constants
  const showPhishingCard =
    !Cookies.get(PHISHING_COOKIE_SLUG) ||
    (!!Cookies.get(PHISHING_COOKIE_SLUG) && dayjs(Cookies.get(PHISHING_COOKIE_SLUG)).isAfter(dayjs().subtract(1, 'day')))

  // i18n
  const { t } = useTranslation('overview')

  // Track if users understand scroll snapping
  useLayoutEffect(() => {
    if (isOnScreen) {
      const container = document.getElementsByClassName(styles.content)?.[0]
      const computedStyle = getComputedStyle(container)
      const scrollSnapType = computedStyle.getPropertyValue('scroll-snap-type')

      if (scrollSnapType === 'y mandatory') {
        mixpanel.track(OverviewEvents.SCROLLED_WITH_SNAPPING)
      }
    }
  }, [isOnScreen])

  return (
    <PageLayout
      title={parse(t('overview.title', { name: firstName })).toString()}
      classes={{ header: styles.header, body: styles.content }}
      ref={layoutRef}
    >
      {/* Welcome/Beta */}
      <WelcomeOverviewCard />

      {/* Phishing */}
      {showPhishingCard && (
        <>
          <OverviewCard
            button={{
              id: 'phishing',
              label: t('overview.phishing.button'),
              onClick: () => setPhishingDrawer(true)
            }}
            color="yellow"
          >
            <OverviewCard.Title>{t('overview.phishing.title')}</OverviewCard.Title>
            <p>{t('overview.phishing.description')}</p>
          </OverviewCard>
          <Drawer
            title={t('overview.phishing.drawer.title')}
            isOpen={phishingDrawer}
            onRequestClose={() => {
              setPhishingDrawer(false)
              Cookies.set(PHISHING_COOKIE_SLUG, dayjs().toISOString())
            }}
          >
            <p>{t('overview.phishing.drawer.description')}</p>

            {!!phishingFaqs?.category?.questions?.length && (
              <Accordion
                variant="filled"
                className={styles.accordion}
                items={phishingFaqs.category.questions.map((q) => ({
                  title: q.question,
                  children: parse(q.answer)
                }))}
              />
            )}

            <p className="mb-100 mt-auto">
              {t('overview.phishing.drawer.extra')} <Link to={routes.SUPPORT_FAQ}>{t('overview.phishing.drawer.extraLink')}</Link>.
            </p>

            <Button
              isFullwidth
              variant="secondary"
              onClick={() => {
                setPhishingDrawer(false)
                Cookies.set(PHISHING_COOKIE_SLUG, dayjs().subtract(1, 'day').toISOString())
              }}
            >
              {t('overview.phishing.drawer.button')}
            </Button>
          </Drawer>
        </>
      )}

      {/* Referral */}
      {!isProducer && <ReferralOverviewCard ref={secondCardRef} />}

      {/* Missing Guarantee of Origins*/}
      {isProducer && <GuaranteeOfOriginOverviewCard />}

      {/* Market prices */}
      {accessRights.marketPrices.canAccess && (
        <OverviewCard
          button={{
            label: t('overview.dynamicTariff.button'),
            href: routes.MARKET_PRICES
          }}
        >
          <OverviewCard.Title>{t('overview.dynamicTariff.title')}</OverviewCard.Title>
          <MarketPricesContent compact />
        </OverviewCard>
      )}

      {/* Consumption */}
      <UsageOverviewCard electricityContract={serviceContracts.electricity} {...{ accessRights }} />

      {/* Invoice status */}
      <InvoicesOverviewCard />

      {/* Producer */}
      <ProducerOverviewCard {...{ producerId }} />

      {/* Contact */}
      <SupportOverviewCard />

      {/* Bill shock */}
      {!isProducer && <BillShockOverviewCard {...{ billingContract, email, accessRights }} />}
    </PageLayout>
  )
}

export default Overview
