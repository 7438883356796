import { EanLookupResponseBody, Region } from 'api/types'
import { Language, LanguageLowercase, MoveFlows } from 'store/app/types'
import { ClientType, Contact, DeliveryMode, Person } from 'types/types'
import { ELProduct, NGProduct } from 'types/products'
import { SourceType } from 'types/user'
import { SalesEvent } from 'types/sales'
import { ReferralFormInputs } from 'features/contracts/referral/types.ts'
import { PaymentMode, SignChannel, SimulationType } from 'types/contacts.ts'
import { AddressWithCountryAndTownCode, Company, LegalForm } from 'types/general.ts'
import { Producer } from 'types/producer.ts'

// ENUMS

export enum AddContractSteps {
  ADDRESS = 'address',
  REFERRAL = 'referral',
  PRODUCER = 'producer',
  PRODUCT = 'product',
  INSTALMENT_DATA = 'instalmentData',
  METER_DETAILS = 'meterDetails',
  CUSTOMER_DATA = 'customerData',
  CONFIRMATION = 'confirmation',
  DONE = 'done'
}

export enum Situation {
  SWITCH = 'Supplier Switch',
  MOVE = 'Move',
  NEW_CONNECTION = 'New Connection'
}

export enum EanCaptureType {
  NO_LOOKUP_DONE = 'No look-up done',
  NO_EAN_PROCESS = 'No EAN process',
  NOT_FOUND = 'Not found - Manual filled',
  OVERWRITTEN = 'Overwritten by customer',
  PREFILLED = 'Prefilled'
}

export type StartDateRange = {
  min: Date
  max: Date
  initial: Date
}

export enum CounterTypes {
  ANALOG = 'Analog',
  DIGITAL = 'Digital'
}

export enum MeterType {
  SINGLE_RATE = 'Single',
  DOUBLE_RATE = 'Double'
}

export enum OptinPoint {
  PORTAL_REGISTRATION = 'Portal Registration'
}

export enum SimulationSalesOffice {
  EXTRA_ADDRESS = 'Extra Address',
  MOVE = 'Move'
}

// TYPES

export type AddContractData = {
  [AddContractSteps.ADDRESS]: AddressFormInputs
  [AddContractSteps.REFERRAL]?: ReferralFormInputs
  [AddContractSteps.PRODUCER]?: Producer
  [AddContractSteps.PRODUCT]: ProductFormInputs
  [AddContractSteps.INSTALMENT_DATA]: InstalmentData
  [AddContractSteps.METER_DETAILS]: MeterDataFormInputs
  [AddContractSteps.CUSTOMER_DATA]: CustomerDataFormInputs
}

type InstalmentData = {
  simulationType: SimulationType
  instalments: {
    electricity: number
    gas?: number
  }
}

export type CurrentStepData =
  | AddressFormInputs
  | ReferralFormInputs
  | Producer
  | ProductFormInputs
  | InstalmentData
  | MeterDataFormInputs
  | CustomerDataFormInputs

export type AddContractStepProps = {
  isMove?: boolean
  isSales?: boolean
  setNextStep: (data: CurrentStepData, nextStep: AddContractSteps) => void
  addContractData: AddContractData
  setCurrentStep: (addContractStep: AddContractSteps) => void
  setCustomerNumber?: (customerNumber: string) => void
  eanLookupData?: EanLookupResponseBody
  handleEanLookup?: (deliveryAddress: AddressWithCountryAndTownCode) => void
  setAddContractData: (addContractData: AddContractData) => void
}

export type DoneProps = {
  producer?: AddContractData['producer']
}

export type AddressFormInputs = {
  salesEmail?: string
  eventId?: string
  event?: SalesEvent
  deliveryAddress: AddressWithCountryAndTownCode
  needsGas: boolean
  region: Region
}

export type ProductFormInputs = {
  electricity: ELProduct
  gas?: NGProduct
}

export type MeterDataFormInputs = {
  contractStartDate: string | null // ISO date format
  counterType: CounterTypes
  customContractStartDate?: boolean
  docUpload?: FileList | null
  dynamicTariff: boolean
  eanCaptureType: EanCaptureType
  electricity: {
    total: number | null
    day: number | null
    night: number | null
    exclNight: number | null
    ean: number | string
    eanExclNight: number | string | null
    meterNumber: number | null
  }
  gas?: {
    total: number | null
    ean: string | number
    meterNumber: number | null
  }
  hasExclNight: boolean
  hasExclNightEan: boolean
  hasSolarPanels: boolean
  meterReadingMonth: number | null
  meterType: MeterType
  opportunityId?: string
  situation: Situation
  walloniaSolarPanelsSince2024?: boolean
  previousInhabitant?: PreviousInhabitant
}

export type CustomerDataFormInputs = {
  customerNumber?: string
  isCompany: boolean
  firstName: string
  lastName: string
  email: string
  emailConfirmation: string
  mobilePhone: string
  dateOfBirth: BirthDate | null
  language: Language
  companyNumber?: string
  legalForm?: LegalForm
  companyName?: string
  subjectToVat?: boolean
  correspondenceAddress?: AddressWithCountryAndTownCode
  sameCorrespondenceAddress?: boolean
  paymentMethod: PaymentMode
  deliveryMode: DeliveryMode
  iban?: string
}

export type BirthDate = {
  day: string
  month: string
  year: string
}

export type ElectricityAndGasPrices = {
  electricity: number
  gas?: number
}

export type CreateContractData = {
  moveFlow: MoveFlows
  boltGoPrices?: ElectricityAndGasPrices
  company?: Company
  contact: Contact
  contractStartDate: string // ISO date format
  correspondenceAddress: AddressWithCountryAndTownCode
  counterType: CounterTypes
  customerNumber?: string
  deliveryAddress: AddressWithCountryAndTownCode
  dynamicTariff: boolean
  document?: string
  eanCaptureType: EanCaptureType
  hasSolarPanels: boolean
  instalments?: ElectricityAndGasPrices
  invoicing: Invoicing
  language: LanguageLowercase
  marketing: MarketingData
  meterInfo: MeterInfo
  meterType: MeterType
  person: Person
  producer: {
    id: string
    name: Producer['name']
    salesforceId: string
    slug: Producer['slug']
    sourceRegion: string
  }
  products: Products
  referral?: ReferralFormInputs
  situation: Situation
  walloniaSolarPanelsSince2024?: boolean
  previousInhabitant?: PreviousInhabitant
}

export type Invoicing = {
  ibanNumber: string
  paymentMethod: PaymentMode
}

export type MarketingData = {
  agentID?: string
  eanCaptureType?: EanCaptureType
  optinPoint?: OptinPoint
  partnerID?: string
  signChannel?: SignChannel
  simulationSalesOffice?: SimulationSalesOffice | string
  simulationType?: SimulationType
  sourceType?: SourceType
}

export type MeterInfo = {
  electricity: {
    EAN: string
    exclEAN: string | ((radix?: number | undefined) => string)
    indexDay: string
    indexNight: string
    indexExcl: string
    meterNumber: string
    exclmeterNumber?: string
  }
  gas?: {
    EAN: string
    index: string
    meterNumber: string
  }
  meterReadingMonth?: number
}

export type InstalmentStepError = {
  type: 'total' | 'electricity' | 'gas'
  message: string
}

export type Products = {
  electricity: ELProduct
  gas?: NGProduct
}

export type PreviousInhabitant = {
  firstName?: string
  lastName?: string
  address?: AddressWithCountryAndTownCode
  supplier?: string
  clientType?: ClientType
}
