import { useTranslation } from 'react-i18next'
import Card from 'components/Card/Card.tsx'
import { Heading, Icon } from '@boltenergy-be/design-system'
import Link from 'components/Link/Link.tsx'
import { routes } from 'types/routes.ts'
import { Navigate } from 'react-router'
import EmptyState from 'components/ReturnLater/EmptyState.tsx'
import { useStoreSelector } from 'hooks/store.ts'
import { selectContact, selectCurrentContracts } from 'store/contact/selectors.ts'
import { determineAccessRights, isActiveContract } from 'utils/contracts.ts'
import { TotalInstalmentsConfig } from 'pages/App/billing/instalment/types.ts'
import { useGetBillShockQuery } from 'store/queries/bolt-api/contracts'
import { AccessRightsKeys } from 'types/contracts.ts'
import styles from './Instalment.module.scss'
import { Fragment } from 'react'
import { formatCurrency } from 'utils/format'
import mixpanel from 'mixpanel-browser'
import { BillingEvents } from 'types/tracking.ts'
import EstimatedSettlement from 'pages/App/billing/billing-cycles/components/estimated-settlement/EstimatedSettlement.tsx'

const Instalment = () => {
  // REDUX STORE
  const { language } = useStoreSelector((store) => store.app)
  const { selected, billingContracts, contact } = useStoreSelector((store) => store.contact)

  // Contracts
  const { email } = selectContact({ contact })
  const billingContract = selectCurrentContracts({ selected, billingContracts })
  const { electricity, gas } = billingContract.serviceContracts

  // Access rights
  const accessRights = determineAccessRights(electricity)

  // Redux queries
  const { data: billShockData, isLoading } = useGetBillShockQuery(
    {
      billingContractId: selected.billingContract,
      email,
      serviceContracts: billingContract.serviceContracts
    },
    {
      skip:
        !selected.billingContract ||
        !accessRights[AccessRightsKeys.BILLING_CYCLES].canAccess ||
        !accessRights[AccessRightsKeys.BILLING_CYCLES].showContent
    }
  )

  // i18n
  const { t } = useTranslation(['billing', 'common'])

  // constants
  const estimatedSettlement = billShockData?.billShock?.settlement?.billShockAmount
  const TOTALS_CONFIG: TotalInstalmentsConfig = {
    total: {
      color: '--text-default',
      icon: 'euro',
      label: t('instalment.total'),
      value: (electricity?.detail?.instalment || 0) + (gas?.detail?.instalment || 0)
    },
    electricity: isActiveContract(gas)
      ? {
          color: '--accent-brand',
          icon: 'electricity',
          label: t('common:electricity'),
          value: electricity?.detail?.instalment
        }
      : undefined,
    gas: isActiveContract(gas)
      ? {
          color: '--accent-purple',
          icon: 'gas',
          label: t('common:gas'),
          value: gas?.detail?.instalment
        }
      : undefined
  }

  return !accessRights.instalment.canAccess ? (
    <Navigate to={routes.BILLING_INVOICES} replace />
  ) : !accessRights.instalment.showContent ? (
    <EmptyState description={t('editInstalment.returnLater')} />
  ) : (
    <>
      <Card className={styles.card}>
        <Heading as="h2" variant="h6" weight={600}>
          {t('instalment.currentInstalment')}
        </Heading>

        {Object.values(TOTALS_CONFIG)
          .filter((config) => !!config)
          .map(({ icon, color, label, value }) => (
            <Fragment key={label}>
              <div className={styles.entry}>
                <Icon name={icon} style={{ color: `var(${color})` }} />
                <strong>{label}</strong>
                <data value={value}>{value ? formatCurrency(value, { language }) : ''}</data>
              </div>
              <hr />
            </Fragment>
          ))}

        <EstimatedSettlement loading={isLoading} settlementAmount={estimatedSettlement} labelAsTitle="h3" />
      </Card>

      <Link
        href={routes.BILLING_EDIT_INSTALMENT}
        representation="button"
        isFullwidth
        onClick={() => mixpanel.track(BillingEvents.GO_TO_CHANGE_INSTALMENT, { from: routes.BILLING_INSTALMENT })}
      >
        {t('instalment.editInstalment')}
      </Link>
    </>
  )
}

export default Instalment
