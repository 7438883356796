import { Region } from 'api/types.ts'
import { Language } from 'store/app/types.ts'

export const GO_INFO_URLS: { [key in Region]: string | { [key in Language]: string } } = {
  [Region.FLANDERS]: 'https://www.vreg.be/nl/garanties-van-oorsprong',
  [Region.BRUSSELS]: {
    [Language.NL]: 'https://brugel.brussels/nl_BE/themes/hernieuwbare-energie-11/levering-van-groene-energie-42',
    [Language.FR]: 'https://brugel.brussels/fr/themes/infos-pour-le-secteur-de-l-energie-13/gerer-un-compte-cv-go-54'
  },
  [Region.WALLONIA]:
    "https://energie.wallonie.be/fr/marche-des-garanties-d-origine.html?IDC=9824#:~:text=La%20garantie%20d'origine%20(GO,la%20cog%C3%A9n%C3%A9ration%20%C3%A0%20haut%2Drendement."
}
