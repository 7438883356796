import { InvoicesStatus, InvoiceStatusTextKeys } from './types.ts'
import { CardColors } from '@boltenergy-be/design-system'

export const INVOICE_STATUS_CONFIG: Record<InvoicesStatus, { color: CardColors; textKey: InvoiceStatusTextKeys }> = {
  [InvoicesStatus.BREAK_EVEN]: {
    color: 'green',
    textKey: 'neutral'
  },
  [InvoicesStatus.IS_GETTING_MONEY_BACK]: {
    color: 'green',
    textKey: 'open'
  },
  [InvoicesStatus.HAS_UNPAID_INVOICES]: {
    color: 'yellow',
    textKey: 'open'
  },
  [InvoicesStatus.DIRECT_DEBIT]: {
    color: 'green',
    textKey: 'directDebit'
  },
  [InvoicesStatus.DIRECT_DEBIT_WITH_UNPAID_INVOICES]: {
    color: 'yellow',
    textKey: 'open'
  }
}
