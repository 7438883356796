/**
 * This file is generated by i18next-resources-for-ts
 */
import addresses from './json/addresses.json'
import auth from './json/auth.json'
import billing from './json/billing.json'
import common from './json/common.json'
import consumption from './json/consumption.json'
import contracts from './json/contracts.json'
import energyOverview from './json/energyOverview.json'
import feedbackWidget from './json/feedbackWidget.json'
import guaranteeOfOrigin from './json/guaranteeOfOrigin.json'
import home from './json/home.json'
import invoices from './json/invoices.json'
import marketPrices from './json/marketPrices.json'
import move from './json/move.json'
import overview from './json/overview.json'
import producer from './json/producer.json'
import referral from './json/referral.json'
import sales from './json/sales.json'
import support from './json/support.json'
import user from './json/user.json'
import validation from './json/validation.json'

const resources = {
  addresses,
  auth,
  billing,
  common,
  consumption,
  contracts,
  energyOverview,
  feedbackWidget,
  guaranteeOfOrigin,
  home,
  invoices,
  marketPrices,
  move,
  overview,
  producer,
  referral,
  sales,
  support,
  user,
  validation
} as const

export default resources
